import { EMPTY_OBJECT } from 'src/shared/constants/fallbacks';
import { PartGroups, Parts, SubgroupCatalogsData } from 'src/shared/model/Store/CarCatalog/types';

const skeletonRenderDetailedDescriptionDataPartGroup = {
  ...(EMPTY_OBJECT as PartGroups),
  parts: Array.from({ length: 3 }, () => EMPTY_OBJECT as Parts)
};

export const skeletonRenderDetailedDescriptionData = {
  ...(EMPTY_OBJECT as SubgroupCatalogsData),
  partGroups: Array.from({ length: 3 }, () => skeletonRenderDetailedDescriptionDataPartGroup)
};
