import { EMPTY_STRING } from '../../constants/fallbacks';

const options: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  month: 'long',
  day: 'numeric'
};

// Пример: 2024-04-18 08:01:19 -> 18 апреля 2024
export const formattedDate = (res: string): string => {
  if (!res) {
    return EMPTY_STRING;
  }

  const date = new Date(res);

  return date.toLocaleString('ru-RU', options);
};

// Пример: 2024-04-18 08:01:19 -> 2024
export const formattedDatePerYear = (res: string): string => {
  const date = new Date(res);
  const year = date.getFullYear();

  return String(year);
};
