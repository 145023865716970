import {
  Main,
  SearchByVIN,
  SearchByCar,
  FilterPage,
  ProductPage,
  CatalogCategory,
  CatalogProduct,
  HistoryOrdersPage,
  Basket,
  PlacingAnOrder,
  CatalogItemPriceByArticlePage,
  PartsCatalogByCar,
  PageNotFound,
  PlacingAnOrderSuccess,
  PlacingAnOrderReject
} from 'src/pages';
import { pathConfig } from '@shared/constants/pathConfig';

export const privatePages = [
  { Component: Main, path: pathConfig.MainPath },
  {
    Component: SearchByVIN,
    path: pathConfig.SearchByGrz
  },
  {
    Component: SearchByVIN,
    path: pathConfig.SearchByVin
  },
  {
    Component: SearchByCar,
    path: pathConfig.CatalogPath
  },
  {
    Component: FilterPage,
    path: pathConfig.FilterPagePath
  },
  {
    Component: CatalogItemPriceByArticlePage,
    path: pathConfig.ArticleWithPricePagePath
  },
  {
    Component: CatalogItemPriceByArticlePage,
    path: pathConfig.CatalogItemPriceByArticlePagePath
  },
  {
    Component: ProductPage,
    path: pathConfig.ProductPagePath
  },
  {
    Component: ProductPage,
    path: pathConfig.CarCatalogProductPagePath
  },
  {
    Component: CatalogCategory,
    path: pathConfig.CatalogCategoryPath
  },
  {
    Component: CatalogProduct,
    path: pathConfig.CatalogProductPath
  },
  {
    Component: HistoryOrdersPage,
    path: pathConfig.HistoryOrdersPath
  },
  // {
  //   Component: DetailedOrdersPage, // TODO: Скрываем эту страницу, восстановим когда будем расширять приложение (Сроков пока нет), на данном этапе страница не требуется
  //   path: pathConfig.DetailedOrderPath
  // },
  {
    Component: Basket,
    path: pathConfig.BasketPath
  },
  {
    Component: PlacingAnOrder,
    path: pathConfig.PlacingAnOrderPath
  },
  {
    Component: PlacingAnOrderSuccess,
    path: pathConfig.PlacingAnOrderSuccessPath
  },
  {
    Component: PlacingAnOrderReject,
    path: pathConfig.PlacingAnOrderRejectPath
  },
  {
    Component: PartsCatalogByCar,
    path: pathConfig.PartsCatalogByCarPath
  },
  {
    Component: PageNotFound,
    path: pathConfig.PageNotFound
  }
];
