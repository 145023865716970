import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';

import { EMPTY_STRING } from 'src/shared/constants/fallbacks';

import { DarkModeStore } from './types';

export const useDarkModeStore = create(
  devtools(
    persist<DarkModeStore>(
      set => ({
        theme: localStorage.getItem('theme') ?? EMPTY_STRING,

        setTheme: theme => {
          set({
            theme
          });
        },
        resetTheme: () => {
          set({
            theme: EMPTY_STRING
          });
        }
      }),
      {
        name: 'theme-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
