import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { EMPTY_ARRAY } from 'src/shared/constants/fallbacks';
import { RecommendedPurchaseStore } from './types';
import {
  getRecommendedByArticleProducts,
  getRecommendedByBasketProducts,
  getRecommendedPurchase
} from './service';
import { recommendationsDefaultData } from './constants';

export const useRecommendedPurchaseStore = create(
  devtools(
    persist<RecommendedPurchaseStore>(
      set => ({
        isLoading: false,
        recommendations: recommendationsDefaultData,
        recommendedArticleProducts: EMPTY_ARRAY,
        recommendedBasketProducts: EMPTY_ARRAY,

        // TODO: Изменить функцию, когда появится соответствующий метод на беке
        getRecommendedPurchaseData: () => {
          // TODO: временно добавлен "isLoading: true", для того, чтобы тестировщики могли протестировать скелетон, так как фейковый запрос выполняется моментально
          set({ isLoading: true });

          // TODO: временно добавлен setTimeout, для того, чтобы тестировщики могли протестировать скелетон, так как фейковый запрос выполняется моментально
          setTimeout(async () => {
            await executeRequest(
              () => getRecommendedPurchase(),
              isLoading => set({ isLoading }),
              () => set({ recommendations: recommendationsDefaultData })
            );
          }, 3000);

          return recommendationsDefaultData;
        },

        getRecommendedArticleProductsData: async (brand, number, limit = 4) => {
          await executeRequest(
            () => getRecommendedByArticleProducts(brand, number, limit),
            isLoading => set({ isLoading }),
            response => set({ recommendedArticleProducts: response ?? EMPTY_ARRAY })
          );
        },

        getRecommendedBasketProductsData: async postData => {
          await executeRequest(
            () => getRecommendedByBasketProducts(postData),
            isLoading => set({ isLoading }),
            response => set({ recommendedBasketProducts: response ?? EMPTY_ARRAY })
          );
        },

        resetRecommendedPurchaseState: () => {
          set({
            isLoading: false,
            recommendations: recommendationsDefaultData,
            recommendedArticleProducts: EMPTY_ARRAY
          });
        }
      }),
      {
        name: 'recommendedPurchase-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
