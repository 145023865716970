import React, { useEffect, useRef, useState } from 'react';

import { PickupPointProps } from '@pages/PlacingAnOrder/lib/PickupPoint/ui/types';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { skeletonPickupPoint } from '../lib/constants/mockData';
import { PickupPointView } from './PickupPointView';

export const PickupPoint = ({
  slides,
  currentPoint,
  handleClickPoint,
  sliderRef,
  isLoading,
  settings
}: PickupPointProps) => {
  const sliderWrapRef = useRef<HTMLDivElement>(null);
  const [slideHeight, setSlideHeight] = useState(EMPTY_STRING);

  useEffect(() => {
    if (slides && sliderWrapRef.current) {
      const heightCalculation = setTimeout(() => {
        const banner = sliderWrapRef.current;
        if (banner) {
          const bannerHeight = banner.clientHeight - 30;
          setSlideHeight(`${bannerHeight}px`);
        }
      }, 1500);

      () => clearTimeout(heightCalculation);
    }
  }, [slides, sliderWrapRef, setSlideHeight]);

  return (
    <PickupPointView
      currentPoint={currentPoint}
      handleClickPoint={handleClickPoint}
      isLoading={isLoading}
      settings={settings}
      slideHeight={slideHeight}
      sliderRef={sliderRef}
      sliderWrapRef={sliderWrapRef}
      slides={isLoading ? skeletonPickupPoint : slides}
    />
  );
};
