import React from 'react';
import { CustomTableView } from './CustomTableView';
import { TableProps } from './types';

export const CustomTable = ({
  data,
  headers,
  selectedRow,
  isLoading,
  handleRadioChange
}: TableProps<{
  [key: string]: string | number;
}>) => {
  const filteredHeaders = headers.filter(header => data && data[0] && header in data[0]);
  const skeletonData = Array.from({ length: 3 } as {
    [key: string]: string | number;
  }[]);

  return (
    <CustomTableView
      data={isLoading ? skeletonData : data}
      filteredHeaders={filteredHeaders}
      handleRadioChange={handleRadioChange}
      isLoading={isLoading}
      selectedRow={selectedRow}
    />
  );
};
