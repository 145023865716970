import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { ReactComponent as CarIcon } from '@assets/InfoCardCar.svg';

import { InfoCardDataType } from '@shared/components/InfoCard/types';
import { dataTestId } from '@shared/constants/dataTestId';
import styles from './styles.module.scss';

export const InfoCard = ({ infoCardData, isLoading }: InfoCardDataType) => (
  <div className={styles.infoCardContainer} data-testid={dataTestId.INFO_CARD}>
    {isLoading ? (
      <Skeleton height='56px' width='56px' />
    ) : (
      <CarIcon className={styles.infoCardContainer_icon} />
    )}
    {isLoading
      ? Array.from({ length: 4 }).map((_, index) => (
          <div className={styles.infoCardContainer_textWrapper} key={index}>
            <Text color='secondary'>
              <Skeleton className={styles.infoCardContainer_textWrapper_skeleton} />
            </Text>
            <Text className={styles.infoCardContainer_textWrapper_additionalInfo} color='default'>
              <Skeleton className={styles.infoCardContainer_textWrapper_additionalInfo_skeleton} />
            </Text>
          </div>
        ))
      : infoCardData.map(({ title, name }) => (
          <div className={styles.infoCardContainer_textWrapper} key={title}>
            <Text color='secondary'>{title}</Text>
            <Text className={styles.infoCardContainer_textWrapper_additionalInfo} color='default'>
              {name}
            </Text>
          </div>
        ))}
  </div>
);
