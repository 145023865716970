import { fetchData } from 'src/shared/api/apiConfig';
import {
  ArticleTipsResponse,
  SearchByGRZData,
  SearchByArticleResponse,
  SearchByVINData
} from './types';

export const getSearchByGRZ = async (search: string): Promise<SearchByGRZData[] | void> =>
  fetchData('getSearchByGRZ', { search });

export const getSearchByVIN = async (vin: string): Promise<SearchByVINData[] | void> =>
  fetchData('getSearchByVIN', { vin });

export const getSearchByArticle = async (
  article: string,
  brand: string,
  page?: number,
  perPage?: number
): Promise<SearchByArticleResponse | void> =>
  fetchData('getSearchByArticle', { article, brand, page, 'per-page': perPage });

export const getSearchByArticleOnly = async (
  article: string,
  brand: string,
  page?: number,
  perPage?: number
): Promise<SearchByArticleResponse | void> =>
  fetchData('getSearchByArticleOnly', { article, brand, page, 'per-page': perPage });

export const getArticleTips = async (article: string): Promise<ArticleTipsResponse[] | void> =>
  fetchData('getArticleTips', { article });

export const getArticleAnalogues = async (
  article: string,
  brand: string,
  page?: number,
  perPage?: number
): Promise<SearchByArticleResponse | void> =>
  fetchData('getArticleAnalogues', { article, brand, page, 'per-page': perPage });

export const getArticleOnlyAnalogues = async (
  article: string,
  brand: string,
  page?: number,
  perPage?: number
): Promise<SearchByArticleResponse | void> =>
  fetchData('getArticleOnlyAnalogues', { article, brand, page, 'per-page': perPage });
