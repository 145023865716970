export enum dataTestId {
  ADDITIONAL_INFO_WITH_LINKS = 'AdditionalInfoWithLinks',
  APP = 'App',
  ARROW = 'Arrow',
  ARROW_LEFT = 'Arrow-Left',
  ARROW_RIGHT = 'Arrow-Right',
  BACK_BTN = 'Back-Button',
  BANNERS = 'Banners',
  BANNERS_VIEW = 'BannersView',
  BANNERS_VIEW_BUTTON = 'BannersView=Button',
  BREADCRUMBS_VIEW = 'BreadcrumbsView',
  BTN_ADD_PRODUCT_VIEW = 'BtnAddProductView',
  BTN_ADD_PRODUCT_VIEW_PLUS = 'BtnAddProductView-Plus',
  BTN_ADD_PRODUCT_VIEW_MINUS = 'BtnAddProductView-Minus',
  BTN_ADD_PRODUCT_VIEW_ADD = 'BtnAddProductView-Add',
  CARDS_CONTAINER = 'CardsContainer',
  CATALOG_VIEW = 'CatalogView',
  SEARCH_BY_ARTICLE_INPUT = 'SearchByArticleInput',
  CATALOG_PRICE_BY_ARTICLE_PAGE_VIEW = 'CatalogItemPriceByArticlePageView',
  ARTICLE_WITH_PRICE_PAGE_VIEW = 'ArticleWithPricePageView',
  CATALOG_VIEW_CARD = 'CatalogView-Card',
  CITY_SELECT = 'CitySelect',
  CITY_SELECT_CHANGE_BUTTON = 'CitySelect-ChangeButton',
  COLLABORATE_VIEW = 'CollaborateView',
  COLLABORATE_VIEW_BTN_SUBMIT = 'CollaborateView-BtnSubmit',
  COLLABORATE_VIEW_FORM = 'CollaborateView-Form',
  COLLABORATE_VIEW_FORM_INPUT_AGREEMENT = 'CollaborateView-InputAgreement',
  COLLABORATE_VIEW_FORM_INPUT_COMMENT = 'CollaborateView-InputComment',
  COLLABORATE_VIEW_FORM_INPUT_EMAIL = 'CollaborateView-InputEmail',
  COLLABORATE_VIEW_FORM_INPUT_NAME = 'CollaborateView-InputName',
  COLLABORATE_VIEW_FORM_INPUT_PHONE = 'CollaborateView-InputPhone',
  COLLABORATE_VIEW_FORM_INPUT_URL_AGREEMENT = 'CollaborateView-Url-InputAgreement',
  CONTENT = 'Content',
  COPY_BTN = 'Copy-Button',
  COUNTRY_SELECT = 'CountrySelect',
  COUNTRY_SELECT_BACK = 'CountrySelect-Back',
  CUSTOM_ACCORDION = 'CustomAccordion',
  CUSTOM_ACCORDION_ITEM = 'CustomAccordion-Item',
  CUSTOM_ACCORDION_TITLE = 'CustomAccordion-Tile',
  CUSTOM_SELECT = 'CustomSelect',
  CUSTOM_SELECT_ITEM = 'CustomSelect-Item',
  CUSTOM_SELECT_TITLE = 'CustomSelect-Tile',
  CUSTOM_TABLE = 'CustomTable',
  CUSTOM_TABLE_HEADER_BTN = 'CustomTable-HeaderBtn',
  CUSTOM_TABLE_RADIO = 'CustomTable-Radio',
  DETAILED_ORDER_CARD_ITEM = 'DetailedOrderCardItem',
  DETAILED_ORDER_PAGE = 'DetailedOrderPage',
  FAQ_VIEW = 'FAQView',
  FAQ_VIEW_ACCORDION = 'FAQView-Accordion',
  FILTER_PAGE_FILTER_AVAILABILITY = 'FilterPage-filter-availability',
  FILTER_PAGE_FILTER_BRANDS_BTN_SHOW_ALL = 'FilterPage-filter-brands-btnShowAll',
  FILTER_PAGE_FILTER_BRANDS_CHECKBOX = 'FilterPage-filter-brands-checkbox',
  FILTER_PAGE_FILTER_BTN_SEARCH = 'FilterPage-filter-btnSearch',
  FILTER_PAGE_FILTER_DAY = 'FilterPage-filter_day',
  FILTER_PAGE_FILTER_DELIVERY = 'FilterPage-filter-delivery',
  FILTER_PAGE_FILTER_SELECT = 'FilterPage-filter-select',
  FILTER_PAGE_LIST = 'FilterPageList',
  FILTER_PAGE_LIST_BTN_SEARCH = 'FilterPageList-BtnSearch',
  FILTER_PAGE_LIST_PRODUCT = 'FilterPageList-Product',
  FILTER_PAGE_VIEW = 'FilterPageView',
  FILTER_VIEW = 'FilterView',
  FOOTER_VIEW = 'FooterView',
  FOOTER_VIEW_ACCORDION = 'FooterView-Accordion',
  FOOTER_VIEW_APPSTORE = 'FooterView-SocialLink-AppStore',
  FOOTER_VIEW_GOOGLE_PLAY = 'FooterView-SocialLink-googlePlay',
  FOOTER_VIEW_NAVIGATION_MENU = 'FooterView-NavigationMenu',
  FOOTER_VIEW_PHONE = 'FooterView-SocialLink-Phone',
  FOOTER_VIEW_SOCIAL = 'FooterView-SocialLink-Social',
  FOOTER_VIEW_VK = 'FooterView-SocialLink-VK',
  FOOTER_VIEW_WHATSAPP_PHONE = 'FooterView-SocialLink-WhatsappPhone',
  FOOTER_VIEW_YOUTUBE = 'FooterView-SocialLink-youtube',
  GEOLOCATION_REFERENCE_ELEMENT = 'Geolocation-ReferenceElement',
  GET_FOOTER_INFO_LINKS = 'GetFooterInfoLinks',
  HEADER = 'Header',
  HEADER_LINK = 'HeaderLink',
  INSURANCE_LINK = 'InsuranceLink',
  LOCATION_CITY_SEARCH = 'LocationCitySearch',
  LOCATION_CITY_SEARCH_BUTTON = 'LocationCitySearch-Button',
  LOCATION_CITY_SEARCH_DROPDOWN = 'LocationCitySearch-Dropdown',
  SEARCH_DROPDOWN = 'Search-Dropdown',
  LOCATION_CITY_SEARCH_INPUT = 'LocationCitySearch-Input',
  LOCATION_CITY_SEARCH_LABEL = 'LocationCitySearch-Label',
  SEARCH_LABEL = 'Search-Label',
  LOCATION_POPULAR_CITIES = 'LocationPopularCities',
  LOCATION_POPULAR_CITIES_RADIO = 'LocationPopularCities-Radio',
  LOCATION_POPULAR_COUNTRIES = 'LocationPopularCountries',
  LOCATION_POPULAR_COUNTRIES_RADIO = 'LocationPopularCountries-Radio',
  LOGO_LINK = 'LogoLink',
  MAIN_VIEW = 'MainView',
  NAVIGATION_MENU = 'NavigationMenu',
  NO_INFO = 'NoInfo',
  ORDER_BREADCRUMBS = 'Order-BreadCrumbs',
  ORDER_BUTTON = 'Order-Button',
  ORDER_PAGE = 'HistoryOrderPage',
  ORDER_CARD_ITEM = 'OrderCardItem',
  ORDER_CARD_ITEM_VIEW = 'OrderCardItemView',
  ORDER_CARD_DATA = 'OrderCardData',
  ORDER_CARD = 'OrderCard',
  ORDER_CARD_DATA_BTN_SEARCH = 'OrderCardData-BtnSearch',
  ORDER_CHANGE_BUTTON = 'Order-Change',
  ORDER_SELECT = 'Order-Select',
  PHONE = 'Phone',
  PRODUCT_CARD_VIEW = 'ProductCardView',
  PRODUCT_CARD_VIEW_CHECKBOX = 'ProductCardView-Checkbox',
  PRODUCT_CARD_VIEW_LINK = 'ProductCardView-Link',
  PRODUCT_PAGE = 'ProductPage',
  PRODUCT_PAGE_ABOUT = 'ProductPage-About',
  PRODUCT_PAGE_ADD = 'ProductPage-Add',
  PRODUCT_PAGE_MINUS = 'ProductPage-Minus',
  PRODUCT_PAGE_NAME = 'ProductPage-Name',
  PRODUCT_PAGE_PHOTOS = 'ProductPage-Photos',
  PRODUCT_PAGE_PLUS = 'ProductPage-Plus',
  PRODUCT_PAGE_RECOMMENDATIONS = 'productPage-recommendations',
  RECENTLY_VIEWED_VIEW = 'RecentlyViewedView',
  RECENTLY_VIEWED_VIEW_CARD = 'RecentlyViewedView-Card',
  RECOMMENDATIONS_VIEW = 'RecommendationsView',
  RECOMMENDATIONS_VIEW_CARD = 'RecommendationsView-Card',
  RENDER_CARD = 'Render-Card',
  RENDER_DESCRIPTION_CARD = 'Render-Description-Card',
  RENDER_DETAILED_DESCRIPTION_BTN = 'RenderDetailedDescription-Button',
  RENDER_DETAILED_DESCRIPTION_CHEVRON = 'RenderDetailedDescription-Chevron',
  RENDER_GROUP_CARD = 'Render-Group-Card',
  SEARCH_BY_ARTICLE_VIEW = 'SearchByArticleView',
  SEARCH_BY_ARTICLE_VIEW_INPUT_BTN = 'SearchByArticleView-searchInputButton',
  CATALOG_PRICE_BY_ARTICLE_PAGE_VIEW_INPUT_BTN = 'CatalogItemPriceByArticlePageView-searchInputButton',
  SEARCH_BY_CAR_BTN = 'SearchByCar-Button',
  SEARCH_BY_CAR_VIEW = 'SearchByCarView',
  SEARCH_BY_VIN_VIEW = 'SearchByVINView',
  SEARCH_BY_VIN_VIEW_BTN = 'SearchByVINView-Button',
  SEARCH_BY_VIN_VIEW_SEARCH_INPUT_BTN = 'SearchByVINView-SearchInputButton',
  SEARCH_DISK = 'SearchDisk',
  SEARCH_DISK_BTN_SEARCH = 'SearchDisk-BtnSearch',
  SEARCH_MENU_ITEM = 'SearchMenuItem',
  SEARCH_SEARCH_MENU_ITEM = 'Search-SearchMenuItem',
  SEARCH_TIRES = 'SearchTires',
  SEARCH_TIRES_BTN_SEARCH = 'SearchTires-BtnSearch',
  SEARCH_TIRES_SELECT = 'SearchTires-Select',
  SEARCH_VIEW = 'SearchView',
  SEARCH_VIEW_BTN = 'SearchView-Button',
  SEARCH_VIEW_CHOICE_MODEL = 'SearchView-ChoiceModel',
  SEARCH_VIEW_CHOICE_MODEL_BRAND = 'SearchView-ChoiceModel-Brand',
  SEARCH_VIEW_CHOICE_MODEL_MODEL = 'SearchView-ChoiceModel-Model',
  SEARCH_VIEW_PROGRESSBAR = 'SearchView-ProgressBar',
  SEARCH_VIEW_SEARCH_INPUT_BUTTON = 'SearchView-SearchInputButton',
  SEARCH_VIEW_SELECT_GENERATION = 'SearchView-selectGeneration',
  SEARCH_VIEW_SELECT_GENERATION_RADIO = 'SearchView-selectGeneration-Radio',
  SEARCH_VIEW_SELECT_MODIFICATION = 'SearchView-SelectModification',
  SEARCH_VIEW_SELECT_MODIFICATION_BTN_BACK = 'SearchView-SelectModification-BtnBack',
  SEARCH_VIEW_SELECT_MODIFICATION_BTN_NEXT = 'SearchView-SelectModification-BtnNext',
  SEARCH_VIEW_SELECT_MODIFICATION_TABLE = 'SearchView-SelectModification-Table',
  SECOND_MENU_ITEM_OF_HEADER = 'SecondMenuItemOfHeader',
  SECOND_MENU_ITEM_OF_HEADER_BASKET = 'SecondMenuItemOfHeader-Basket',
  SECOND_MENU_ITEM_OF_HEADER_LOGIN = 'SecondMenuItemOfHeader-Login',
  SOCIAL_LINK = 'SocialLink',
  STICKY_INFO_PRODUCT_VIEW = 'StickyInfoProductView',
  THEME_SWITCHER = 'ThemeSwitcher',
  TIRE_SELECTION_VIEW = 'TireSelectionView',
  TIRE_SELECTION_VIEW_SEARCH_MENU = 'TireSelectionView-SearchMenu',
  WHATSAPP_PHONE = 'WhatsAppPhone',
  CATALOG_CATEGORY_VIEW = 'CatalogCategoryView',
  CATALOG_PRODUCT_VIEW = 'CatalogProductView',
  GRP_INPUT_VIEW = 'GRZInputView',
  BASKET_VIEW = 'BasketView',
  BASKET_VIEW_CHECKBOX = 'BasketView-Checkbox',
  PLACING_AN_ORDER_VIEW = 'PlacingAnOrderView',
  PICKUP_POINT_VIEW = 'PickupPointView',
  PICKUP_POINT_VIEW_SLIDE = 'PickupPointView-Slide',
  RECEIVER_FORM_VIEW = 'ReceiverFormView',
  ORDER_PRICE_VIEW = 'OrderPriceView',
  ORDER_PRICE_VIEW_BTN = 'OrderPriceView-Button',
  FILE_PREVIEW_ITEM_VIEW = 'FilePreviewItemView',
  FILE_PREVIEW_VIEW = 'FilePreviewView',
  FILE_PREVIEW_VIEW_INPUT_FILE = 'FilePreviewView-File',
  PAGINATION_IN_FRONT_VIEW = 'PaginationInFrontView',
  SUB_ITEM_ACCORDION = 'SubItemAccoridon',
  SEARCH_FOUNT_TEXT = 'SearchFoundText',
  INFO_CARD = 'InfoCard',
  SKELETON_BUTTON = 'SkeletonButton',
  SKELETON_SELECT = 'SkeletonSelect',
  SEARCH_WITH_CLEAR_BUTTON = 'SearchWithClearButton',
  REQUEST_BANNER = 'RequestBanner',
  INFO_BLOCK = 'InfoBlock'
}
