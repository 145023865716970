import React, { useState } from 'react';
import { ApplicabilityTextView } from './ApplicabilityTextView';
import { ApplicabilityTextProps } from './types';

export const ApplicabilityText = ({ isLoading, text }: ApplicabilityTextProps) => {
  if (!isLoading && !text) {
    return null;
  }

  const [showText, setShowText] = useState<boolean>(false);

  const handleShowAllText = () => setShowText(!showText);

  return (
    <ApplicabilityTextView
      handleShowAllText={handleShowAllText}
      isLoading={isLoading}
      showText={showText}
      text={text}
    />
  );
};
