import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormikErrors } from 'formik';

import { InputsForm } from '@shared/components/InputsForm/InputsForm';
import { CollaborateProps } from '@pages/Main/lib/Collaborate/ui/types';
import { FormikHandlers, FormikTouched } from 'formik/dist/types';
import { EMPTY_ARRAY } from '../constants/fallbacks';

export interface InputsFormValues {
  name?: string;
  phone?: string;
  email?: string;
  agreement?: boolean;
  comment?: string;
  file?: File[];
}

interface InputsFormType {
  values: InputsFormValues;
  setFieldValue: (file: string, files: File[] | undefined) => void;
  errors: FormikErrors<CollaborateProps>;
  handleChange: FormikHandlers['handleChange'];
  handleSubmit: FormikHandlers['handleSubmit'];
  handleBlur: FormikHandlers['handleBlur'];
  touched: FormikTouched<InputsFormValues>;
}

export const useInputsForm = ({
  values,
  setFieldValue,
  errors,
  handleChange,
  handleSubmit,
  handleBlur,
  touched
}: InputsFormType) => {
  const [submitDisabled, setSubmitDisabled] = useState(true);

  // Без функции dragOver, не работает перетаскивание файла в input
  const dragOver: React.DragEventHandler<HTMLLabelElement> = e => {
    e.preventDefault();
  };
  const fileDrop: React.DragEventHandler<HTMLLabelElement> = e => {
    e.preventDefault();

    const oldFiles = values.file?.length ? [...values.file] : EMPTY_ARRAY;

    if (e.dataTransfer?.files) {
      setFieldValue('file', [...oldFiles, e.dataTransfer?.files[0]]);
    }
  };
  const onChangeFileInput = (e: ChangeEvent<HTMLInputElement>) => {
    const oldFiles = values.file?.length ? [...values.file] : EMPTY_ARRAY;

    if (e.target.files) {
      setFieldValue('file', [...oldFiles, e.target.files[0]]);
    }
  };

  useEffect(() => {
    if (!values.phone || !values.comment) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(Object.keys(errors).length !== 0);
    }
  }, [errors]);

  const renderChildren = () => (
    <InputsForm
      deleteFile={handleDeleteFile}
      dragOver={dragOver}
      errors={errors}
      fileDrop={fileDrop}
      handleBlur={handleBlur}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      isCommentInput
      isInputFile
      isInputPhone
      isModal
      onChangeFileInput={onChangeFileInput}
      submitDisabled={submitDisabled}
      touched={touched}
      values={values}
    />
  );
  const handleDeleteFile = (id: number) => {
    const newFiles = values?.file?.filter((_, index) => index !== id);
    setFieldValue('file', newFiles);
  };

  return {
    dragOver,
    errors,
    fileDrop,
    onChangeFileInput,
    handleDeleteFile,
    values,
    setFieldValue,
    renderChildren
  };
};
