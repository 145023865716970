import { HeaderMenu, IconedText } from '@uremont/ui-kit';
import { Security, Service } from '@uremont/ui-icons-v2';
import { uremontLinks } from '@shared/constants/links';
import styles from './styles.module.scss';

export const MenuItems = () => (
  <>
    <div className={styles.headerTitleLink}>
      <a href={uremontLinks.map}>Обслуживание автомобиля</a>
    </div>
    <div className={styles.headerTitleLink}>
      <a href={uremontLinks.insurance}>Страхование</a>
    </div>
    <div className={styles.headerTitleLink}>
      <a href={uremontLinks.autoparts}>Запчасти</a>
    </div>
    <HeaderMenu title='Партнерство'>
      <a className={styles.headerLink} href={uremontLinks.insuranceAgents}>
        <IconedText icon={Security} iconSize={24} textType='bodyTightRegular'>
          Стать страховым агентом
        </IconedText>
      </a>
      <a className={styles.headerLink} href={uremontLinks.registerService}>
        <IconedText icon={Service} iconSize={24} textType='bodyTightRegular'>
          Для автосервиса
        </IconedText>
      </a>
    </HeaderMenu>
  </>
);
