import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { EMPTY_STRING } from '@shared/constants/fallbacks';

import { TextUnderPriceViewProps } from './types';
import styles from './styles.module.scss';

export const TextUnderPriceView = ({
  rest = EMPTY_STRING,
  days = EMPTY_STRING,
  isLoading
}: TextUnderPriceViewProps) => {
  return (
    <>
      <div className={styles.textUnderPrice_rest}>
        <Text color='secondary' variant='body-tight'>
          {isLoading ? <Skeleton height='20px' width='115px' /> : 'Осталось, шт.'}
        </Text>
        <Text variant='body-tight'>
          {isLoading ? <Skeleton height='20px' width='115px' /> : rest}
        </Text>
      </div>
      <div className={styles.textUnderPrice_days}>
        <Text color='secondary' variant='body-tight'>
          {isLoading ? <Skeleton height='20px' width='115px' /> : 'Доставка'}
        </Text>
        <Text variant='body-tight'>
          {isLoading ? <Skeleton height='20px' width='115px' /> : days}
        </Text>
      </div>
    </>
  );
};
