export enum ORDER_STATUSES {
  ORDER_CREATED = 1, // Заказ создан
  IN_WORK = 2, // В работе
  DELIVERY = 3, // Доставка
  TO_ISSUE = 4, // К выдаче
  ISSUED = 5, // Выдан
  REJECT = 6 // Отказ/Закрыт
}

export enum COOKIE_NAME {
  AUTH_TOKEN = 'auth_token',
  REFRESH_TOKEN = 'refresh_token',
  CITY_ID = 'city-id',
  COUNTRY_ID = 'country-id',
  CITY_NAME = 'city-name',
  REDIRECT_LINK = 'redirect-link'
}

export enum USER_ROLE {
  SERVICE = 'service',
  AUTOPARK = 'autopark',
  CUSTOMER = 'customer'
}
