import React from 'react';
import { PartsCatalogByCarViewProps } from 'src/pages/PartsCatalogByCar/ui/types';
import { Breadcrumbs } from 'src/shared/components/Breadcrumbs';
import { breadcrumbsForSearchLinks } from 'src/shared/constants/breadcrumbsForSearchLinks';
import { SearchProgressBar } from 'src/pages/Main/lib/SearchProgressBar';
import { RequestBanner } from 'src/shared/components/RequestBanner';
import {
  BANNER_BUTTON_TEXT,
  BANNER_SUBTITLE_TEXT,
  BANNER_TITLE_TEXT
} from 'src/shared/constants/ texts';
import { CustomModal } from 'src/shared/components/CustomModal/CustomModal';
import styles from './styles.module.scss';

export const PartsCatalogByCarView = ({
  Icon,
  renderChildren,
  toggleModal,
  isOpenModal
}: PartsCatalogByCarViewProps) => {
  return (
    <>
      <Breadcrumbs links={breadcrumbsForSearchLinks} />
      <SearchProgressBar />
      <div className={styles.bannerContainer}>
        <RequestBanner
          Icon={Icon}
          buttonText={BANNER_BUTTON_TEXT}
          onPress={toggleModal}
          subTitle={BANNER_SUBTITLE_TEXT}
          title={BANNER_TITLE_TEXT}
        />
        <CustomModal
          children={renderChildren}
          isOpen={isOpenModal}
          title='Заявка на поиск запчастей'
          toggleModal={toggleModal}
          width='xs'
        />
      </div>
    </>
  );
};
