import React from 'react';
import { Text } from '@uremont/erp-ui';
import cx from 'classnames';
import { ReactComponent as FirstAward } from '@assets/FirstAward.svg';
import { ReactComponent as LogoIcon } from '@assets/logo/FooterUremontLogo.svg';
import { ReactComponent as LogoMasterCard } from '@assets/LogoMasterCard.svg';
import { ReactComponent as LogoMir } from '@assets/LogoMir.svg';
import { ReactComponent as LogoUST } from '@assets/LogoUST.svg';
import { ReactComponent as LogoVisa } from '@assets/LogoVisa.svg';
import { ReactComponent as ParticipantIcon } from '@assets/ParticipantIcon.svg';
import { ReactComponent as SecondAward } from '@assets/SecondAward.svg';
import { dataTestId } from '@shared/constants/dataTestId';
import { CustomAccordion } from '@shared/components/CustomAccordion/CustomAccordion';
import {
  socialLinks,
  socialMediaLinks,
  storeLinks
} from '@features/AppLayout/components/Footer/lib/constants/links/socialLinks';
import { FooterProps } from '@features/AppLayout/components/Footer/ui/types';
import { countryCitiesLinks } from '@features/AppLayout/components/Footer/lib/constants/links/countryCitiesLinks';
import { menus } from '@features/AppLayout/components/Footer/lib/constants/links/groupDescriptionsOfLinks';
import { LogoLink } from '@features/AppLayout/components/Footer/lib/LogoLink/LogoLink';
import { SocialLink } from '@features/AppLayout/components/Footer/lib/SocialLink/SocialLink';
import { NavigationMenu } from '@features/AppLayout/components/Footer/lib/NavigationMenu/NavigationMenu';
import { InsuranceLink } from '@features/AppLayout/components/Footer/lib/InsuranceLink/InsuranceLink';
import { AdditionalInfoWithLinks } from '@features/AppLayout/components/Footer/lib/AdditionalInfoWithLinks/AdditionalInfoWithLinks';
import { insuranceCompaniesLinks } from '@features/AppLayout/components/Footer/lib/constants/links/insuranceCompaniesLinks';
import { additionalInfoLinks } from '@features/AppLayout/components/Footer/lib/constants/links/additionalInfoLinks';
import { infoTexts } from '@features/AppLayout/components/Footer/lib/constants/infoTexts';
import styles from './styles.module.scss';

export const FooterView = ({
  selectedCountryCityId,
  selectedMenu,
  icon,
  selectedBelCountry,
  isLargeScreen,
  participantLink
}: FooterProps) => {
  return (
    <div className={styles.footerView} data-testid={dataTestId.FOOTER_VIEW}>
      <div className={styles.footerView_content}>
        <div className={styles.footerView_mapAndTitleContainer}>
          <div
            className={cx(styles.footerView_cities, {
              [styles.footerView_cities_bel]: selectedBelCountry
            })}
          >
            <Text className={styles.footerView_cities_header} variant='header'>
              Автосервисы в городах <br /> {selectedMenu.countryTitle}
            </Text>
            <div
              className={cx(styles.footerView_cities_wrapper, {
                [styles.footerView_cities_wrapper_bel]: selectedBelCountry
              })}
            >
              {countryCitiesLinks[(selectedCountryCityId as keyof typeof menus) ?? 1].map(
                (link, index) => {
                  const { name, slug } = link;

                  return (
                    <Text
                      as='a'
                      className={styles.footerView_cities_title}
                      href={slug}
                      key={index}
                      variant='body'
                    >
                      {name}
                    </Text>
                  );
                }
              )}
            </div>
          </div>
          {isLargeScreen && icon}
        </div>
        <div className={styles.footerView_link}>
          <div className={styles.footerView_link_firstGroup}>
            <LogoLink>
              <div className={styles.footerView_link_logoWrap}>
                <LogoIcon className={styles.footerView_link_logo} />
              </div>
            </LogoLink>
            <div className={styles.footerView_link_firstGroup_wrapper}>
              {socialLinks.map((link, index) => (
                <SocialLink key={index} {...link} />
              ))}
            </div>
            <>
              <div className={styles.footerView_link_socialItemWrap}>
                {socialMediaLinks.map((link, index) => (
                  <SocialLink key={index} {...link} />
                ))}
              </div>
              <div className={styles.footerView_link_buttonStoreContainer}>
                {storeLinks.map((link, index) => (
                  <SocialLink key={index} {...link} />
                ))}
              </div>
            </>
          </div>
          {selectedMenu?.navigation.map((group, index) => (
            <NavigationMenu
              data-testid={dataTestId.FOOTER_VIEW_NAVIGATION_MENU}
              id={index}
              key={index}
              links={group.links}
              name={group.name}
              navigationMenuClass={styles.footerView_navigationMen}
            />
          ))}
          {selectedMenu?.navigation && (
            <CustomAccordion
              classNameContainer={styles.footerView_accordion}
              data-testid={dataTestId.FOOTER_VIEW_ACCORDION}
              isLoading={false} // TODO: когда в компонент будет интегрировано api, заменить false
              items={selectedMenu.navigation}
              textVariant='footnote-bold'
            />
          )}
          <div className={styles.footerView_achievements}>
            <SocialLink
              Icon={ParticipantIcon}
              className={styles.footerView_achievements_participantIcon}
              href={participantLink}
              isNewWindow
            />
            <div className={styles.footerView_achievements_wrapper}>
              <FirstAward />
              <Text className={styles.footerView_achievements_title} variant='caption'>
                Цифровой
                <br />
                Прорыв года 2019
              </Text>
            </div>
            <div className={styles.footerView_achievements_wrapper}>
              <SecondAward />
              <Text className={styles.footerView_achievements_title} variant='caption'>
                Премия
                <br />
                Рунета 2018
              </Text>
            </div>
            <div className={styles.footerView_achievements_cards}>
              <LogoMasterCard />
              <LogoMir />
              <LogoUST />
              <LogoVisa />
            </div>
          </div>
        </div>
        <InsuranceLink insuranceCompaniesLinks={insuranceCompaniesLinks} />
        <AdditionalInfoWithLinks additionalInfoLinks={additionalInfoLinks} />
        <div className={styles.footerView_info}>
          <Text className={styles.footerView_info_text} variant='caption'>
            {infoTexts.rightsReserved}
          </Text>
          <Text
            className={cx(styles.footerView_info_text, styles.footerView_info_text_certificate)}
            variant='caption'
          >
            {infoTexts.certificate}
          </Text>
          <Text className={styles.footerView_info_text} variant='caption'>
            {infoTexts.required}
          </Text>
          <Text
            className={cx(styles.footerView_info_text, styles.footerView_info_text_editorChief)}
            variant='caption'
          >
            {infoTexts.editorChief}
          </Text>
          <Text className={styles.footerView_info_text} variant='caption'>
            {infoTexts.copyrightHolder}
          </Text>
        </div>
      </div>
    </div>
  );
};
