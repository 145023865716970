export const AUTO_PART_BREADCRUMBS = 'Автозапчасти';
export const OIL_BREADCRUMBS = 'Масла';
export const OIL_NAME_BREADCRUMBS = 'Фильтр масляный Hyundai-KIA 263002Y500';
export const SEARCH_ARTICLE = 'Поиск по артикулу';
export const CHOICE_AUTO_BREADCRUMBS = 'Выбор авто';
export const CHOICE_DETAILS_BREADCRUMBS = 'Выбор детали';
export const SPARE_PARTS_BREADCRUMBS = 'Запчасти';
export const ORDER_BREADCRUMBS = 'К списку заказов';
export const HOME_BREADCRUMBS = 'Главный экран';
export const BASKET_BREADCRUMBS = 'Корзина';
export const PLACINGANORDER_BREADCRUMBS = 'Оформление заказа';
