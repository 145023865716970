import React, { ChangeEvent, useState } from 'react';
import { useFormik } from 'formik';
import { ModalFormView } from './ModalFormView';
import { FormikValues, ModalFormProps } from './types';
import { INITIAL_VALUES } from './constants';
import { insuranceRequest } from './service';

export const ModalForm = ({ onClose }: ModalFormProps) => {
  const [isSucceed, setIsSucceed] = useState(false);

  const handleSubmit = async (values: FormikValues) => {
    await insuranceRequest(values)
      .then(() => {
        setIsSucceed(true);
      })
      .catch(error => {
        setIsSucceed(false);
        // eslint-disable-next-line no-console
        console.warn(error);
      });
  };

  const formik = useFormik<FormikValues>({
    initialValues: INITIAL_VALUES,
    onSubmit: handleSubmit
  });
  const { isSubmitting, values, handleChange } = formik;
  const { name, phone } = values;
  const handleFormSubmit = (e: ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (isSubmitting) {
      return;
    }

    formik.handleSubmit();
  };

  const handleClose = () => {
    if (isSubmitting) {
      return;
    }

    onClose();
  };

  return (
    <ModalFormView
      isSubmitting={isSubmitting}
      isSucceed={isSucceed}
      name={name}
      onChange={handleChange}
      onClose={handleClose}
      onSubmit={handleFormSubmit}
      phone={phone}
    />
  );
};
