import { fetchData } from '@shared/api/apiConfig';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { AccordionItem } from '@shared/components/CustomAccordion/types';
import { FAQQuestionResponse } from './types';

export const getFAQ = async (): Promise<FAQQuestionResponse[] | void> => fetchData('getFAQ');

export const getConvertedFAQResponse = (data: FAQQuestionResponse[]): AccordionItem[] => {
  const dataFAQ = data.filter(item => item?.qna);

  return dataFAQ.map(item => ({
    id: item.id,
    name: item?.qna?.question || EMPTY_STRING,
    links: [
      {
        name: item?.qna?.answer || EMPTY_STRING,
        path: EMPTY_STRING,
        target: EMPTY_STRING
      }
    ]
  }));
};
