import React, { useCallback, useMemo, useState } from 'react';
import ReactSelect, { SingleValue } from 'react-select';

import { dataTestId } from '@shared/constants/dataTestId';
import { SelectProps } from './types';
import { getStyles } from './styles';
import { noOptionsMessage } from './texts';
import styles from './styles.module.scss';

export const Select = ({ defaultValue, onChange, options, placeholder, title }: SelectProps) => {
  const [hideDefaultValue, setHideDefaultValue] = useState(false);
  const getOptions = useMemo(() => {
    return options.map(item => ({ value: item, label: item }));
  }, [options]);
  const getValue = useMemo(() => {
    if (hideDefaultValue) return null;

    const findValue = options.find(option => option === defaultValue);

    return findValue
      ? {
          label: findValue,
          value: findValue
        }
      : null;
  }, [defaultValue, hideDefaultValue]);
  const handleChange = useCallback((newValue: SingleValue<{ value: string }>) => {
    onChange(newValue?.value as string);
  }, []);
  const handleMenuOpen = useCallback(() => {
    setHideDefaultValue(true);
  }, []);
  const handleMenuClose = useCallback(() => {
    setHideDefaultValue(false);
  }, []);

  return (
    <div className={styles.select} data-testid={dataTestId.CUSTOM_SELECT}>
      <label className={styles.selectLabel}>{title}</label>
      <ReactSelect
        menuPlacement='auto'
        noOptionsMessage={() => noOptionsMessage}
        onChange={handleChange}
        onMenuClose={handleMenuClose}
        onMenuOpen={handleMenuOpen}
        options={getOptions}
        placeholder={placeholder}
        styles={getStyles()}
        value={getValue}
      />
    </div>
  );
};
