import React from 'react';
import { Button, Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';

import styles from './styles.module.scss';
import { SearchMenuItemProps } from './types';

export const SearchMenuItem = ({
  text,
  isClickedSearchItem = false,
  isLoading = false,
  onClick
}: SearchMenuItemProps) => {
  if (isLoading) {
    return (
      <div className={styles.menuItem_skeleton}>
        <Skeleton height='40px' width='150px' />
        <Skeleton height='2px' width='150px' />
      </div>
    );
  }

  return (
    <Button
      data-testid={dataTestId.SEARCH_MENU_ITEM}
      onClick={onClick}
      variant='control'
      className={cx(styles.menuItem, {
        [styles.active]: isClickedSearchItem
      })}
    >
      <Text className={styles.menuItem_text} variant='body-tight'>
        {text}
      </Text>
    </Button>
  );
};
