import { Text } from '@uremont/erp-ui';
import React from 'react';
import { Breadcrumbs } from 'src/shared/components/Breadcrumbs';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { FilterComponent } from 'src/shared/components/FilterComponent';
import { breadcrumbsLinks } from 'src/shared/components/FilterComponent/lib/constants/breadcrumbsLinks';
import styles from './styles.module.scss';

export const FilterPageView = () => (
  <div className={styles.filterPage} data-testid={dataTestId.FILTER_PAGE_VIEW}>
    <Breadcrumbs links={breadcrumbsLinks} />
    <Text className={styles.filterPage_text} variant='header'>
      Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500
    </Text>
    <FilterComponent
      isLoading={false} // TODO: когда в компонент будет интегрировано api, заменить false
    />
  </div>
);
