import React from 'react';
import { Text } from '@uremont/erp-ui';
import { SearchByCarViewProps } from '@pages/SearchByCar/ui/types';
import { Breadcrumbs } from '@shared/components/Breadcrumbs';
import { CardsContainer } from '@shared/components/CardsContainer';
import { InfoCard } from '@shared/components/InfoCard/InfoCard';
import { dataTestId } from '@shared/constants/dataTestId';
import { breadcrumbsForSearchByCarCatalogLinks } from '@pages/SearchByCar/lib/constants/breadCrumbsForSearchByVIN';
import { RenderCard } from '@shared/components/RenderCard/ui';

import styles from './styles.module.scss';

export const SearchByCarView = ({
  carGroupParts,
  transformedSelectedModelData,
  isLoading
}: SearchByCarViewProps) => {
  return (
    <div className={styles.searchContainer} data-testid={dataTestId.SEARCH_BY_CAR_VIEW}>
      <Breadcrumbs links={breadcrumbsForSearchByCarCatalogLinks} />
      <InfoCard infoCardData={transformedSelectedModelData} isLoading={isLoading} />
      <Text className={styles.searchContainer_mainTitle} variant={'header'}>
        Каталог
      </Text>
      <CardsContainer>
        {carGroupParts.map(({ name, id, subgroups }, index) => (
          <RenderCard
            data-testid={dataTestId.RENDER_CARD}
            isLoading={isLoading}
            key={`${name}${index}`}
            links={subgroups}
            path={id}
            title={name}
          />
        ))}
      </CardsContainer>
    </div>
  );
};
