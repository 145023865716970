import React from 'react';

import { OrderCardItem } from '@shared/components/OrderCardItem/ui';
import { dataTestId } from '@shared/constants/dataTestId';

import { OrderCardItemListProps } from './types';

export const OrderCardItemList = ({ orderCardData, isLoading }: OrderCardItemListProps) => (
  <>
    {orderCardData.map((item, index) => (
      <OrderCardItem
        data-testid={`${dataTestId.ORDER_CARD_ITEM}-${item.id}`}
        isLastItem={index === orderCardData.length - 1}
        isLoading={isLoading}
        item={item}
        key={item.id}
      />
    ))}
  </>
);
