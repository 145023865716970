import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { CustomAccordion } from '@shared/components/CustomAccordion/CustomAccordion';
import { dataTestId } from '@shared/constants/dataTestId';

import { FAQViewProps } from './types';
import styles from './styles.module.scss';

export const FAQView = ({ FAQItems, isLoading }: FAQViewProps) => {
  return (
    <div className={styles.faq} data-testid={dataTestId.FAQ_VIEW}>
      <Text className={styles.title} variant='headline-L'>
        {isLoading ? <Skeleton height='36px' width='240px' /> : 'Частые вопросы'}
      </Text>
      <div>
        <CustomAccordion
          classNameContainer={styles.accordionWrap}
          data-testid={dataTestId.FAQ_VIEW_ACCORDION}
          isLoading={isLoading}
          items={FAQItems}
          textVariant='headline-H3'
        />
      </div>
    </div>
  );
};
