import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'src/shared/constants/fallbacks';
import { getCountries, getCurrentCountry } from 'src/shared/model/Store/Country/service';

import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { CollectionDefaultCountryData, CountiesStore } from './types';

export const useCountriesStore = create(
  devtools(
    persist<CountiesStore>(
      set => ({
        countries: EMPTY_ARRAY,
        currentCountry: EMPTY_OBJECT as CollectionDefaultCountryData,
        isLoading: false,

        getCountriesData: async () => {
          await executeRequest(
            getCountries,
            isLoading => set({ isLoading }),
            response => set({ countries: response ?? EMPTY_ARRAY })
          );
        },
        getCurrentCountry: async () => {
          await executeRequest(
            getCurrentCountry,
            isLoading => set({ isLoading }),
            response => {
              set({
                currentCountry: response ?? (EMPTY_OBJECT as CollectionDefaultCountryData)
              });
            }
          );
        },

        resetCountryState: () => {
          set({
            countries: EMPTY_ARRAY,
            currentCountry: EMPTY_OBJECT as CollectionDefaultCountryData
          });
        }
      }),
      {
        name: 'counties-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
