// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AqxAp6aGhuFpD1XH7XuG{display:flex;flex-direction:column;width:100%;gap:.25rem}.FxvfumY2Hf3Zzaccsbwg{font-size:.813rem;font-weight:400;line-height:1rem}`, "",{"version":3,"sources":["webpack://./src/shared/components/Select/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,UAAA,CACA,UAAA,CAEA,sBACE,iBAAA,CACA,eAAA,CACA,gBAAA","sourcesContent":[".select {\n  display: flex;\n  flex-direction: column;\n  width: 100%;\n  gap: 0.25rem;\n\n  &Label {\n    font-size: 0.813rem;\n    font-weight: 400;\n    line-height: 1rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": `AqxAp6aGhuFpD1XH7XuG`,
	"selectLabel": `FxvfumY2Hf3Zzaccsbwg`
};
export default ___CSS_LOADER_EXPORT___;
