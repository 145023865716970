import React from 'react';
import { Button, Skeleton, Text } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';

import { OrderPriceViewProps } from './types';
import styles from './styles.module.scss';

export const OrderPriceView = ({
  btnText,
  quantity,
  price,
  discount,
  total,
  isMinCount,
  isDisabledSubmit,
  handleSubmit,
  isLoading
}: OrderPriceViewProps) => {
  return (
    <div className={styles.orderPrice} data-testid={dataTestId.ORDER_PRICE_VIEW}>
      <Text className={styles.orderPrice_title} variant='headline-H3'>
        {isLoading ? <Skeleton height='24px' width='100%' /> : 'Сумма заказа'}
      </Text>
      <div className={styles.orderPrice_rest}>
        {isLoading ? (
          <Skeleton height='24px' width='300px' />
        ) : (
          <>
            <div className={styles.orderPrice_rest_left}>
              <Text variant='body-tight'>{quantity} товаров</Text>
            </div>
            <Text variant='title'>{price} ₽</Text>
          </>
        )}
      </div>
      <div className={styles.orderPrice_discount}>
        {Boolean(discount) &&
          (isLoading ? (
            <Skeleton height='20px' width='300px' />
          ) : (
            <>
              <Text variant='body-tight'>Скидка</Text>
              <Text color='success' variant='title'>
                -{discount} ₽
              </Text>
            </>
          ))}
      </div>
      <div className={styles.orderPrice_result}>
        <Text variant='headline-H2'>
          {isLoading ? <Skeleton height='32px' width='50px' /> : 'Итог'}
        </Text>
        {isLoading ? (
          <Skeleton height='20px' width='60px' />
        ) : (
          <Text variant='headline-H2'>{total} ₽</Text>
        )}
      </div>
      {isLoading ? (
        <Skeleton height='48px' width='100%' />
      ) : (
        <Button
          className={styles.orderPrice_btnBasket}
          data-testid={dataTestId.ORDER_PRICE_VIEW_BTN}
          disabled={isDisabledSubmit}
          loading={isLoading}
          onClick={handleSubmit}
          size='md'
          variant='solid'
        >
          <Text className={styles.orderPrice_btnBasket_text} variant='body-tight'>
            {btnText}
          </Text>
        </Button>
      )}

      {isMinCount && (
        <Text
          align='center'
          className={styles.orderPrice_isMinCount}
          color='warning'
          variant='body-tight'
        >
          {isLoading ? (
            <Skeleton height='25px' width='300px' />
          ) : (
            '  Минимальная сумма заказа от 1000 рублей'
          )}
        </Text>
      )}
    </div>
  );
};
