import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { ProductStore } from './types';
import { getProductInfo } from './service';
import { skeletonProductData } from './constants';
import { responseProductInfoToData } from './functions';

export const useProductStore = create(
  devtools(
    persist<ProductStore>(
      set => {
        return {
          isLoading: false,
          product: skeletonProductData,

          getProductInfoData: async (brand, number, currentItem) => {
            await executeRequest(
              () => getProductInfo(brand, number, 5),
              isLoading => set({ isLoading }),
              response => {
                const productInfoToData = response
                  ? responseProductInfoToData(response, currentItem)
                  : skeletonProductData;

                set({ product: productInfoToData });
              }
            );
          },

          resetProduct: () => set({ product: skeletonProductData })
        };
      },
      {
        name: 'product-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
