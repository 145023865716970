export const IN_BASKET_TEXT = 'В корзину';
export const VENDOR_CODE_TEXT = 'Артикул';
export const BRAND_TEXT = 'Бренд';
export const EMPTY_REQUEST_TEXT = 'По вашему запросу ничего не найдено';
export const BANNER_TITLE_TEXT = 'Не уверены в правильном выборе?';
export const BANNER_SUBTITLE_TEXT = 'Отправьте VIN-запрос нашему эксперту, и мы поможем с выбором';
export const BANNER_BUTTON_TEXT = 'Оставить заявку';
export const IMAGE_TYPE = 'Изображение';
export const MORE_PRODUCTS_TEXT = 'Больше товаров';
export const HIDE_TEXT = 'Скрыть';
