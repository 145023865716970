import { Button, Text } from '@uremont/erp-ui';
import { ReactComponent as Ghost } from 'src/assets/Ghost.svg';
import { ReactComponent as CheckCircle } from 'src/assets/CheckCircle.svg';
import { ReactComponent as CloseCircle } from 'src/assets/CloseCircle.svg';
import { NoInfoViewProps } from 'src/shared/components/NoInfo/ui/types';
import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';

export const NoInfoView = ({
  onClick,
  isSearchByDetails,
  isPlacingAnOrder = false,
  isError,
  isGRZError
}: NoInfoViewProps) => (
  <div className={styles.container} data-testid={dataTestId.NO_INFO}>
    <div className={styles.container_contentWrapper}>
      {isPlacingAnOrder ? (
        isError ? (
          <CloseCircle className={styles.container_contentWrapper_icon} />
        ) : (
          <CheckCircle className={styles.container_contentWrapper_icon} />
        )
      ) : (
        <Ghost className={styles.container_contentWrapper_icon} />
      )}
      <Text className={styles.container_contentWrapper_header} variant='headline-H4'>
        {isPlacingAnOrder
          ? isError
            ? 'Ошибка'
            : 'Оплата прошла успешно'
          : isSearchByDetails
          ? 'Пока деталей нет...'
          : isGRZError
          ? 'ГРЗ не найден...'
          : 'Мы ничего не нашли...'}
      </Text>
      <Text className={styles.container_contentWrapper_text} variant='body-tight'>
        {isPlacingAnOrder
          ? isError
            ? 'Не удалость провести платеж по карте. Хотите вернуться и оплатить заказ снова?'
            : 'Мы приняли ваш заказ в обработку и скоро сообщим срок получения'
          : 'Вы можете оставить заявку на подбор запчастей, и мы поможем с выбором'}
      </Text>
      <Button className={styles.container_contentWrapper_button} onClick={onClick} variant='solid'>
        <Text className={styles.container_contentWrapper_button_text} variant='body-tight'>
          {isPlacingAnOrder
            ? isError
              ? 'К заказу'
              : 'На главную страницу'
            : 'Оставить заявку на подбор запчастей'}
        </Text>
      </Button>
    </div>
  </div>
);
