import { CarGenerationsType, ParameterKeys } from 'src/shared/model/Store/CarCatalog/types';
import { EMPTY_ARRAY } from 'src/shared/constants/fallbacks';

export type ParameterValues = Array<{ [key: string]: string }>;

export const getModificationParametersById = (
  obj: CarGenerationsType | null,
  id: string
): ParameterValues | null => {
  if (!obj) {
    return EMPTY_ARRAY;
  }
  const foundItems = Object.values(obj)
    .flatMap(items => items)
    .filter(item => item.name === id);
  if (foundItems.length === 0) {
    return null;
  }
  const convertedParams: ParameterValues = [];

  foundItems.forEach(item => {
    const parameters: { [key: string]: string } = {
      ['Марка']: item.name,
      id: item.id,
      brand: item.catalogId,
      description: item.description,
      name: item.brand
    };
    Object.values(item.modification_parameters).forEach((value: ParameterKeys) => {
      if (value.name !== null && value.value !== null) {
        parameters[value.name] = value.value;
      }
    });

    convertedParams.push(parameters);
  });

  return convertedParams;
};
