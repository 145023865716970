import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import { roundedNumber } from '@shared/functions/formattedNumber/roundedNumber';
import { throttle } from 'lodash';
import { convertHoursToDays } from '@utils/convertHoursToDays';
import { useRecommendedPurchaseStore } from '@shared/model/Store/RecommendedPurchase';
import { useSearchDetailsStore } from '@shared/model/Store/SearchDetails';
import { useProductStore } from '@shared/model/Store/Product';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { pathConfig } from '@shared/constants/pathConfig';
import { formattedDeliveryDays } from '@shared/functions/formattedText/formattedDeliveryDays';
import { NoInfoView } from '@shared/components/NoInfo/ui/NoInfoView';
import { breadcrumbsLinks } from '@pages/ProductPage/lib/constants/breadcrumbsLinks';
import { convertRecommendedArticleProductsData } from '@pages/ProductPage/lib/functions/convertRecommendedArticleProductsData/convertRecommendedArticleProductsData';
import { useScrollToTop } from '@shared/hooks/use-scroll-to-top';
import { applicabilityKey } from '../lib/constants/constants';
import { ProductPageView } from './ProductPageView';

export const ProductPage = () => {
  const [recommendedArticleProducts] = useRecommendedPurchaseStore(state => [
    state.recommendedArticleProducts
  ]);

  const [
    // selectedArticle, //TODO: Раскомментировать запрос, когда будет известно конечное флоу для "С этим покупают"
    searchByArticleDetailsData,
    isLoadingSearchByArticleDetailsData,
    getSearchDetailsByArticleData
  ] = useSearchDetailsStore(state => [
    // state.selectedArticle, //TODO: Раскомментировать запрос, когда будет известно конечное флоу для "С этим покупают"
    state.searchByArticleDetailsData,
    state.isLoadingSearchByArticleDetailsData,
    state.getSearchDetailsByArticleData
  ]);

  const [product, isLoading, getProductInfoData, resetProduct] = useProductStore(state => [
    state.product,
    state.isLoading,
    state.getProductInfoData,
    state.resetProduct
  ]);

  const [mainPhoto, setMainPhoto] = useState(EMPTY_STRING);
  const [count, setCount] = useState<number>(0);
  const [isPriceBlockScrolled, setIsPriceBlockScrolled] = useState(false);
  const [daysText, setDaysText] = useState(EMPTY_STRING);
  const [breadcrumbsSecond, setBreadcrumbsSecond] = useState(EMPTY_STRING);

  const { detailed_product = EMPTY_STRING } = useParams();
  const params = detailed_product.split('_');
  const selectedBrand = params[0];
  const selectedIemArticle = params[1];
  const selectedPartHash = params[2];

  const recommendedData = convertRecommendedArticleProductsData(recommendedArticleProducts);

  const countNotZero = count !== 0;
  const price = product.price ? String(roundedNumber(product.price)) : EMPTY_STRING;

  const handleChangePhoto = (index: number) => {
    setMainPhoto(product.images[index].url);
  };

  const handleAdd = () => setCount(count + 1);

  const handleDelete = () => {
    if (count <= 0) {
      return;
    }
    setCount(count - 1);
  };

  const navigate = useNavigate();

  const goBack = () => navigate(pathConfig.MainPath);

  const newDaysText = useMemo(
    () => formattedDeliveryDays(convertHoursToDays(product.deliveryPeriod ?? 0)),
    [product.deliveryPeriod]
  );

  useScrollToTop();

  useEffect(() => {
    //TODO: Раскомментировать запрос, когда будет известно конечное флоу для "С этим покупают"
    //getRecommendedArticleProductsData брать из useRecommendedPurchaseStore
    //getRecommendedArticleProductsData(selectedBrand, selectedArticle);

    const handleScroll = throttle(() => {
      const element = document.getElementById('productPage_priceBlock');
      if (!element) return;

      const elementPosition = element.offsetTop;
      const scrollPosition = window.scrollY;
      setIsPriceBlockScrolled(scrollPosition > elementPosition + 200);
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      handleScroll.cancel();
      resetProduct();
    };
  }, [setIsPriceBlockScrolled]);

  // TODO: Временное решение, пока в запрос getProductInfoData не добавят недостающие поля
  useEffect(() => {
    getSearchDetailsByArticleData(selectedIemArticle, selectedBrand);
  }, []);

  // TODO: Временное решение, пока в запрос getProductInfoData не добавят недостающие поля
  useEffect(() => {
    const currentItem =
      searchByArticleDetailsData &&
      searchByArticleDetailsData.find(item => item.partHash === selectedPartHash);

    if (currentItem) {
      setBreadcrumbsSecond(currentItem.description || EMPTY_STRING);
      getProductInfoData(selectedBrand, selectedIemArticle, currentItem);
    }
  }, [searchByArticleDetailsData]);

  useEffect(() => {
    setDaysText(newDaysText);

    if (product && product.images?.length) {
      setMainPhoto(product.images[0].url);
    } else {
      setMainPhoto(EMPTY_STRING);
    }
  }, [product]);

  if (
    (!isLoading || !isLoadingSearchByArticleDetailsData) &&
    (product.part_hash === EMPTY_STRING || product.part_hash === undefined)
  ) {
    return <NoInfoView onClick={goBack} />;
  }

  return (
    <ProductPageView
      breadcrumbsLinks={breadcrumbsLinks(breadcrumbsSecond, selectedBrand, selectedIemArticle)}
      count={count}
      countNotZero={countNotZero}
      days={daysText}
      handleAdd={handleAdd}
      handleChangePhoto={handleChangePhoto}
      handleDelete={handleDelete}
      infoList={product.properties}
      isLoading={isLoading || isLoadingSearchByArticleDetailsData} //TODO: Временно добавлен isLoadingSearchByArticleDetailsData, пока не добавят недостающие поля в запрос getProductInfoData
      isPriceBlockScrolled={isPriceBlockScrolled}
      item={product}
      mainPhoto={mainPhoto}
      name={breadcrumbsSecond}
      photos={product.images}
      price={price}
      recommendations={recommendedData}
      rest={product.availability}
      text={product.properties[applicabilityKey] || EMPTY_STRING}
    />
  );
};
