import React from 'react';
import { Button, Text } from '@uremont/erp-ui';
import { ReactComponent as IconCross } from 'src/assets/IconCross.svg';
import { ReactComponent as IconFile } from 'src/assets/IconFile.svg';
import { dataTestId } from 'src/shared/constants/dataTestId';
import styles from './styles.module.scss';
import { FilePreviewItemViewProps } from './types';

export const FilePreviewItemView = ({
  name,
  isImg,
  urlImg,
  deleteFile
}: FilePreviewItemViewProps) => (
  <div className={styles.filePreviewItem} data-testid={dataTestId.FILE_PREVIEW_ITEM_VIEW}>
    <div className={styles.filePreviewItem_top}>
      <Text className={styles.filePreviewItem_name} title={name} variant='body-tight'>
        {name}
      </Text>
      <Button className={styles.filePreviewItem_btnClose} onClick={deleteFile} variant='control'>
        <IconCross />
      </Button>
    </div>
    {isImg ? <img alt={name} className={styles.filePreviewItem_img} src={urlImg} /> : <IconFile />}
  </div>
);
