import { ArrowSlider } from 'src/shared/components/ArrowSlider';
import styles from '../../ui/styles.module.scss';

export const pickupPointSettings = {
  className: styles.slider,
  dots: false,
  infinite: false,
  arrows: true,
  nextArrow: <ArrowSlider arrowDirection='right' buttonShadow={true} />,
  prevArrow: <ArrowSlider arrowDirection='left' buttonShadow={true} />,
  speed: 500,
  slidesToShow: 2.5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 1022,
      settings: {
        slidesToShow: 2.5
      }
    },
    {
      breakpoint: 550,
      settings: {
        slidesToShow: 1.1
      }
    }
  ]
};
