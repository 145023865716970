import { pathConfig } from '@shared/constants/pathConfig';
import {
  CHOICE_AUTO_BREADCRUMBS,
  HOME_BREADCRUMBS,
  SPARE_PARTS_BREADCRUMBS
} from '@shared/constants/breadcrumbsText';

export const breadcrumbsLinksForCatalogItemPrice = (
  currentProduct: string,
  isSearchArticlePage: boolean
) => {
  if (isSearchArticlePage) {
    return [
      {
        active: false,
        href: pathConfig.MainPath,
        label: HOME_BREADCRUMBS
      },
      {
        active: true,
        label: currentProduct
      }
    ];
  }

  return [
    {
      active: false,
      href: pathConfig.MainPath,
      label: HOME_BREADCRUMBS
    },
    {
      active: false,
      href: pathConfig.PartsCatalogByCarPath,
      label: CHOICE_AUTO_BREADCRUMBS
    },

    {
      active: false,
      href: pathConfig.CatalogPath,
      label: SPARE_PARTS_BREADCRUMBS
    },
    {
      active: true,
      label: currentProduct
    }
  ];
};
