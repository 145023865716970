import React from 'react';
import { Skeleton } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';
import styles from './styles.module.scss';

export const SkeletonSelect = () => {
  return (
    <div className={styles.skeletonSelect} data-testid={dataTestId.SKELETON_SELECT}>
      <Skeleton height='68px' width='100%' />
    </div>
  );
};
