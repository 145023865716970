import { getHostname } from './getHostname';

export const getDomainForCookie = () => {
  if (typeof process !== 'undefined' && process.env && process.env.NODE_ENV === 'test') {
    return '';
  }

  if (typeof window === 'undefined') {
    return '';
  }

  const { hostname } = window.location;

  return getHostname(hostname);
};
