import React, { useEffect, useState } from 'react';

import { EMPTY_OBJECT, EMPTY_STRING } from '@shared/constants/fallbacks';
import { Brand } from '@pages/FilterPage/ui/types';
import { FilterView } from './FilterView';

import { FilterProps } from './types';

export const Filter = ({
  // TODO: Пропсы для Срок доставки пока скрыты, так как в будущем может потребуется вернуть этот функционал
  // deliveries,
  // selectedDelivery,
  // handleSelectedDelivery,
  // handleDay,
  brands,
  handleBrands,
  availability,
  day,
  handleAvailability,
  handleSearch,
  dirty,
  showFilter,
  handleShowFilter,
  isLoading
}: FilterProps) => {
  const [isShowAllBrands, setIsShowAllBrands] = useState<boolean>(false),
    handleIsShowAllBrands = () => setIsShowAllBrands(!isShowAllBrands),
    handleSearchBtn = () => {
      handleSearch();

      if (showFilter) {
        handleShowFilter();
      }
    };

  const skeletonData = Array.from({ length: 3 }, () => EMPTY_OBJECT as Brand);

  const brandsData = isLoading ? skeletonData : brands;
  const showBtnShowAll = brands.length > 3;

  useEffect(() => {
    if (showFilter) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = EMPTY_STRING;
    }
  }, [showFilter]);

  return (
    <FilterView
      availability={availability}
      brands={brandsData}
      day={day}
      dirty={dirty}
      handleAvailability={handleAvailability}
      handleBrands={handleBrands}
      handleIsShowAllBrands={handleIsShowAllBrands}
      handleSearch={handleSearchBtn}
      handleShowFilter={handleShowFilter}
      isLoading={isLoading}
      isShowAllBrands={isShowAllBrands}
      showBtnShowAll={showBtnShowAll}
      showFilter={showFilter}
      // TODO: Пропсы для Срок доставки пока скрыты, так как в будущем может потребуется вернуть этот функционал
      // deliveries={deliveries}
      // handleDay={handleDay}
      // handleSelectedDelivery={handleSelectedDelivery}
      // selectedDelivery={selectedDelivery}
    />
  );
};
