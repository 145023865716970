import React from 'react';
import { Button, Skeleton, Text } from '@uremont/erp-ui';

import { OrderCardItem } from '@shared/components/OrderCardItem/ui';
import { OrderCardProps } from '@shared/components/OrderCard/types';
import { dataTestId } from '@shared/constants/dataTestId';
import { getOrderStatus } from '@shared/functions/getOrderStatus/getOrderStatus';

import styles from './styles.module.scss';

export const OrderCard = ({
  dataOrder,
  orderCardData,
  isLoading,
  handleRepeatOrder
}: OrderCardProps) => {
  return (
    <div className={styles.orderCard} data-testid={dataTestId.ORDER_CARD}>
      <div className={styles.orderCard_header}>
        <div className={styles.orderCard_header_repeateTitleContainer}>
          {isLoading ? <Skeleton height='24px' width='150px' /> : getOrderStatus(dataOrder.status)}
          {isLoading ? (
            <Skeleton height='24px' width='150px' />
          ) : (
            <Button
              className={styles.orderCard_header_repeateTitle}
              onClick={() => handleRepeatOrder(dataOrder.id)}
              variant='control'
            >
              Повторить заказ
            </Button>
          )}
        </div>
        <div className={styles.orderCard_header_titleContainer}>
          <div className={styles.orderCard_header_titleContainer_dateAndNumberWrapper}>
            <Text
              className={styles.orderCard_header_titleContainer_dateAndNumberWrapper_dateAndNumber}
              variant='headline-H2'
            >
              {isLoading ? (
                <Skeleton height='24px' width='240px' />
              ) : (
                `Заказ от ${dataOrder.dateOrder}`
              )}
            </Text>
            {!isLoading && (
              <div className={styles.orderCard_header_titleContainer_dateAndNumberWrapper_dash} />
            )}
            <Text
              className={styles.orderCard_header_titleContainer_dateAndNumberWrapper_dateAndNumber}
              variant='headline-H2'
            >
              {!isLoading && `№ ${dataOrder.numberOrder}`}
            </Text>
          </div>
          <Text className={styles.orderCard_header_titleContainer_price} variant='headline-H2'>
            {isLoading ? <Skeleton width='150px' /> : `${dataOrder.sumOrder} ₽`}
          </Text>
        </div>
        <Text className={styles.orderCard_header_link} variant='body-tight'>
          {isLoading ? <Skeleton width='150px' /> : `Адрес доставки: ${dataOrder.deliveryLink}`}
        </Text>
      </div>
      {dataOrder.historyOrder.map((item, index) => {
        return (
          <OrderCardItem
            data-testid={`${dataTestId.ORDER_CARD_ITEM}-${item.id}`}
            isLastItem={index === orderCardData.length - 1}
            isLoading={isLoading}
            item={item}
            key={item.id}
          />
        );
      })}
      {/* {isLoading ? ( // TODO: Скрываем эту страницу, восстановим когда будем расширять приложение (Сроков пока нет), на данном этапе страница не требуется
        <Skeleton className={styles.orderCard_button} width='150px' />
      ) : (
        <Link
          className={styles.orderCard_button}
          data-testid={dataTestId.ORDER_BUTTON}
          to={`/order/${dataOrder.id}`}
        >
          <Text className={styles.orderCard_button_text} variant='body-tight'>
            Перейти в заказ
          </Text>
        </Link>
      )} */}
    </div>
  );
};
