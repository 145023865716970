import React from 'react';
import { IconArrowLeft, Input } from '@uremont/erp-ui';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';
import { ArticleDropdown } from '@shared/components/ArticleDropdown/ArticleDropdown';

import { SearchByArticleInputType } from './types';
import styles from './styles.module.scss';

export const SearchByArticleInput = ({
  isLoadingArticle,
  articleTips,
  searchValue,
  searchWithClearButton,
  handleSearchChange,
  handleDropdownItemClick,
  handleInputBlur,
  handleInputFocus,
  isOpenDropdown,
  dropdownRef,
  handleButtonClick,
  isFocused,
  isMobile,
  goBack,
  classNameInput
}: SearchByArticleInputType) => (
  <div className={styles.searchByArticleInput} data-testid={dataTestId.SEARCH_BY_ARTICLE_INPUT}>
    {isOpenDropdown && isMobile && <IconArrowLeft className={styles.arrow} onClick={goBack} />}
    <Input
      action={searchWithClearButton}
      className={cx(classNameInput, isFocused && styles.focused)}
      data-testid={dataTestId.SEARCH_BY_ARTICLE_VIEW_INPUT_BTN}
      onBlur={handleInputBlur}
      onChange={e => handleSearchChange(e.target.value)}
      onFocus={handleInputFocus}
      onKeyDown={e => e.key === 'Enter' && handleButtonClick()}
      placeholder='Введите артикул'
      value={searchValue}
    />
    {isOpenDropdown && <div className={styles.overlay} onClick={handleInputBlur} />}
    {isOpenDropdown && (
      <ArticleDropdown
        articleTips={articleTips}
        dropdownRef={dropdownRef}
        handleDropdownItemClick={handleDropdownItemClick}
        isLoading={isLoadingArticle}
        searchValue={searchValue}
      />
    )}
  </div>
);
