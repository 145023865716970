import React from 'react';
import { Button, Radio, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { Select } from '@shared/components/Select/Select';
import {
  SELECT_CAR_STEP,
  SELECT_GENERATION_STEP,
  SELECT_MODIFICATION_STEP,
  steps
} from '@pages/Main/lib/constants/consts';
import { dataTestId } from '@shared/constants/dataTestId';
import { SkeletonButton } from '@shared/components/SkeletonButton';
import { getMinMaxYearRange } from '@shared/functions/getMinMaxYearRange/getMinMaxYearRange';
import { headers } from '@pages/Main/lib/SearchProgressBar/lib/consts';
import { SkeletonSelect } from '@shared/components/SkeletonSelect';
import { CustomTable } from '@shared/components/CustomTable';
import { PaginationInFront } from '@shared/components/PaginationInFront';
import { SkeletonCarGenerations } from '../lib/SkeletonCarGenerations';

import { SearchProgressBarProps } from './types';
import styles from './styles.module.scss';

export const SearchProgressBarView = ({
  carGenerations,
  isDisabled,
  handleRadioChangeForTable,
  selectedRow,
  carNames,
  selectedRadio,
  handleRadioChange,
  handleSelectModelChange,
  handleSelectBrandChange,
  handlePrevious,
  handleNext,
  step,
  carModelsNames,
  selectedBrandValue,
  selectedModelValue,
  isLoadingCarBrands,
  isLoadingCarModels,
  isLoadingCarGenerations,
  items,
  currentItems,
  setCurrentItems
}: SearchProgressBarProps) => {
  return (
    <>
      <div className={styles.progressBar} data-testid={dataTestId.SEARCH_VIEW_PROGRESSBAR}>
        {steps.map((stepItem, index) => (
          <div
            key={index}
            className={`${styles.progressBar_stepperItem} ${
              step === index + 1
                ? styles.active
                : step > index + 1
                ? styles.completed
                : EMPTY_STRING
            }`}
          >
            <div
              className={`${styles.progressBar_stepCounter} ${
                step > index ? styles.completed : EMPTY_STRING
              }`}
            />
            <Text className={styles.progressBar_stepperItem_title} variant='body-tight'>
              {stepItem.label}
            </Text>
            <Text
              className={styles.progressBar_stepperItem_subTitle}
              color='disabled'
              variant='caption-regular'
            >
              {stepItem.caption}
            </Text>
          </div>
        ))}
      </div>
      {step === SELECT_CAR_STEP && (
        <div data-testid={dataTestId.SEARCH_VIEW_CHOICE_MODEL}>
          <Text className={styles.selectTitle} variant='headline-H2'>
            Выберите марку и модель
          </Text>
          <div className={styles.selectWrapper}>
            {isLoadingCarBrands ? (
              <SkeletonSelect />
            ) : (
              <Select
                data-testid={dataTestId.SEARCH_VIEW_CHOICE_MODEL_BRAND}
                defaultValue={selectedBrandValue}
                onChange={handleSelectBrandChange}
                options={carNames}
                placeholder='Выберите марку'
                title='Марка'
              />
            )}
            {isLoadingCarBrands ? (
              <SkeletonSelect />
            ) : (
              <Select
                data-testid={dataTestId.SEARCH_VIEW_CHOICE_MODEL_MODEL}
                defaultValue={selectedModelValue || carModelsNames[0]}
                onChange={handleSelectModelChange}
                options={carModelsNames}
                placeholder='Выберите модель'
                title='Модель'
              />
            )}
          </div>
        </div>
      )}
      {step === SELECT_GENERATION_STEP && (
        <div data-testid={dataTestId.SEARCH_VIEW_SELECT_GENERATION}>
          <Text className={styles.selectTitle} variant='headline-H2'>
            Выберите поколение
          </Text>
          <div className={styles.autoAge}>
            {isLoadingCarGenerations ? (
              <SkeletonCarGenerations />
            ) : (
              carGenerations &&
              Object.keys(carGenerations).map(modelName => {
                const generations = carGenerations[modelName];
                const years = generations.map(
                  option => option.modification_parameters?.year?.value
                );

                return (
                  <Radio
                    checked={selectedRadio === generations[0].name}
                    className={styles.autoAge_radioContainer}
                    data-testid={`${dataTestId.SEARCH_VIEW_SELECT_GENERATION_RADIO}-${generations[0].id}`}
                    key={generations[0].id}
                    onChange={() => handleRadioChange(generations[0].name)}
                    children={
                      <div className={styles.autoAge_radioContainer_wrapper}>
                        <Text
                          className={styles.autoAge_radioContainer_wrapper_title}
                          variant='headline-H3'
                        >
                          {modelName}
                        </Text>
                        <Text
                          className={styles.autoAge_radioContainer_wrapper_subTitle}
                          variant='body-tight'
                        >
                          {getMinMaxYearRange(years)}
                        </Text>
                      </div>
                    }
                  />
                );
              })
            )}
          </div>
        </div>
      )}
      {step === SELECT_MODIFICATION_STEP && (
        <>
          <Text
            className={styles.selectTitle}
            data-testid={dataTestId.SEARCH_VIEW_SELECT_MODIFICATION}
            variant='headline-H2'
          >
            Выберите модификацию
          </Text>
          <div className={styles.tableContainer}>
            <CustomTable
              data={currentItems}
              data-testid={dataTestId.SEARCH_VIEW_SELECT_MODIFICATION_TABLE}
              handleRadioChange={handleRadioChangeForTable}
              headers={headers}
              isLoading={isLoadingCarGenerations}
              selectedRow={selectedRow}
            />
          </div>
          <PaginationInFront items={items} setCurrentItems={setCurrentItems} />
        </>
      )}
      <div className={styles.button_container}>
        {step !== SELECT_CAR_STEP && (
          <Button
            className={cx(styles.button, styles.button_back)}
            data-testid={dataTestId.SEARCH_VIEW_SELECT_MODIFICATION_BTN_BACK}
            onClick={handlePrevious}
            variant='default'
          >
            <Text className={styles.button_text} variant='body-tight'>
              Назад
            </Text>
          </Button>
        )}
        {isLoadingCarBrands ? (
          <SkeletonButton />
        ) : (
          <Button
            className={styles.button}
            data-testid={dataTestId.SEARCH_VIEW_SELECT_MODIFICATION_BTN_NEXT}
            disabled={isDisabled}
            loading={isLoadingCarModels}
            onClick={handleNext}
            variant='solid'
          >
            <Text className={styles.button_text} variant='body-tight'>
              Далее
            </Text>
          </Button>
        )}
      </div>
    </>
  );
};
