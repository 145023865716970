import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { StickyInfoProduct } from '@pages/ProductPage/lib/StickyInfoProduct';
import { Breadcrumbs } from '@shared/components/Breadcrumbs';
import { BRAND_TEXT, IN_BASKET_TEXT, VENDOR_CODE_TEXT } from '@shared/constants/ texts';
import { BasketButton } from '@shared/components/BasketButton';
import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholderForCard.png';
import { dataTestId } from '@shared/constants/dataTestId';
import { AboutProduct } from '../lib/AboutProduct';
import { PhotosBtn } from '../lib/PhotosBtn';
import { ApplicabilityText } from '../lib/ApplicabilityText';

import { ProductPageViewProps } from './types';
import styles from './styles.module.scss';

export const ProductPageView = ({
  item,
  name,
  photos,
  mainPhoto,
  handleChangePhoto,
  rest,
  days,
  price,
  count,
  countNotZero,
  handleAdd,
  handleDelete,
  infoList,
  text,
  isLoading,
  isPriceBlockScrolled,
  breadcrumbsLinks
}: ProductPageViewProps) => {
  return (
    <div className={styles.productPage} data-testid={dataTestId.PRODUCT_PAGE}>
      <Breadcrumbs links={breadcrumbsLinks} />
      <StickyInfoProduct
        count={count}
        countNotZero={countNotZero}
        handleAdd={handleAdd}
        handleDelete={handleDelete}
        isLoading={isLoading}
        isPriceBlockScrolled={isPriceBlockScrolled}
        item={item}
        mainPhoto={mainPhoto}
        name={name}
        textBtn={IN_BASKET_TEXT}
      />
      <div className={styles.productPage_product}>
        <div className={styles.productPage_product_top_wrap}>
          <div className={styles.productPage_product_top}>
            <div className={styles.productPage_product_top_left}>
              <div className={styles.productPage_photos}>
                <div className={styles.productPage_photos_list}>
                  <PhotosBtn
                    handleChangePhoto={handleChangePhoto}
                    isLoading={isLoading}
                    photos={photos}
                  />
                </div>
              </div>
              <div className={styles.productPage_photos_list_main}>
                {isLoading ? (
                  <Skeleton height='254px' width='254px' />
                ) : (
                  <img
                    alt='Изображение'
                    id='productPage_photos_list_main_MainPhoto'
                    src={mainPhoto || picturePlaceholder}
                  />
                )}
              </div>
              <div className={styles.productPage_product_top_info}>
                <Text className={styles.productPage_product_name} variant='headline-L'>
                  {isLoading ? (
                    <Skeleton className={styles.productPage_product_name_skeleton} />
                  ) : (
                    name
                  )}
                </Text>
                <div className={styles.productPage_product_vendorCode}>
                  <Text color='secondary' variant='body'>
                    {isLoading ? (
                      <Skeleton className={styles.productPage_product_vendorCode_skeleton_left} />
                    ) : (
                      VENDOR_CODE_TEXT
                    )}
                  </Text>
                  <Text variant='body'>
                    {isLoading ? (
                      <Skeleton className={styles.productPage_product_vendorCode_skeleton_right} />
                    ) : (
                      item.vendorCode
                    )}
                  </Text>
                </div>
                <div className={styles.productPage_product_brand}>
                  <Text color='secondary' variant='body'>
                    {isLoading ? (
                      <Skeleton className={styles.productPage_product_vendorCode_skeleton_left} />
                    ) : (
                      BRAND_TEXT
                    )}
                  </Text>
                  <Text variant='body'>
                    <Text data-testid={dataTestId.PRODUCT_PAGE_NAME}>
                      {isLoading ? (
                        <Skeleton
                          className={styles.productPage_product_vendorCode_skeleton_right}
                        />
                      ) : (
                        item.brand
                      )}
                    </Text>
                  </Text>
                </div>
              </div>
            </div>
            <div className={styles.productPage_priceBlock} id='productPage_priceBlock'>
              <Text className={styles.productPage_priceBlock_price} variant='headline-H2'>
                {isLoading ? <Skeleton height='32px' width='70px' /> : `${price} ₽`}
              </Text>
              <div className={styles.productPage_priceBlock_rest}>
                <Text color='secondary' variant='body'>
                  {isLoading ? <Skeleton width='115px' /> : 'Осталось, шт.'}
                </Text>
                <Text variant='body'>{isLoading ? <Skeleton width='115px' /> : rest}</Text>
              </div>
              <div className={styles.productPage_priceBlock_days}>
                <Text color='secondary' variant='body'>
                  {isLoading ? <Skeleton width='115px' /> : 'Доставка'}
                </Text>
                <Text variant='body'>{isLoading ? <Skeleton width='115px' /> : days}</Text>
              </div>
              <BasketButton
                btnText='Оформить'
                id={item?.part_hash?.toString()}
                isLoading={isLoading}
                rest={rest}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.productPage_info}>
        <AboutProduct infoList={infoList} isLoading={isLoading} />
        <ApplicabilityText isLoading={isLoading} text={text} />
      </div>
      {/*TODO: Раскомментировать, когда будет необходимо добавить логику*/}
      {/*<Text className={styles.productPage_info_caption} variant='headline'>*/}
      {/*  С этим покупают*/}
      {/*</Text>*/}
      {/*<ProductCardsContainer>*/}
      {/*  {recommendations.map((item, index) => (*/}
      {/*    <ProductCard*/}
      {/*      btnText='В корзину'*/}
      {/*      data-testid={`${dataTestId.PRODUCT_PAGE_RECOMMENDATIONS}-${index}`}*/}
      {/*      isLoading={isLoading}*/}
      {/*      item={item}*/}
      {/*      key={item.id}*/}
      {/*    />*/}
      {/*  ))}*/}
      {/*</ProductCardsContainer>*/}
    </div>
  );
};
