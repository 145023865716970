import { EMPTY_STRING } from 'src/shared/constants/fallbacks';

const getStringLength = (value: string | null | undefined) =>
  String(value || EMPTY_STRING)
    .replace(/_+/g, EMPTY_STRING)
    .replace(/\s+/g, EMPTY_STRING).length;

export const stringLength = (expectedLength: number) => (value: string | null | undefined) => {
  const length = getStringLength(String(value || EMPTY_STRING));

  return length === expectedLength;
};

export const phoneNumber = (value: string | null | undefined) => {
  if (value === null || value === undefined) {
    return true;
  }

  return stringLength(11)(String(value).replace(/\D/giu, EMPTY_STRING));
};
