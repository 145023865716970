import React from 'react';
import { Checkbox, Skeleton } from '@uremont/erp-ui';

import { getCheckboxName } from '@shared/functions/getCheckboxName/getCheckboxName';
import { dataTestId } from '@shared/constants/dataTestId';
import { RenderBrandsProps } from '@shared/components/FilterComponent/lib/Filter/lib/RenderBrands/types';

import styles from './styles.module.scss';

export const RenderBrands = ({ brands, isLoading, handleBrands }: RenderBrandsProps) =>
  brands.map((item, index) =>
    isLoading ? (
      <Skeleton
        className={styles.filter_brands_checkbox_skeleton}
        height='20px'
        key={index}
        width='100px'
      />
    ) : (
      <Checkbox
        boxAlign='start'
        checked={item.status}
        children={getCheckboxName(item.name)}
        className={styles.filter_brands_checkbox}
        crossOrigin='anonymous'
        data-testid={`${dataTestId.FILTER_PAGE_FILTER_BRANDS_CHECKBOX}-${item.value}`}
        key={index}
        name={item.value}
        onChange={() => handleBrands(index)}
      />
    )
  );
