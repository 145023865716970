enum PathEnum {
  EnterPath = '/join/login?redirect=true',
  BasketPath = '/cart',
  MainPath = '/',
  PageNotFound = '*',
  ServicePath = '/register-service',
  AgentsPath = '/agents',
  FondPath = 'https://fond.uremont.com/',
  SearchByGrz = '/bygrz',
  SearchByVin = '/byvin',
  SearchByArticlePath = '/byarticle',
  ArticleWithPricePagePath = '/byarticle/search/:brand/:item_product',
  FilterPagePath = '/filter',
  CatalogItemPriceByArticlePagePath = '/catalog/:category/:product/:brand/:item_product',
  ProductPagePath = '/byarticle/:product_description/:detailed_product',
  CarCatalogProductPagePath = 'catalog/:category/:product/:brand/:item_product/:detailed_product',
  CatalogPath = '/catalog',
  CatalogCategoryPath = '/catalog/:category',
  CatalogProductPath = '/catalog/:category/:product/:brand/:carId/:name_detail/:year_detail/:region_detail/:description_detail',
  HistoryOrdersPath = '/order',
  PlacingAnOrderPath = '/placinganorder',
  PlacingAnOrderSuccessPath = '/placinganorder/success_full_payment',
  PlacingAnOrderRejectPath = '/placinganorder/payment_error',
  DetailedOrderPath = '/order/:id',
  PartsCatalogByCarPath = '/parts-catalog'
}

export const pathConfig: Record<keyof typeof PathEnum, string> = {
  EnterPath: PathEnum.EnterPath,
  BasketPath: PathEnum.BasketPath,
  MainPath: PathEnum.MainPath,
  PageNotFound: PathEnum.PageNotFound,
  ServicePath: PathEnum.ServicePath,
  AgentsPath: PathEnum.AgentsPath,
  FondPath: PathEnum.FondPath,
  SearchByGrz: PathEnum.SearchByGrz,
  SearchByVin: PathEnum.SearchByVin,
  SearchByArticlePath: PathEnum.SearchByArticlePath,
  FilterPagePath: PathEnum.FilterPagePath,
  ProductPagePath: PathEnum.ProductPagePath,
  CatalogPath: PathEnum.CatalogPath,
  CatalogCategoryPath: PathEnum.CatalogCategoryPath,
  CatalogProductPath: PathEnum.CatalogProductPath,
  HistoryOrdersPath: PathEnum.HistoryOrdersPath,
  PlacingAnOrderPath: PathEnum.PlacingAnOrderPath,
  DetailedOrderPath: PathEnum.DetailedOrderPath,
  PartsCatalogByCarPath: PathEnum.PartsCatalogByCarPath,
  CatalogItemPriceByArticlePagePath: PathEnum.CatalogItemPriceByArticlePagePath,
  ArticleWithPricePagePath: PathEnum.ArticleWithPricePagePath,
  CarCatalogProductPagePath: PathEnum.CarCatalogProductPagePath,
  PlacingAnOrderSuccessPath: PathEnum.PlacingAnOrderSuccessPath,
  PlacingAnOrderRejectPath: PathEnum.PlacingAnOrderRejectPath
};
