import React, { useEffect, useState } from 'react';

import {
  SELECT_CAR_STEP,
  SELECT_MODIFICATION_STEP,
  steps
} from 'src/pages/Main/lib/constants/consts';
import { SearchProgressBarView } from 'src/pages/Main/lib/SearchProgressBar/ui/SearchProgressBarView';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { useCarCatalog } from 'src/shared/hooks/use-car-catalog';
import { getCarModelsNames } from 'src/shared/functions/getCarModelsNames/getCarModelsNames';
import { useNavigate } from 'react-router-dom';
import { SelectedRow } from 'src/pages/Main/lib/SearchProgressBar/ui/types';
import { capitalizeCarNameLetter } from 'src/shared/functions/capitalizeCarNameLetter/capitalizeCarNameLetter';
import {
  ParameterValues,
  getModificationParametersById
} from 'src/shared/functions/getModificationParametersById/getModificationParametersById';

export const SearchProgressBar = () => {
  const [items, setItems] = useState<ParameterValues>(EMPTY_ARRAY);
  const [currentItems, setCurrentItems] = useState<ParameterValues>(EMPTY_ARRAY);
  const [step, setStep] = useState(SELECT_CAR_STEP),
    [selectedRow, setSelectedRow] = useState<SelectedRow>(EMPTY_OBJECT),
    [selectedRadio, setSelectedRadio] = useState(EMPTY_STRING),
    navigate = useNavigate(),
    {
      carNames,
      carModels,
      getCarModels,
      getCarGenerations,
      getCurrentCarModelId,
      carGenerations,
      getCarGroupParts,
      setSelectedModel,
      carIds,
      isLoadingCarBrands,
      isLoadingCarModels,
      isLoadingCarGenerations,
      isLoadingCarGroupParts
    } = useCarCatalog(),
    carModelsNames = getCarModelsNames(carModels),
    [selectedBrandValue, setSelectedBrandValue] = useState(carNames[0]),
    [selectedModelValue, setSelectedModelValue] = useState(EMPTY_STRING),
    handleSelectBrandChange = (value: string) => {
      setSelectedBrandValue(value);
      setSelectedModelValue(EMPTY_STRING);
      getCarModels(value.toLowerCase());
    },
    currentModelId = getCurrentCarModelId(
      selectedModelValue ? selectedModelValue : carModelsNames[0]
    ),
    handleRadioChangeForTable = (index: { [p: string]: string }) => {
      setSelectedModel(index);
      setSelectedRow(index);
    },
    handleSelectModelChange = (value: string) => {
      setSelectedModelValue(value);
    },
    handleRadioChange = (value: string) => {
      setSelectedRadio(value);
    },
    handleNext = () => {
      if (step < steps.length) {
        setStep(step + SELECT_CAR_STEP);
        if (step === SELECT_CAR_STEP) {
          getCarGenerations(selectedBrandValue.toLowerCase(), currentModelId);
          setSelectedRadio(EMPTY_STRING);
          setSelectedRow(EMPTY_OBJECT);
        }
      }
      if (step === 3 && selectedRow.brand && selectedRow.id) {
        getCarGroupParts(selectedRow.brand.toLowerCase(), selectedRow.id, navigate);
      }
    },
    handlePrevious = () => {
      if (step > SELECT_CAR_STEP) {
        setStep(step - SELECT_CAR_STEP);
      }
    },
    isDisabled =
      (step === SELECT_MODIFICATION_STEP && !selectedRow.id) ||
      (step !== SELECT_CAR_STEP && !selectedRadio);

  useEffect(() => {
    const newItems = getModificationParametersById(carGenerations, selectedRadio);

    setItems(newItems || EMPTY_ARRAY);
  }, [carGenerations, selectedRadio]);

  return (
    <SearchProgressBarView
      carGenerations={carGenerations}
      carModelsNames={carModelsNames}
      carNames={capitalizeCarNameLetter(carIds)}
      currentItems={currentItems}
      handleNext={handleNext}
      handlePrevious={handlePrevious}
      handleRadioChange={handleRadioChange}
      handleRadioChangeForTable={handleRadioChangeForTable}
      handleSelectBrandChange={handleSelectBrandChange}
      handleSelectModelChange={handleSelectModelChange}
      isDisabled={isDisabled}
      isLoadingCarBrands={isLoadingCarBrands}
      isLoadingCarGenerations={isLoadingCarGenerations}
      isLoadingCarModels={isLoadingCarModels || isLoadingCarGroupParts}
      items={items}
      selectedBrandValue={selectedBrandValue}
      selectedModelValue={selectedModelValue}
      selectedRadio={selectedRadio}
      selectedRow={selectedRow}
      setCurrentItems={setCurrentItems}
      step={step}
    />
  );
};
