export const insuranceCompaniesLinks = [
  {
    link: 'https://www.alfastrah.ru/',
    title: 'AO «АльфаСтрахование»',
    contract: 'АД № 17510/ТАЮЛ/2021'
  },
  {
    link: 'https://www.renins.ru/',
    title: 'АО «Группа Ренессанс Страхование»',
    contract: 'АД № 24/07/2019 от 24 июля 2017 г.'
  },
  {
    link: 'https://www.rgs.ru/',
    title: 'ПАО СК «Росгосстрах»',
    contract: 'АГ №1504-21 от 01.06.2021'
  },
  {
    link: 'https://www.vsk.ru/',
    title: 'САО «ВСК»',
    contract: 'АД 16598850001 от 28.01.21'
  },
  {
    link: 'https://zettains.ru/',
    title: 'ООО «Зетта Страхование»',
    contract: 'АГД-991-445486/21 от 01.07.2021'
  },
  {
    link: 'https://energogarant.ru/',
    title: 'ПАО «САК ЭНЕРГОГАРАНТ»',
    contract: 'АД № 0000/1488/21/01 от 23.08.2021'
  },
  {
    link: 'https://makc.ru/',
    title: 'АО «МАКС»',
    contract: 'АД №12170/21-214531 от.23.08.2021'
  },
  {
    link: 'https://sberbankins.ru/bazovyi-standart',
    title: 'ООО СК «Сбербанк страхование»',
    contract: 'АД-171/21 от 21.04.2021'
  }
];
