import React from 'react';
import { Cluster, IconChevronRight, Text } from '@uremont/erp-ui';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { dataTestId } from '@shared/constants/dataTestId';

import { BreadcrumbsViewProps } from './types';
import styles from './styles.module.scss';

export const BreadcrumbsView = ({ links, className, ...props }: BreadcrumbsViewProps) => (
  <Cluster
    data-testid={dataTestId.BREADCRUMBS_VIEW}
    {...props}
    className={cx(styles.wrapper, className)}
    gap='sm'
  >
    {links.map(({ label, href, active }) => (
      <React.Fragment key={label}>
        {href ? (
          <>
            <Link
              to={href}
              className={cx(styles.link, {
                [styles.link_active]: active
              })}
            >
              <Text variant='body-tight'> {label}</Text>
            </Link>
            <IconChevronRight className={styles.icon} />
          </>
        ) : (
          <Text
            variant='body-tight'
            className={cx(styles.link, styles.link_static, {
              [styles.link_active]: active
            })}
          >
            {label}
          </Text>
        )}
      </React.Fragment>
    ))}
  </Cluster>
);
