import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholderForCard.png';
import { BRAND_TEXT, VENDOR_CODE_TEXT } from '@shared/constants/ texts';
import { dataTestId } from '@shared/constants/dataTestId';
import { capitalizeFirstLetter } from '@shared/functions/capitalizeFirstLetter/capitalizeFirstLetter';

import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { ProductCardInfoProductProps } from './types';
import styles from './styles.module.scss';

export const ProductCardInfoProduct = ({
  item,
  horizontal,
  isLoading,
  isBasket,
  isHistoryCard = false,
  productUrl,
  price,
  handleSelectedArticleClicked,
  isOutOfStockProducts = false
}: ProductCardInfoProductProps) => {
  return (
    <>
      <div
        className={cx(styles.productCardInfoProduct_img, {
          [styles.productCardInfoProduct_imgHorizontal]: horizontal,
          [styles.productCardInfoProduct_img_noImgBackground]: !item.volume
        })}
      >
        {isLoading ? (
          <Skeleton height={horizontal ? '89px' : '140px'} width='100%' />
        ) : (
          <img alt={item.brand} src={item.volume || picturePlaceholder} />
        )}
      </div>
      <div
        className={cx(
          styles.productCardInfoProduct_middle,
          horizontal && styles.productCardInfoProduct_middleHorizontal,
          isBasket && styles.productCardInfoProduct_middleBasket,
          isHistoryCard && styles.productCardInfoProduct_isHistoryCard
        )}
      >
        {isLoading ? (
          <Skeleton className={styles.productCardInfoProduct_name_skeleton} />
        ) : horizontal ? (
          <div onClick={() => handleSelectedArticleClicked && handleSelectedArticleClicked(item)}>
            <Link
              className={styles.productCardInfoProduct_name}
              data-testid={`${dataTestId.PRODUCT_CARD_VIEW_LINK}-${item.distributorId}`}
              to={productUrl}
            >
              <Text color={isOutOfStockProducts ? 'secondary' : 'default'} variant='headline-H3'>
                {item.description && capitalizeFirstLetter(item.description)}
              </Text>
              {isBasket && (
                <Text
                  className={styles.productCardInfoProduct_price}
                  color={isOutOfStockProducts ? 'danger' : 'secondary'}
                  variant={isOutOfStockProducts ? 'title' : 'body-tight'}
                >
                  {isOutOfStockProducts
                    ? 'Нет в наличии'
                    : `${price} ${isOutOfStockProducts ? EMPTY_STRING : '₽ за 1 шт'}`}
                </Text>
              )}
            </Link>
          </div>
        ) : (
          <Text className={styles.productCardInfoProduct_nameDefault} variant='headline-H3'>
            {item.description && capitalizeFirstLetter(item.description)}
          </Text>
        )}
        <div
          className={
            isOutOfStockProducts
              ? styles.productCardInfoProduct_vendorCode_isOutProducts
              : styles.productCardInfoProduct_vendorCode
          }
        >
          <Text color='secondary' variant='body-tight'>
            {isLoading ? (
              <Skeleton className={styles.productCardInfoProduct_vendorCode_skeleton_left} />
            ) : (
              VENDOR_CODE_TEXT
            )}
          </Text>
          <Text color={isOutOfStockProducts ? 'secondary' : 'default'} variant='body-tight'>
            {isLoading ? (
              <Skeleton className={styles.productCardInfoProduct_vendorCode_skeleton_right} />
            ) : (
              item.number || item.vendorCode
            )}
          </Text>
        </div>
        <div
          className={
            isOutOfStockProducts
              ? styles.productCardInfoProduct_brand_isOutProducts
              : styles.productCardInfoProduct_brand
          }
        >
          <Text color='secondary' variant='body-tight'>
            {isLoading ? (
              <Skeleton className={styles.productCardInfoProduct_vendorCode_skeleton_left} />
            ) : (
              BRAND_TEXT
            )}
          </Text>
          <Text color={isOutOfStockProducts ? 'secondary' : 'default'} variant='body-tight'>
            {isLoading ? (
              <Skeleton className={styles.productCardInfoProduct_vendorCode_skeleton_right} />
            ) : (
              item.brand
            )}
          </Text>
        </div>
      </div>
    </>
  );
};
