import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, IconMagnifyingGlass } from '@uremont/erp-ui';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { ReactComponent as CarIcon } from '@assets/CarIcon.svg';
import { ReactComponent as PartCarIcon } from '@assets/PartCarIcon.svg';
import {
  AUTO_NUMBER_MASK,
  enterNumberText,
  SEARCH_MENU_ITEMS_FOR_MOBILE_SCREEN,
  SEARCH_MENU_ITEMS_FOR_SCREEN,
  searchAutoNumber,
  searchModelAuto,
  searchVINNumber
} from '@pages/Main/lib/constants/consts';
import { SearchView } from '@pages/Main/lib/Search/ui/SearchView';
import { SearchMenuItem } from '@pages/Main/lib/SearchMenuItem';
import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { useScreenSizeBreakpoints } from '@shared/hooks/use-screen-size-breakpoints';
import { InputsFormValues, useInputsForm } from '@shared/hooks/use-inputs-form';
import { defaultValuesSearchForm } from '@pages/Main/lib/Search/lib/constants/constants';
import { searchFormSchema } from '@pages/Main/lib/Search/lib/constants/schema';
import { VIN_VALUE_LENGTH, GRZ_VALUE_LENGTH } from '@shared/constants/consts';
import { ArticleTipsResponse } from '@shared/model/Store/SearchDetails/types';
import { useFocused } from '@shared/hooks/use-focused';
import { useSearch } from '@shared/hooks/use-search';
import { pathConfig } from '@shared/constants/pathConfig';
import { useSearchRequestStore } from '@shared/model/Store/SearchRequest';
import { useBasketStore } from '@shared/model/Store';

import styles from './styles.module.scss';

export const Search = () => {
  const [searchVINValue, setSearchVINValue] = useState(EMPTY_STRING);
  const [searchGRZValue, setSearchGRZValue] = useState(EMPTY_STRING);
  const [selectedOption, setSelectedOption] =
    useState<keyof typeof SEARCH_MENU_ITEMS>(searchAutoNumber);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [initialValues, setInitialValues] = useState<InputsFormValues>(defaultValuesSearchForm);
  const dropdownRef = useRef<HTMLDivElement>(null);
  useFocused(isFocused);

  const [searchRequest] = useSearchRequestStore(state => [state.searchRequest]);

  const [checkCardCount, basket] = useBasketStore(state => [state.checkCardCount, state.basket]);

  const navigate = useNavigate();

  const goBack = () => navigate(pathConfig.MainPath);

  const formik = useFormik<InputsFormValues>({
    initialValues,
    validationSchema: searchFormSchema,
    onSubmit: (values: InputsFormValues) => {
      setInitialValues(values);
    }
  });
  const { errors, values, handleChange, resetForm, handleBlur, setFieldValue, touched } = formik;

  const searchRequestValue = {
    phone: values.phone?.replace(/\D/g, EMPTY_STRING) ?? EMPTY_STRING,
    query: values.comment ?? EMPTY_STRING,
    file: values.file ? values.file[0] : null
  };

  const toggleModal = () => {
    setIsOpenModal(prevState => !prevState);
    resetForm();
  };

  const handleSubmit = async () => {
    await searchRequest(searchRequestValue);
    toggleModal();
  };

  const { renderChildren } = useInputsForm({
    values,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched
  });
  const { isMobile, isTablet } = useScreenSizeBreakpoints();

  const searchValue = searchVINValue === EMPTY_STRING ? searchGRZValue : searchVINValue;

  const {
    articleTips,
    isLoadingArticle,
    isEmptyDataShow,
    setIsEmptyDataShow,
    getSearchDetailsByGRZData,
    getSearchDetailsByVINData,
    setArticleTip,
    getArticleTips,
    setIsGRZError,
    isGRZError,
    isLoading
  } = useSearch(searchValue);
  const Icon = isTablet ? <PartCarIcon /> : <CarIcon />;
  const SEARCH_MENU_ITEMS = isMobile
    ? SEARCH_MENU_ITEMS_FOR_MOBILE_SCREEN
    : SEARCH_MENU_ITEMS_FOR_SCREEN;
  const showAutoVinNumberInput =
    selectedOption === searchAutoNumber || selectedOption === searchVINNumber;
  const showModelAutoSearch = selectedOption === searchModelAuto;
  const placeholderText = selectedOption === searchAutoNumber ? AUTO_NUMBER_MASK : enterNumberText;
  const isSearchByGRZ = AUTO_NUMBER_MASK === placeholderText;
  const isOpenDropdown = isFocused && articleTips.length > 0;
  const handleInputFocus = () => {
    setIsGRZError(false);
    setIsFocused(true);
  };
  const handleInputBlur = () => {
    setTimeout(() => {
      setIsFocused(false);
    }, 200);
  };
  const handleSearchGRZChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value.length === 10 ? e.target.value.slice(0, -1) : e.target.value;
    setSearchGRZValue(newValue);
  };
  const handleSearchVINChange = (value: string) => {
    value.length > 0 && getArticleTips(value);

    setSearchVINValue(value.toUpperCase());
  };
  const handleOptionChange = (option: keyof typeof SEARCH_MENU_ITEMS) => {
    setIsGRZError(false);
    setSelectedOption(option);
    setSearchGRZValue(EMPTY_STRING);
    setSearchVINValue(EMPTY_STRING);

    basket && checkCardCount(basket);
  };

  const isVINValid = searchVINValue.length === VIN_VALUE_LENGTH;
  const isGRZValid = GRZ_VALUE_LENGTH.includes(searchGRZValue.length);

  const handleButtonClick = () => {
    if (!isSearchByGRZ) {
      if (isVINValid) {
        getSearchDetailsByVINData(searchVINValue, navigate);
      } else {
        if (!articleTips.length) {
          return;
        }

        navigate(`/byarticle/search/${articleTips[0].brand}/${articleTips[0].number}`);
        setSearchVINValue(articleTips[0].number);
        setArticleTip(articleTips[0]);
      }
    }
    if (isSearchByGRZ) {
      if (isGRZValid) {
        setIsGRZError(false);
        getSearchDetailsByGRZData(searchGRZValue, navigate);
      }
    }
  };
  const noInfoCloseHandle = () => {
    setSearchGRZValue(EMPTY_STRING);
    setSearchVINValue(EMPTY_STRING);
    setIsEmptyDataShow(false);
  };
  const searchInputButton = () => (
    <Button
      className={styles.searchInputButton}
      icon={<IconMagnifyingGlass />}
      iconAlign='end'
      loading={isLoading || isLoadingArticle}
      onClick={handleButtonClick}
      variant='solid'
    />
  );

  const handleDropdownItemClick = (tip: ArticleTipsResponse) => {
    setArticleTip(tip);
    setSearchVINValue(tip.number);
    navigate(`/byarticle/search/${tip.brand}/${tip.number}`);
  };

  const renderSearchLinks = () => {
    return (
      <>
        {Object.keys(SEARCH_MENU_ITEMS).map((optionKey, index) => (
          <SearchMenuItem
            data-testid={`${dataTestId.SEARCH_SEARCH_MENU_ITEM}-${index}`}
            isClickedSearchItem={optionKey === selectedOption}
            isLoading={isLoading}
            key={optionKey}
            onClick={() => handleOptionChange(optionKey as keyof typeof SEARCH_MENU_ITEMS)}
            text={SEARCH_MENU_ITEMS[optionKey as keyof typeof SEARCH_MENU_ITEMS]}
          />
        ))}
      </>
    );
  };

  useEffect(() => {
    setIsEmptyDataShow(
      articleTips.length === 0 &&
        Boolean(searchVINValue) &&
        searchVINValue.length !== VIN_VALUE_LENGTH
    );
  }, [articleTips, searchVINValue]);

  return (
    <SearchView
      Icon={Icon}
      articleTips={articleTips}
      children={renderChildren()}
      dropdownRef={dropdownRef}
      goBack={goBack}
      handleButtonClick={handleButtonClick}
      handleDropdownItemClick={handleDropdownItemClick}
      handleInputBlur={handleInputBlur}
      handleInputFocus={handleInputFocus}
      handleSearchGRZChange={handleSearchGRZChange}
      handleSearchVINChange={handleSearchVINChange}
      isEmptyDataShow={isEmptyDataShow}
      isFocused={isFocused}
      isGRZError={isGRZError}
      isLoadingArticle={isLoadingArticle}
      isMobile={isMobile}
      isOpenDropdown={isOpenDropdown}
      isOpenModal={isOpenModal}
      isSearchByGRZ={isSearchByGRZ}
      noInfoCloseHandle={noInfoCloseHandle}
      placeholderText={placeholderText}
      renderSearchLinks={renderSearchLinks}
      searchGRZValue={searchGRZValue}
      searchInputButton={searchInputButton()}
      searchVINValue={searchVINValue}
      showAutoVinNumberInput={showAutoVinNumberInput}
      showModelAutoSearch={showModelAutoSearch}
      toggleModal={toggleModal}
    />
  );
};
