import { BasketParts, OldBasketParts } from '@shared/model/Store/Basket/types';
import { ProductCard } from '@shared/components/ProductCard/ui/types';
import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholderForCard.png';

export const convertToProductCard = (rawProduct: BasketParts | OldBasketParts): ProductCard => {
  const product = rawProduct as BasketParts;

  return {
    id: product?.id,
    img: picturePlaceholder,
    description: product?.description,
    vendorCode: product?.article_name,
    brand: product?.brand_name,
    price: product?.price || product.distributor_price,
    url: `${rawProduct.brand_name}`,
    rest: rawProduct?.quantity,
    deliveryPeriod: product?.deliveryPeriod,
    availability: product?.availability,
    quantity: rawProduct?.quantity,
    part_hash: product?.part_hash,
    selected: false
  };
};
