import React from 'react';

import { RequestBannerProps } from 'src/shared/components/RequestBanner/types';
import { useScreenSizeBreakpoints } from 'src/shared/hooks/use-screen-size-breakpoints';

import { RequestBannerView } from './RequestBannerView';

export const RequestBanner = ({
  Icon,
  title,
  subTitle,
  buttonText,
  onPress
}: RequestBannerProps) => {
  const { isMobile } = useScreenSizeBreakpoints();

  return (
    <RequestBannerView
      Icon={Icon}
      buttonText={buttonText}
      isMobile={isMobile}
      onPress={onPress}
      subTitle={subTitle}
      title={title}
    />
  );
};
