import React, { useEffect } from 'react';
import { Routes } from 'react-router-dom';
import { useAuthStore } from '@shared/model/Store/Auth';
import { useBasketStore } from '@shared/model/Store';
import { dataTestId } from '@shared/constants/dataTestId';
import { renderPages } from '@pages/lib/utils/renderPages';
import { privatePages } from '@pages/lib/constants/privatePages';
import './index.scss';

export const App = () => {
  const [auth] = useAuthStore(state => [state.auth]);
  const [getBasketId, basketId, checkBasketId, basket] = useBasketStore(state => [
    state.getBasketId,
    state.basketId,
    state.checkBasketId,
    state.basket
  ]);

  useEffect(() => {
    (!basket || !basketId) && getBasketId();
  }, [basket]);

  useEffect(() => {
    auth();
  }, []);

  useEffect(() => {
    basketId && checkBasketId(basketId);
  }, [basketId]);

  return (
    <div data-testid={dataTestId.APP}>
      <Routes>{renderPages(privatePages)}</Routes>
    </div>
  );
};
