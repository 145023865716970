import { pathConfig } from '@shared/constants/pathConfig';

export const uremontLinks = {
  mainUremont: 'https://uremont.com',
  newBid: 'https://uremont.com/newbid',
  map: 'https://uremont.com/map',
  autoparts: pathConfig.MainPath,
  autopartsOrders: pathConfig.HistoryOrdersPath,
  autopartsCart: pathConfig.BasketPath,
  eosago: 'https://uremont.com/eosago',
  insurance: 'https://insurance.uremont.com',
  insuranceKasko: 'https://insurance.uremont.com/kasko',
  insuranceEipoteka: 'https://insurance.uremont.com/eipoteka',
  insuranceAgents: 'https://insurance.uremont.com/agents',
  faq: 'https://uremont.com/faq',
  autoparks: 'https://corpark.uremont.com',
  registerService: 'https://uremont.com/register-service',
  agreement: 'https://uremont.com/agreement',
  agreementBel: 'https://uremont.com/agreement-bel',
  offer: 'https://uremont.com/offer',
  offerBel: 'https://uremont.com/offer-belarus',
  offerSTO: 'https://uremont.com/static/Offer_march_2020.pdf',
  offerSTOBel: 'https://uremont.com/offer-sto-belarus',
  privacy: 'https://uremont.com/privacy',
  privacyBel: 'https://uremont.com/privacy-bel',
  partsOffer: 'https://uremont.com/static/Offer_january_2021.pdf',
  howItWorks: 'https://uremont.com/how-it-works',
  contacts: 'https://uremont.com/contacts',
  charity: 'https://fond.uremont.com',
  documentation: 'https://uremont.com/documents',
  vk: 'https://vk.com/uremont_com',
  youtube: 'https://www.youtube.com/channel/UCwxYP-9mGpnPbNyOr5HoS7Q',
  appStore: 'https://apps.apple.com/ru/app/uremont-com/id1262343094#?platform=iphone',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.uremont&hl=ru&gl=US',
  // TODO - раскомметировать изменения, при возвращении пункта "Автомасла" в меню хедера и в слайдер баннера
  // silkOil: 'https://silkoil.pro/',
  loyalty: 'https://loyalty.uremont.com',
  raffle: 'https://raffle.uremont.com',
  franchising: 'https://uremont.com/franchising',
  serviceBids: 'https://uremont.com/cabinet/service/bids',
  serviceProfile: 'https://uremont.com/cabinet/service/profile',
  serviceMyService: 'https://uremont.com/cabinet/service/my-service',
  serviceReviews: 'https://uremont.com/cabinet/service/reviews',
  serviceTariffs: 'https://uremont.com/cabinet/service/tariffs',
  serviceStat: 'https://uremont.com/cabinet/service/stat',
  servicePromo: 'https://uremont.com/cabinet/service/promo',
  serviceFinanses: 'https://uremont.com/cabinet/service/finanses',
  serviceChat: 'https://uremont.com/cabinet/service/chat',
  customerDashboard: 'https://uremont.com/cabinet/customer/dashboard',
  customerProfile: 'https://uremont.com/cabinet/customer/profile',
  customerPromo: 'https://uremont.com/cabinet/customer/promo',
  customerChosen: 'https://uremont.com/cabinet/customer/chosen',
  customerBids: 'https://uremont.com/cabinet/customer/bids',
  customerFinanses: 'https://uremont.com/cabinet/customer/finanses',
  customerCars: 'https://uremont.com/cabinet/customer/cars',
  customerReports: 'https://uremont.com/cabinet/customer/reports',
  customerChat: 'https://uremont.com/cabinet/customer/chat'
};
