import { useEffect, useState } from 'react';

import { SCREEN_SIZES } from 'src/shared/constants/screenSizes';

export const useScreenSizeBreakpoints = () => {
  const [screenSizeType, setScreenSizeType] = useState('desktop');
  const [windowWidthSize, setWindowWidthSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;

      setWindowWidthSize(innerWidth);

      let screenSizeType = 'desktop';

      if (innerWidth < 1440 && innerWidth > 768) {
        screenSizeType = 'laptop';
      } else {
        const screenSize = SCREEN_SIZES.find(itemSize => innerWidth <= itemSize.max);

        if (screenSize) {
          screenSizeType = screenSize.type;
        }
      }

      setScreenSizeType(screenSizeType);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return {
    isDesktop: screenSizeType === 'desktop',
    isTablet: screenSizeType === 'tablet',
    isMobile: screenSizeType === 'mobile',
    windowWidthSize
  };
};
