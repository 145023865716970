import React from 'react';

import { pathConfig } from '@shared/constants/pathConfig';
import { EMPTY_OBJECT, EMPTY_STRING } from '@shared/constants/fallbacks';
import { roundedNumber } from '@shared/functions/formattedNumber/roundedNumber';
import { useBasket } from '@shared/hooks/use-basket';
import { useBasketStore } from '@shared/model/Store';
import { ProductCardView } from './ProductCardView';

import { ProductCardProps } from './types';

//Добавлено игнорирование, так как из за React.memo получаем ошибку eslint
// eslint-disable-next-line react/display-name
export const ProductCard = React.memo(
  ({
    item,
    btnText,
    horizontal = false,
    isBasket = false,
    isLoading,
    handleSelect,
    isLastItem,
    isArticleScreen = false,
    isHistoryCard = false,
    handleSelectedArticleClicked,
    isOutOfStockProducts = false,
    isShowCheckbox = true
  }: ProductCardProps) => {
    const showDivider = !isLastItem && horizontal;

    const brandAndArticle = encodeURIComponent(`${item.brand}_${item.number || item.vendorCode}`);
    const partHash = encodeURIComponent(item.partHash || item.part_hash || EMPTY_STRING); //TODO из-за того, что в разных запросах это поле приходит с разным ключем, пока сделано такое временное решение

    const productUrl =
      `${pathConfig.SearchByArticlePath}/${brandAndArticle}/${brandAndArticle}_${partHash}` ??
      EMPTY_STRING;

    const description = encodeURIComponent(item.description || EMPTY_STRING);
    const articleProductUrl = `${
      pathConfig.SearchByArticlePath
    }/${description}/${encodeURIComponent(item.brand || EMPTY_STRING)}/${encodeURIComponent(
      (item.number || item.vendorCode) ?? EMPTY_STRING
    )}`;

    const roundedPrice = item.price ? roundedNumber(item.price) : EMPTY_STRING;

    const [basket] = useBasketStore(state => [state.basket]);

    const clickedItem = basket
      ? basket.noChanges.find(basketItem => basketItem?.part_hash === item.part_hash)
      : EMPTY_OBJECT;

    const { cardCount } = useBasket(partHash, clickedItem);

    return (
      <ProductCardView
        articleProductUrl={articleProductUrl}
        btnText={btnText}
        cardCount={cardCount}
        handleSelect={handleSelect}
        handleSelectedArticleClicked={handleSelectedArticleClicked}
        horizontal={horizontal}
        isArticleScreen={isArticleScreen}
        isBasket={isBasket}
        isHistoryCard={isHistoryCard}
        isLoading={isLoading}
        isOutOfStockProducts={isOutOfStockProducts}
        isShowCheckbox={isShowCheckbox}
        item={item}
        price={roundedPrice}
        productUrl={productUrl}
        showDivider={showDivider}
      />
    );
  }
);
