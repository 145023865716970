import React from 'react';
import { Route } from 'react-router-dom';

import { PageLayout } from 'src/features/AppLayout/components/PageLayout';
import { Page } from 'src/pages/lib/types';

export const renderPages = (pages: Page[]) => {
  return pages.map(({ Component, path }) => (
    <Route element={<PageLayout children={<Component />} />} key={path} path={path} />
  ));
};
