import React from 'react';

import { BreadcrumbsView } from './BreadcrumbsView';
import { BreadcrumbsProps } from './types';

export const Breadcrumbs = ({ links, className, ...props }: BreadcrumbsProps) => {
  if (!Array.isArray(links) || !links.length) {
    return null;
  }

  return <BreadcrumbsView className={className} links={links} {...props} />;
};
