import { useEffect } from 'react';

export const useFocused = (isFocused: boolean) => {
  useEffect(() => {
    const originalOverflowStyle = document.body.style.overflow;

    if (isFocused) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = originalOverflowStyle;
    }

    return () => {
      document.body.style.overflow = originalOverflowStyle;
    };
  }, [isFocused]);
};
