import { Text, TextVariant } from '@uremont/erp-ui';
import { dataTestId } from '@shared/constants/dataTestId';
import { CountryCitiesLinks } from '@features/AppLayout/components/Footer/ui/types';

export const getFooterInfoLinks = (
  links: CountryCitiesLinks[],
  linkTextStyle: string,
  textVariant?: TextVariant
) => {
  return links.map((link, index) => (
    <Text
      as='a'
      className={linkTextStyle}
      data-testid={dataTestId.GET_FOOTER_INFO_LINKS}
      href={link.path}
      key={index}
      variant={textVariant}
      {...(link.target && {
        target: link.target
      })}
    >
      {link.name}
    </Text>
  ));
};
