import { HOME_BREADCRUMBS } from '@shared/constants/breadcrumbsText';
import { pathConfig } from '@shared/constants/pathConfig';

export const breadcrumbsLinks = (
  currentProduct: string,
  currentDetailedItem: string,
  selectedIemArticle: string
) => [
  {
    active: false,
    href: pathConfig.MainPath,
    label: HOME_BREADCRUMBS
  },
  {
    active: false,
    href: `${pathConfig.SearchByArticlePath}/search/${encodeURIComponent(
      currentDetailedItem
    )}/${encodeURIComponent(selectedIemArticle)}`,
    label: currentProduct
  },
  {
    active: true,
    label: currentDetailedItem
  }
];
