import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware';

import { EMPTY_ARRAY, EMPTY_OBJECT } from 'src/shared/constants/fallbacks';
import { getPopularCities, getSearchCities } from 'src/shared/model/Store/City/service';
import { CollectionDefaultCityData } from 'src/shared/model/Store/Country/types';

import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { CitiesStore } from './types';

export const useCitiesStore = create(
  devtools(
    persist<CitiesStore>(
      set => ({
        cities: EMPTY_ARRAY,
        selectedCity: EMPTY_OBJECT as CollectionDefaultCityData,
        countryId: null,
        searchCities: EMPTY_ARRAY,
        selectedCountryCityId: 1,
        isLoading: false,
        isLoadingEnteringCity: false,

        getPopularCitiesData: async countryId => {
          await executeRequest(
            () => getPopularCities(countryId),
            isLoading => set({ isLoading }),
            response => set({ cities: response ?? EMPTY_ARRAY, countryId })
          );
        },

        getSearchCitiesData: async (countryId, query) => {
          await executeRequest(
            () => getSearchCities(countryId, query),
            isLoadingEnteringCity => set({ isLoadingEnteringCity }),
            response => set({ searchCities: response ?? EMPTY_ARRAY, countryId })
          );
        },

        setSearchCitiesEmptyData: () => {
          set({
            searchCities: EMPTY_ARRAY
          });
        },

        setSelectedCity: city => {
          set({
            selectedCity: city,
            countryId: city?.country_id || null
          });
        },

        setSelectedCountryCityId: id => {
          set({
            selectedCountryCityId: id
          });
        },

        resetCityState: () => {
          set({
            cities: EMPTY_ARRAY,
            countryId: null,
            selectedCity: EMPTY_OBJECT as CollectionDefaultCityData,
            searchCities: EMPTY_ARRAY
          });
        }
      }),
      {
        name: 'cities-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
