import React from 'react';

import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { skeletonData } from '@pages/SearchByCar/lib/constants/skeletonData';
import { SearchByCarView } from './SearchByCarView';

export const SearchByCar = () => {
  const { carGroupParts, transformedSelectedModelData, isLoadingCarGroupParts } = useCarCatalog();

  const carGroupPartsData = isLoadingCarGroupParts ? skeletonData : carGroupParts;

  return (
    <SearchByCarView
      carGroupParts={carGroupPartsData}
      isLoading={isLoadingCarGroupParts}
      transformedSelectedModelData={transformedSelectedModelData}
    />
  );
};
