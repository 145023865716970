import React from 'react';
import { Button, Skeleton } from '@uremont/erp-ui';

import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholderForCard.png';
import { dataTestId } from '@shared/constants/dataTestId';

import { PhotosBtnProps } from './types';
import styles from './styles.module.scss';

export const PhotosBtnView = ({ handleChangePhoto, isLoading, photos }: PhotosBtnProps) => (
  <>
    {photos.map((item, index) => {
      if (isLoading) {
        return <Skeleton className={styles.photosBtn_list_photo_skeleton} key={index} />;
      }

      return (
        <Button
          className={styles.photosBtn_list_photo}
          data-testid={dataTestId.PRODUCT_PAGE_PHOTOS}
          key={index}
          onClick={() => handleChangePhoto(index)}
          variant='control'
        >
          <img alt='Изображение' src={item.url || picturePlaceholder} />
        </Button>
      );
    })}
  </>
);
