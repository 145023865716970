import React from 'react';
import { IconPaperclip, Text } from '@uremont/erp-ui';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { FilePreviewItem } from '../lib/FilePreviewItem';
import { FilePreviewViewProps } from './types';
import styles from './styles.module.scss';

export const FilePreviewView = ({
  id,
  showInput,
  dragOver,
  fileDrop,
  onChangeFileInput,
  file,
  deleteFile
}: FilePreviewViewProps) => (
  <div className={styles.filePreview} data-testid={dataTestId.FILE_PREVIEW_VIEW}>
    {showInput && (
      <label
        className={styles.filePreview_upload}
        htmlFor={id}
        onDragOver={dragOver}
        onDrop={fileDrop}
      >
        <IconPaperclip />
        <Text className={styles.filePreview_upload_text} variant='body-tight'>
          Прикрепить файл
        </Text>
        <input
          accept='.jpg, .jpeg, .png, .pdf, .doc, .docx'
          className={styles.filePreview_upload_input}
          data-testid={dataTestId.FILE_PREVIEW_VIEW_INPUT_FILE}
          id={id}
          multiple
          name='file'
          onChange={onChangeFileInput}
          type='file'
        />
      </label>
    )}
    {file?.length !== 0 && (
      <div className={styles.filePreview_previewsWrap}>
        <div className={styles.filePreview_previews}>
          {file?.map((item, index) => (
            <FilePreviewItem deleteFile={deleteFile} id={index} item={item} key={index} />
          ))}
        </div>
      </div>
    )}
  </div>
);
