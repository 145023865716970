import React from 'react';

import { applicabilityKey } from '../../constants/constants';
import { AboutProductView } from './AboutProductView';

import { AboutProductProps } from './types';

export const AboutProduct = ({ infoList, isLoading }: AboutProductProps) => {
  if (!infoList) {
    return null;
  }

  const list = Object.keys(infoList).filter(item => item !== applicabilityKey);

  return <AboutProductView infoList={infoList} isLoading={isLoading} list={list} />;
};
