import React, { useState } from 'react';

import { dataTestId } from '@shared/constants/dataTestId';
import { AccordionItem } from './lib/AccordionItem';

import { CustomAccordionProps } from './types';

export const CustomAccordion = ({
  classNameContainer,
  textVariant,
  isLoading,
  items
}: CustomAccordionProps) => {
  const [activeItem, setActiveItem] = useState<number | null>(null);

  const handleActiveItem = (itemNum: number) => {
    if (activeItem === itemNum) {
      setActiveItem(null);
    } else {
      setActiveItem(itemNum);
    }
  };

  return (
    <>
      {items.map((item, index) => (
        <AccordionItem
          classNameContainer={classNameContainer}
          data-testid={`${dataTestId.CUSTOM_ACCORDION_ITEM}-${index}`}
          isActive={activeItem === index}
          isLoading={isLoading}
          key={index}
          links={item.links}
          setIsActive={() => handleActiveItem(index)}
          textVariant={textVariant}
          title={item.name}
        />
      ))}
    </>
  );
};
