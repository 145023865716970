import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware';

import { executeRequest } from '@shared/functions/executeRequest/executeRequest';
import {
  addToBasket,
  checkBasketId,
  clearBasket,
  getBasket,
  getBasketId,
  recreateBasketByOrder,
  removeFromBasket
} from '@shared/model/Store/Basket/service';
import { EMPTY_OBJECT, EMPTY_STRING } from '@shared/constants/fallbacks';
import { pathConfig } from '@shared/constants/pathConfig';
import { getCountsProducts } from '@shared/functions/getCountsProducts/getCountsProducts';
import { transformBasket } from '@pages/Basket/lib/functions/transformBasket/transformBasket';
import { compareAndTransform } from '@pages/Basket/lib/functions/compareAndTransform/compareAndTransform';
import { BasketItems, BasketStore } from './types';

export const useBasketStore = create(
  devtools(
    persist<BasketStore>(
      set => ({
        basketId: EMPTY_STRING,
        basket: null,
        recreateBasket: null,
        basketParts: null,
        isLoading: false,
        isLoadingCheck: false,
        isLoadingItems: false,
        checkBasketResponse: null,
        orderPriceBasketData: null,
        cardCounts: EMPTY_OBJECT as BasketStore['cardCounts'],

        getBasketId: async () => {
          await executeRequest(
            () => getBasketId(),
            isLoading => set({ isLoading }),
            response => set({ basketId: response || EMPTY_STRING })
          );
        },

        getBasket: async (id, navigateToPage) => {
          await executeRequest(
            () => getBasket(id),
            isLoading => set({ isLoading }),
            response => {
              set({ basket: response ? transformBasket(response) : null });

              if (response && navigateToPage) {
                navigateToPage(pathConfig.BasketPath);
              }
            }
          );
        },

        recreateBasketByOrder: async (orderId, navigateToPage) => {
          await executeRequest(
            () => recreateBasketByOrder(orderId.toString() ?? EMPTY_STRING),
            isLoading => set({ isLoadingCheck: isLoading }),
            response => {
              set({ recreateBasket: response ? response : null });

              if (response && navigateToPage) {
                navigateToPage(pathConfig.BasketPath);
              }
            }
          );
        },

        resetRecreateBasket: () => {
          set({
            recreateBasket: null
          });
        },

        setBasket: value => {
          set({
            orderPriceBasketData: transformBasket(value),
            checkBasketResponse: value ? value : null
          });
        },

        setLoadItems: loading => {
          set({
            isLoadingItems: loading
          });
        },

        addToBasket: async (id, data) => {
          await executeRequest(
            () => addToBasket(id, data),
            isLoading => set({ isLoading }),
            response => set({ basketParts: response ? response : null })
          );
        },

        setCardCount: newCounts => set({ cardCounts: newCounts }),

        removeFromBasket: async (id, data) => {
          await executeRequest(
            () => removeFromBasket(id, data),
            isLoading => set({ isLoading }),
            response => set({ basketParts: response ? response : null })
          );
        },

        clearBasket: async id => {
          await executeRequest(
            () => clearBasket(id),
            isLoading => set({ isLoading }),
            response =>
              set({
                basketParts: response ? response : null,
                recreateBasket: null
              })
          );
        },

        checkBasketId: async id => {
          await executeRequest(
            () => checkBasketId(id),
            isLoading => set({ isLoading, isLoadingCheck: isLoading }),
            response => {
              if (!response) {
                set({ basket: null, cardCounts: EMPTY_OBJECT });

                return;
              }

              const newCounts = getCountsProducts(response.basket_parts);
              const recreateBasketItems =
                useBasketStore.getState().recreateBasket?.basketItems ??
                (EMPTY_OBJECT as BasketItems);
              set({
                basket: compareAndTransform(response.basket_parts, recreateBasketItems),
                cardCounts: newCounts,
                checkBasketResponse: response ? response : null
              });
            }
          );
        },

        checkCardCount: basket => {
          const newCardCounts = basket['noChanges'].reduce(
            (counts, part) => {
              if (part.part_hash) {
                counts[part.part_hash] = part.quantity;
              }

              return counts;
            },
            {} as BasketStore['cardCounts']
          );

          set({ cardCounts: newCardCounts });
        },

        resetBasketState: () => {
          set({
            basket: null,
            basketId: EMPTY_STRING,
            basketParts: null,
            cardCounts: EMPTY_OBJECT as BasketStore['cardCounts'],
            isLoading: false
          });
        }
      }),
      {
        name: 'basket-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
