import React from 'react';
import { Text } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';
import { pickupPointSettings } from '@pages/PlacingAnOrder/lib/PickupPoint/lib/constants/settings';
import { Breadcrumbs } from '@shared/components/Breadcrumbs';
import { LocationAffectedDelivery } from '../lib/LocationAffectedDelivery';
import { placingAnOrderBreadcrumbsLinks } from '../lib/constants/placingAnOrderBreadcrumbsLinks';
import { OrderPrice } from '../lib/OrderPrice';
import { PickupPoint } from '../lib/PickupPoint';

import { PlacingAnOrderViewProps } from './types';
import styles from './styles.module.scss';

export const PlacingAnOrderView = ({
  handleSubmit,
  orderValues,
  slides,
  handleClickPoint,
  currentPoint,
  isLoading,
  sliderRef,
  address
}: PlacingAnOrderViewProps) => (
  <div className={styles.placingAnOrder} data-testid={dataTestId.PLACING_AN_ORDER_VIEW}>
    <Breadcrumbs links={placingAnOrderBreadcrumbsLinks} />
    <Text className={styles.placingAnOrder_titleText} variant='header'>
      Оформление заказа
    </Text>
    <div className={styles.placingAnOrder_wrap}>
      <div className={styles.placingAnOrder_left}>
        <PickupPoint
          currentPoint={currentPoint}
          handleClickPoint={handleClickPoint}
          isLoading={isLoading}
          settings={pickupPointSettings}
          sliderRef={sliderRef}
          slides={slides}
        />
        {/*TODO: Вернуть блок с вводом данных о пользователе, когда это будет нужно*/}
        {/*<ReceiverForm errors={errors} handleChange={handleChange} values={values} />*/}
        <LocationAffectedDelivery address={address} />
      </div>
      <OrderPrice
        btnText='Оформить'
        discount={orderValues.discount}
        handleSubmit={handleSubmit}
        isSelectedAddress={Boolean(address)}
        price={orderValues.price}
        quantity={orderValues.quantity}
      />
    </div>
  </div>
);
