import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { toast } from 'react-toastify';
import { CollaborateStore, PostCollaborateDataProps } from './types';
import { postCollaborate } from './service';

export const useCollaborateStore = create(
  devtools(
    persist<CollaborateStore>(
      set => ({
        isLoading: false,
        isSuccess: false,
        postCollaborateData: async ({ serverData, resetForm }: PostCollaborateDataProps) => {
          await executeRequest(
            () => postCollaborate(serverData),
            isLoading => set({ isLoading }),
            response => {
              if (response?.success) {
                toast.success('Обращение отправлено');
                resetForm();
              }
              set({ isSuccess: response?.success || false });
            }
          );
        }
      }),
      {
        name: 'collaborate-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
