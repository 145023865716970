import { IconChevronLeft } from '@uremont/erp-ui';
import React from 'react';
import { Link } from 'react-router-dom';

import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';
import { BackButtonProps } from './types';

export const BackButton = ({ text, url }: BackButtonProps) => {
  return (
    <Link className={styles.backButton} data-testid={dataTestId.BACK_BTN} to={url}>
      <IconChevronLeft /> {text}
    </Link>
  );
};
