import { Button, Skeleton, Text } from '@uremont/erp-ui';
import { NoInfoView } from '@shared/components/NoInfo/ui/NoInfoView';
import { ProductCard } from '@shared/components/ProductCard';
import { HIDE_TEXT, IN_BASKET_TEXT, MORE_PRODUCTS_TEXT } from '@shared/constants/ texts';
import { dataTestId } from '@shared/constants/dataTestId';
import { CustomModal } from '@shared/components/CustomModal/CustomModal';
import { FilterPageListViewProps } from './types';

import styles from './styles.module.scss';

export const FilterPageListView = ({
  title,
  products,
  id,
  showAll,
  showBtnAll,
  handleShowAll,
  noInfo,
  isLoading,
  handleSelectedArticleClicked,
  onClick,
  isOpenModal,
  renderChildren
}: FilterPageListViewProps) => {
  return (
    <div className={styles.filterPageList} data-testid={dataTestId.FILTER_PAGE_LIST} id={id}>
      <Text className={styles.filterPageList_caption} variant='headline-L'>
        {isLoading ? <Skeleton height='36px' width='440px' /> : title}
      </Text>
      {products &&
        products.map((item, index) => (
          <ProductCard
            btnText={IN_BASKET_TEXT}
            data-testid={`${dataTestId.FILTER_PAGE_LIST_PRODUCT}-${index}`}
            handleSelectedArticleClicked={handleSelectedArticleClicked}
            horizontal={true}
            isLastItem={index === products.length - 1}
            isLoading={isLoading}
            item={item}
            key={`${item.partHash}${index}`}
          />
        ))}
      {noInfo && <NoInfoView onClick={onClick} />}
      {isLoading ? (
        <Skeleton height='48px' width='100%' />
      ) : (
        <div>
          {showBtnAll && (
            <Button
              className={styles.filterPageList_btn}
              data-testid={dataTestId.FILTER_PAGE_LIST_BTN_SEARCH}
              onClick={handleShowAll}
              size='md'
            >
              <Text className={styles.filterPageList_btn_text} variant='body-tight'>
                {showAll ? HIDE_TEXT : MORE_PRODUCTS_TEXT}
              </Text>
            </Button>
          )}
        </div>
      )}
      <CustomModal
        children={renderChildren}
        isOpen={isOpenModal}
        title='Заявка на подбор запчастей'
        toggleModal={onClick}
        width='xs'
      />
    </div>
  );
};
