import React, { ChangeEvent, useState } from 'react';
import { Button, IconMagnifyingGlass, IconXmark } from '@uremont/erp-ui';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';

import { ReactComponent as CarIcon } from 'src/assets/CarIcon.svg';
import { ReactComponent as PartCarIcon } from 'src/assets/PartCarIcon.svg';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { useScreenSizeBreakpoints } from 'src/shared/hooks/use-screen-size-breakpoints';
import { useSearchDetailsStore } from 'src/shared/model/Store/SearchDetails';
import { VIN_VALUE_LENGTH } from 'src/shared/constants/consts';
import { useCarCatalog } from 'src/shared/hooks/use-car-catalog';
import { InputsFormValues, useInputsForm } from 'src/shared/hooks/use-inputs-form';
import { defaultValuesSearchForm } from 'src/pages/Main/lib/Search/lib/constants/constants';
import { searchFormSchema } from 'src/pages/Main/lib/Search/lib/constants/schema';
import styles from 'src/pages/SearchByVIN/ui/styles.module.scss';
import { SearchByVINView } from './SearchByVINView';
import { CarModification } from './types';

export const SearchByVIN = () => {
  const [searchByVINDetailsData, getSearchDetailsByVINData] = useSearchDetailsStore(state => [
    state.searchByVINDetailsData,
    state.getSearchDetailsByVINData
  ]);
  const [selectedRow, setSelectedRow] = useState<CarModification>(EMPTY_OBJECT as CarModification);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [initialValues, setInitialValues] = useState<InputsFormValues>(defaultValuesSearchForm),
    VINValue = searchByVINDetailsData && searchByVINDetailsData[0]?.vin;
  const [searchValue, setSearchValue] = useState(VINValue || EMPTY_STRING);
  const formik = useFormik<InputsFormValues>({
    initialValues,
    validationSchema: searchFormSchema,
    onSubmit: (values: InputsFormValues) => {
      setInitialValues(values);
    }
  });
  const {
    errors,
    values,
    handleChange,
    handleSubmit,
    resetForm,
    handleBlur,
    setFieldValue,
    touched
  } = formik;

  const { renderChildren } = useInputsForm({
    values,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched
  });
  const { getCarGroupParts, setSelectedModel, isLoadingCarGroupParts } = useCarCatalog();
  const navigate = useNavigate();
  const { isTablet } = useScreenSizeBreakpoints(),
    hasSearchResults = searchByVINDetailsData?.length;
  const handleButtonClick = () => {
    searchValue.length === VIN_VALUE_LENGTH && getSearchDetailsByVINData(searchValue, navigate);
  };
  const carsModificationData = searchByVINDetailsData
    ? searchByVINDetailsData.map(item => {
        const { brand, modelName, title, parameters, carId } = item;
        const yearParam = parameters.find(param => param.key === 'year');
        const regionParam = parameters.find(param => param.key === 'sales_region');

        return {
          id: carId,
          ['Марка']: brand,
          ['Обозначенные модели']: modelName,
          ['Название']: title,
          ['Год']: yearParam?.value || EMPTY_STRING,
          ['Регион']: regionParam?.value || EMPTY_STRING
        };
      })
    : EMPTY_ARRAY;
  const searchInputButton = () => (
    <Button
      icon={<IconMagnifyingGlass />}
      iconAlign='end'
      onClick={handleButtonClick}
      variant='solid'
    />
  );
  const toggleModal = () => {
    setIsOpenModal(prevState => !prevState);
    resetForm();
  };
  const handleRadioChange = (index: { [p: string]: string }) => {
    setSelectedModel(index);
    setSelectedRow(index);
  };
  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const handleNext = () =>
    selectedRow && getCarGroupParts(selectedRow['Марка'].toLowerCase(), selectedRow?.id, navigate);
  const Icon = isTablet ? <PartCarIcon /> : <CarIcon />;
  const searchWithClearButton = (
    <div className={styles.searchWithClearButton}>
      {searchInputButton()}
      {searchValue && (
        <div className={styles.closeIcon}>
          <IconXmark onClick={() => setSearchValue(EMPTY_STRING)} />
        </div>
      )}
    </div>
  );

  return (
    <SearchByVINView
      Icon={Icon}
      VINValue={VINValue}
      carsModificationData={carsModificationData}
      handleNext={handleNext}
      handleRadioChange={handleRadioChange}
      handleSearchChange={handleSearchChange}
      hasSearchResults={hasSearchResults}
      isLoadingCarGroupParts={isLoadingCarGroupParts}
      isOpenModal={isOpenModal}
      renderChildren={renderChildren()}
      searchValue={searchValue}
      searchWithClearButton={searchWithClearButton}
      selectedRow={selectedRow}
      toggleModal={toggleModal}
    />
  );
};
