import * as Yup from 'yup';

import * as expect from '@utils/validationExpect';
import * as messages from '@utils/validationMessages';
import * as patterns from '@utils/validationPatterns';

import { PlacingAnOrderProps } from '../../ui/types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const placingAnOrderFormSchema: Yup.SchemaOf<PlacingAnOrderProps> = Yup.object()
  .shape({
    name: Yup.string()
      .matches(patterns.fullName, messages.correctName)
      .max(50, messages.maxLength(50))
      .required(messages.required),
    phone: Yup.string()
      .test('phone', messages.correctPhone, expect.phoneNumber)
      .required(messages.required)
  })
  .required(messages.required);
