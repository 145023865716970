import { DataOrder } from '@shared/components/OrderCard/types';
import { formattedDate, formattedDatePerYear } from '@shared/functions/formattedDate/formattedDate';
import { OrderItem } from '@shared/components/OrderCardItem/ui/types';
import { EMPTY_ARRAY, EMPTY_STRING } from '@shared/constants/fallbacks';
import { SearchByArticleData } from '../SearchDetails/types';
import { CollectionItemResponse, OrderDetail, OrderPartsItemResponse } from './types';

const responseOrderPartsToData = (orderParts: OrderPartsItemResponse[]): OrderItem[] => {
  if (!orderParts || !orderParts.length) {
    return EMPTY_ARRAY;
  }

  return orderParts.map(item => ({
    id: item.id,
    img: EMPTY_STRING, // TODO: Пока у бека нет возможности добавить это поля из-за проблем abcp
    name: item.description,
    vendorCode: item.article_name,
    count: item.quantity,
    sumOrder: String(item.customer_price),
    brand: item.brand_name,
    partHash: item.part_hash
  }));
};

export const responseHistoryOrdersToData = (orders: CollectionItemResponse[]): DataOrder[] => {
  return orders.map(item => {
    const dateOrder = item.order_parts.length
      ? formattedDate(item.order_parts[0].created_at)
      : EMPTY_STRING;

    return {
      id: item.id,
      dateOrder: dateOrder,
      status: item.status,
      deliveryLink: item.delivery_addr,
      sumOrder: String(item.sum),
      numberOrder: String(item.id),
      historyOrder: responseOrderPartsToData(item.order_parts),
      recipient: item.customer_name
    };
  });
};

export const getYearsOrders = (
  orders: CollectionItemResponse[],
  currentYears: string[]
): string[] => {
  const years: string[] = [...currentYears];

  const index = years.indexOf('Все');
  if (index > -1) {
    years.splice(index, 1);
  }

  orders.forEach(item => {
    if (item.order_parts.length) {
      const year = formattedDatePerYear(item.order_parts[0].created_at);

      if (years.find(currentYear => currentYear === year)) {
        return;
      }

      years.push(year);
    }
  });

  return years;
};

const responseHistoryOrderToOrderDetailProducts = (
  orderParts: OrderPartsItemResponse[]
): SearchByArticleData[] => {
  if (!orderParts || !orderParts.length) {
    return EMPTY_ARRAY;
  }

  return orderParts.map(item => ({
    id: item.id,
    brand: item.brand_name,
    vendorCode: item.article_name,
    description: item.description,
    partHash: item.part_hash
  }));
};

export const responseHistoryOrderToOrderDetail = (
  order: CollectionItemResponse
): OrderDetail | null => {
  if (!order) {
    return null;
  }

  return {
    status: order.status,
    dateOrder: formattedDate(order.order_parts[0].created_at),
    numberOrder: String(order.id),
    recipient: order.customer_name,
    deliveryLink: order.delivery_addr,
    products: responseHistoryOrderToOrderDetailProducts(order.order_parts)
  };
};
