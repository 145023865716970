export const cyrillicRegex = /^[а-яА-ЯёЁ\-/,.\s]+$/iu;

export const cyrillicAndNumberRegex = /^[а-яА-ЯёЁ0-9\-/,.\s]+$/gu;

export const cyrillicAndLatinRegex = /^[a-zA-Z-а-яА-ЯёЁ\-/,.\s]+$/iu;

export const cyrillicAndLatinAndNumberRegex = /^[a-zA-Z-а-яА-ЯёЁ0-9\-/,.\s]+$/iu;
export const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/iu;
export const numeric = /^[0-9]+$/;
export const numericWithComa = /^[0-9]*[.]?[0-9]+$/;
export const numericWithSpace = /^[0-9]*[ ]?[0-9]+$/;
export const vin = /^[A-HJ-NPR-Z0-9]{17}$/;
export const sts = /^\d{2}([АВЕКМНОРСТУХ]{2}|[0-9]{2})\d{6}$/;
export const pts = /(^\d{2}([АВЕКМНОРСТУХ]{2}|[0-9]{2})\d{6}$)|(^\d{15})/;
export const licensePlateRegex = /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/;
export const licencePlate = /^[АВЕКМНОРСТУХ]\d{3}[АВЕКМНОРСТУХ]{2}\d{2,3}$/;
export const cyrillicAndNumberWith500CharLimitRegex = /^[а-яА-ЯёЁ0-9\s]{0,500}$/;
export const fullName = /^[a-zA-Z-а-яА-ЯёЁ0-9'"#№&*()$\-/,.\s]+$/iu;
export const withoutSpace = /^[а-яА-ЯёЁ]+$/iu;
