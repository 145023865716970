import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { isLettersInNumberAutoRegex, isNumberRegex } from 'src/shared/constants/regex';

import { RemoveCharsProps } from '../../ui/types';

export const removeChars = ({ str, num }: RemoveCharsProps): string => {
  if (num > str.length) return EMPTY_STRING;

  return str.substring(num);
};

export const removeSpacesAndPipe = (str: string): string => {
  return str.replace(/\s/g, EMPTY_STRING).replace(/\|/g, EMPTY_STRING);
};

// Пример, что функцию должна принимать: A000AA00 или A000AA000
export const changingTheLineToTheCarNumber = (str: string) => {
  const firstPart = str.substring(0, 1).length > 0 ? str.substring(0, 1) + ' ' : EMPTY_STRING,
    secondPart = () => {
      const secondPartLength = removeSpacesAndPipe(str.substring(1, 4)).length;

      switch (secondPartLength) {
        case 1:
          return str.substring(1, 2);
        case 2:
          return str.substring(1, 3);
        case 3:
          return str.substring(1, 4) + ' ';
        default:
          return EMPTY_STRING;
      }
    },
    thirdPart = () => {
      const thirdPartLength = removeSpacesAndPipe(str.substring(4, 6)).length;

      if (thirdPartLength === 1) {
        return str.substring(4, 6);
      }

      if (thirdPartLength === 2) {
        return str.substring(4, 6) + ' | ';
      }

      return EMPTY_STRING;
    },
    fourthPart = str.substring(6, 9);

  return firstPart + secondPart() + thirdPart() + fourthPart; // Пример результата: A 000 AA | 00
};

export const checkingCorrectNumberAuto = (str: string): boolean => {
  const stringWithoutSpaces = str.replace(/\s/g, EMPTY_STRING);

  if (stringWithoutSpaces.substring(0, 1).replace(/\s/g, EMPTY_STRING)) {
    const isValidFirstPart = isLettersInNumberAutoRegex.test(stringWithoutSpaces.substring(0, 1));

    if (!isValidFirstPart) {
      return false;
    }
  }

  if (stringWithoutSpaces.substring(1, 4).replace(/\s/g, EMPTY_STRING)) {
    const isValidSecondPart = isNumberRegex.test(stringWithoutSpaces.substring(1, 4));

    if (!isValidSecondPart) {
      return false;
    }
  }

  if (stringWithoutSpaces.substring(4, 6).replace(/\s/g, EMPTY_STRING)) {
    const isValidThirdPart = isLettersInNumberAutoRegex.test(stringWithoutSpaces.substring(4, 6));

    if (!isValidThirdPart) {
      return false;
    }
  }

  if (stringWithoutSpaces.substring(6, 9).replace(/\s/g, EMPTY_STRING)) {
    const isValidFourthPart = isNumberRegex.test(stringWithoutSpaces.substring(6, 9));

    if (!isValidFourthPart) {
      return false;
    }
  }

  return true;
};

export const deleteValue = (str: string, currentStr: string): string => {
  let newStr = str.slice();
  const strLength = str.length;

  switch (strLength) {
    case 2:
    case 6:
      newStr = newStr.slice(0, -1);
      break;
    case 10:
      newStr = newStr.slice(0, -2);
      break;
    case 11:
      newStr = newStr.slice(0, -3);
      break;
    default:
      newStr = newStr.slice();
  }

  if (strLength >= 11) {
    const testFirst = currentStr.slice(0, 10) + currentStr.slice(11); // А 222 АА | 222 -> А 222 АА |222
    const testSecond = currentStr.slice(0, 9) + currentStr.slice(10); // А 222 АА | 222 -> А 222 АА  222
    const testThree = currentStr.slice(0, 8) + currentStr.slice(9); // А 222 АА | 222 -> А 222 АА| 222
    const testFour = currentStr.slice(0, 5) + currentStr.slice(6); // А 222 АА | 222 -> А 222АА | 222
    const testFive = currentStr.slice(0, 1) + currentStr.slice(2); // А 222 АА | 222 -> А222 АА | 222
    const testSix = currentStr.slice(0, 9) + currentStr.slice(11); // А 222 АА | 222 -> А 222 АА 222
    const testSeven = currentStr.slice(0, 8) + currentStr.slice(11); // А 222 АА | 222 -> А 222 АА222

    if (
      [testFirst, testSecond, testThree, testFour, testFive, testSix, testSeven].includes(newStr)
    ) {
      return currentStr;
    }
  }

  if (strLength === 8 || strLength === 7) {
    const testFirst = currentStr.slice(0, 1) + currentStr.slice(2); // А 222 АА -> А222 АА
    const testSecond = currentStr.slice(0, 5) + currentStr.slice(6); // А 222 АА -> А 222АА

    if ([testFirst, testSecond].includes(newStr)) {
      return currentStr;
    }
  }

  return newStr;
};
