// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Id1aAEYR4erOU1ufw6qX{margin-top:40px;display:flex;color:var(--color-brand);cursor:pointer;gap:8px;max-width:150px}`, "",{"version":3,"sources":["webpack://./src/shared/components/BackButton/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,eAAA,CACA,YAAA,CACA,wBAAA,CACA,cAAA,CACA,OAAA,CACA,eAAA","sourcesContent":[".backButton {\n  margin-top: 40px;\n  display: flex;\n  color: var(--color-brand);\n  cursor: pointer;\n  gap: 8px;\n  max-width: 150px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backButton": `Id1aAEYR4erOU1ufw6qX`
};
export default ___CSS_LOADER_EXPORT___;
