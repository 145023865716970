export const deliveries = [
  {
    id: 1,
    title: 'Любой срок',
    value: Number.MAX_VALUE
  },
  {
    id: 4,
    title: 'До 3 дня',
    value: 2
  },
  {
    id: 5,
    title: 'До 5 дней',
    value: 4
  },
  {
    id: 6,
    title: 'До 7 дней',
    value: 6
  }
];
