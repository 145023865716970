import { getCookie } from '@uremont/erp-ui';
import axios, { AxiosRequestHeaders } from 'axios';

import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { AUTH_TOKEN_COOKIE_NAME } from 'src/shared/constants/values';

export const API_URL = process.env.REACT_APP_API_URL ?? EMPTY_STRING;

const axiosInstance = axios.create({
  baseURL: API_URL
});

axiosInstance.interceptors.request.use(config => {
  const authToken = getCookie(AUTH_TOKEN_COOKIE_NAME);

  if (authToken) {
    if (!config.headers) {
      config.headers = {} as AxiosRequestHeaders;
    }

    config.headers.Authorization = `Bearer ${authToken}`;
  }

  return config;
});

export default axiosInstance;
