import React from 'react';
import cx from 'classnames';
import { Skeleton } from '@uremont/erp-ui';

import { EMPTY_STRING } from '@shared/constants/fallbacks';

import { dataTestId } from '@shared/constants/dataTestId';
import styles from './styles.module.scss';
import { SkeletonButtonProps } from './types';

export const SkeletonButton = ({ className = EMPTY_STRING }: SkeletonButtonProps) => {
  return (
    <div
      data-testid={dataTestId.SKELETON_BUTTON}
      className={cx(styles.skeletonButton, {
        [className]: className
      })}
    >
      <Skeleton height='48px' width='100%' />
    </div>
  );
};
