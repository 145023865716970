import { ProductCard } from 'src/shared/components/ProductCard/ui/types';
import { EMPTY_ARRAY } from 'src/shared/constants/fallbacks';
import { RecommendedProductsResponse } from 'src/shared/model/Store/RecommendedPurchase/types';

export const convertRecommendedArticleProductsData = (
  recommendProducts: RecommendedProductsResponse[]
): ProductCard[] =>
  recommendProducts
    ? recommendProducts.map(product => {
        return {
          id: product.total,
          vendorCode: product.number,
          brand: product.brand,
          price: product.total,
          name: product.number
        };
      })
    : EMPTY_ARRAY;
