export const countryCitiesLinks = {
  1: [
    {
      name: 'Москва',
      slug: 'https://uremont.com/moskva'
    },
    {
      name: 'Санкт-Петербург',
      slug: 'https://uremont.com/sankt-peterburg'
    },
    {
      name: 'Екатеринбург',
      slug: 'https://uremont.com/yekaterinburg'
    },
    {
      name: 'Казань',
      slug: 'https://uremont.com/kazan'
    },
    {
      name: 'Ростов-на-Дону',
      slug: 'https://uremont.com/rostov-na-donu'
    },
    {
      name: 'Красноярск',
      slug: 'https://uremont.com/krasnoyarsk'
    },
    {
      name: 'Челябинск',
      slug: 'https://uremont.com/chelyabinsk'
    },
    {
      name: 'Иркутск',
      slug: 'https://uremont.com/irkutsk'
    },
    {
      name: 'Нижний Новгород',
      slug: 'https://uremont.com/nizhniy-novgorod'
    },
    {
      name: 'Новосибирск',
      slug: 'https://uremont.com/novosibirsk'
    },
    {
      name: 'Омск',
      slug: 'https://uremont.com/omsk'
    },
    {
      name: 'Пермь',
      slug: 'https://uremont.com/perm'
    },
    {
      name: 'Воронеж',
      slug: 'https://uremont.com/voronezh'
    },
    {
      name: 'Самара',
      slug: 'https://uremont.com/samara'
    },
    {
      name: 'Уфа',
      slug: 'https://uremont.com/ufa'
    },
    {
      name: 'Волгоград',
      slug: 'https://uremont.com/volgograd'
    }
  ],
  82: [
    {
      name: 'Нур-Султан',
      slug: 'https://uremont.com/nur-sultan'
    },
    {
      name: 'Алма-Ата',
      slug: 'https://uremont.com/alma-ata'
    },
    {
      name: 'Павлодар',
      slug: 'https://uremont.com/pavlodar'
    },
    {
      name: 'Караганда',
      slug: 'https://uremont.com/karaganda'
    },
    {
      name: 'Костанай',
      slug: 'https://uremont.com/kostanai'
    },
    {
      name: 'Актобе',
      slug: 'https://uremont.com/aktobe'
    },
    {
      name: 'Атырау',
      slug: 'https://uremont.com/atirau'
    },
    {
      name: 'Семей',
      slug: 'https://uremont.com/semei'
    },
    {
      name: 'Усть-Каменогорск',
      slug: 'https://uremont.com/ust-kamenogorsk'
    },
    {
      name: 'Шымкент',
      slug: 'https://uremont.com/shimkent'
    },
    {
      name: 'Тараз',
      slug: 'https://uremont.com/taraz'
    }
  ],
  22: [
    {
      name: 'Брест',
      slug: 'https://uremont.com/brest'
    },
    {
      name: 'Гродно',
      slug: 'https://uremont.com/grodno'
    },
    {
      name: 'Лида',
      slug: 'https://uremont.com/lida'
    },
    {
      name: 'Барановичи',
      slug: 'https://uremont.com/baranovichi'
    },
    {
      name: 'Солигорск',
      slug: 'https://uremont.com/soligorsk'
    },
    {
      name: 'Пинск',
      slug: 'https://uremont.com/pinks'
    },
    {
      name: 'Мозырь',
      slug: 'https://uremont.com/mozir'
    },
    {
      name: 'Речица',
      slug: 'https://uremont.com/rechica'
    },
    {
      name: 'Светлогорск',
      slug: 'https://uremont.com/svetlogorsk'
    },
    {
      name: 'Жлобин',
      slug: 'https://uremont.com/zhlobin'
    },
    {
      name: 'Бобруйск',
      slug: 'https://uremont.com/bobruisk'
    },
    {
      name: 'Минск',
      slug: 'https://uremont.com/minsk'
    },
    {
      name: 'Могилёв',
      slug: 'https://uremont.com/mogilev'
    },
    {
      name: 'Борисов',
      slug: 'https://uremont.com/borisov'
    },
    {
      name: 'Молодечно',
      slug: 'https://uremont.com/molodechno'
    },
    {
      name: 'Орша',
      slug: 'https://uremont.com/orsha'
    },
    {
      name: 'Витебск',
      slug: 'https://uremont.com/vitebsk'
    },
    {
      name: 'Новополоцк',
      slug: 'https://uremont.com/novopolozk'
    },
    {
      name: 'Полоцк',
      slug: 'https://uremont.com/polozk'
    }
  ]
};
