import React, { useEffect, useState } from 'react';
import { defaultValuesSearchForm } from 'src/pages/Main/lib/Search/lib/constants/constants';
import { searchFormSchema } from 'src/pages/Main/lib/Search/lib/constants/schema';
import { useFormik } from 'formik';
import { Icon } from 'src/pages/PartsCatalogByCar/lib/Icon/Icon';
import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { InputsFormValues, useInputsForm } from '@shared/hooks/use-inputs-form';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { useSearchRequestStore } from '@shared/model/Store/SearchRequest';
import { PartsCatalogByCarView } from './PartsCatalogByCarView';

export const PartsCatalogByCar = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [initialValues, setInitialValues] = useState<InputsFormValues>(defaultValuesSearchForm);

  const { getCarModels, carIds } = useCarCatalog();

  const [searchRequest] = useSearchRequestStore(state => [state.searchRequest]);

  const formik = useFormik<InputsFormValues>({
    initialValues,
    validationSchema: searchFormSchema,
    onSubmit: (values: InputsFormValues) => {
      setInitialValues(values);
    }
  });
  const { errors, values, handleChange, resetForm, handleBlur, setFieldValue, touched } = formik;

  const searchRequestValue = {
    phone: values.phone?.replace(/\D/g, EMPTY_STRING) ?? EMPTY_STRING,
    query: values.comment ?? EMPTY_STRING,
    file: values.file ? values.file[0] : null
  };

  const handleSubmit = async () => {
    await searchRequest(searchRequestValue);
    toggleModal();
  };

  const { renderChildren } = useInputsForm({
    values,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched
  });
  const toggleModal = () => {
    setIsOpenModal(prevState => !prevState);
    resetForm();
  };

  useEffect(() => {
    getCarModels(carIds[0]);
  }, []);

  return (
    <PartsCatalogByCarView
      Icon={Icon()}
      isOpenModal={isOpenModal}
      renderChildren={renderChildren()}
      toggleModal={toggleModal}
    />
  );
};
