import React, { useEffect } from 'react';

import { useFAQStore } from 'src/shared/model/Store';
import { skeletonRenderFAQData } from '../lib/constants/skeletonRenderFAQData';
import { FAQView } from './FAQView';

export const FAQ = () => {
  const [FAQItems, isLoading, getFAQData] = useFAQStore(state => [
    state.FAQItems,
    state.isLoading,
    state.getFAQData
  ]);

  useEffect(() => {
    getFAQData();
  }, []);

  return <FAQView FAQItems={isLoading ? skeletonRenderFAQData : FAQItems} isLoading={isLoading} />;
};
