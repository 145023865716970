export const getHostname = (hostname: string) => {
  if (hostname === 'localhost' || hostname === '127.0.0.1') {
    return hostname;
  }

  const splitHostname = hostname.split('.');

  if (splitHostname.length < 2) {
    return `.${hostname}`;
  }

  const topLevelHostname = splitHostname.slice(splitHostname.length - 2).join('.');

  return `.${topLevelHostname}`;
};
