import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import { IconChevronDown, IconChevronUp, Text } from '@uremont/erp-ui';
import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { CustomSelectProps } from 'src/shared/components/CustomSelect/types';
import { dataTestId } from 'src/shared/constants/dataTestId';
import styles from './styles.module.scss';

export const CustomSelect = ({
  options,
  defaultValue,
  onChange,
  title = EMPTY_STRING,
  className
}: CustomSelectProps) => {
  const [isOpen, setIsOpen] = useState(false),
    [selectedOption, setSelectedOption] = useState(defaultValue),
    toggling = () => setIsOpen(!isOpen),
    onOptionClicked = (value: string) => () => {
      setSelectedOption(value);
      setIsOpen(false);
      onChange(value);
    };

  useEffect(() => {
    setSelectedOption(defaultValue);
  }, [defaultValue]);

  return (
    <div className={cx(styles.customSelect, className)} data-testid={dataTestId.CUSTOM_SELECT}>
      <Text variant='caption-regular'>{title}</Text>
      <div
        className={cx(styles.customSelect_wrapper, {
          [styles.open]: isOpen
        })}
      >
        <Text
          className={styles.customSelect_header}
          data-testid={dataTestId.CUSTOM_SELECT_TITLE}
          onClick={toggling}
          variant='body-tight'
        >
          {selectedOption}
          {isOpen ? (
            <IconChevronUp className={styles.customSelect_icon} />
          ) : (
            <IconChevronDown className={styles.customSelect_icon} />
          )}
        </Text>
        {isOpen && (
          <div className={styles.customSelect_list}>
            {options.map((option, index) => (
              <Text
                className={styles.customSelect_option}
                data-testid={`${dataTestId.CUSTOM_SELECT_ITEM}-${index}`}
                key={option}
                onClick={onOptionClicked(option)}
              >
                {option}
              </Text>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};
