import { convertHoursToDays } from '@utils/convertHoursToDays';
import { SearchByArticleData } from '@shared/model/Store/SearchDetails/types';
import { ProductFilteringFilters } from './types';

export const productFiltering = (
  products: SearchByArticleData[],
  filters: ProductFilteringFilters
): SearchByArticleData[] => {
  // Проверка, какие бренды включены в фильтре
  const brandsFilter = filters.brands.filter(item => item.status);

  // Фильтрация по брендам
  const filtersBrands = products.filter(item => {
    if (!brandsFilter.length) {
      return true;
    }

    return brandsFilter.filter(brand => item.brand === brand.value).length;
  });

  // Фильтрация по срокам доставки
  const filtersDay = filtersBrands.filter(item =>
    filters.day !== null && (filters.day === 0 || filters.day)
      ? item.deliveryPeriod && convertHoursToDays(item.deliveryPeriod) <= filters.day
      : true
  );

  // Фильтрация по количеству товара
  const filtersAvailability = filtersDay.filter(item =>
    filters.availability ? item.availability && item.availability >= filters.availability : true
  );

  return filtersAvailability;
};
