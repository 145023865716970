// TODO: Изменить функцию, когда появится соответствующий метод на беке. В apiConfig создан объект getRecommendedPurchase
// eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
import { fetchData } from 'src/shared/api/apiConfig';
import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import {
  RecommendedArticleProductsPostData,
  RecommendedProductsResponse
} from 'src/shared/model/Store/RecommendedPurchase/types';

// TODO: Временно поставлена заглушка, пока не появится нужный метод
export const getRecommendedPurchase = async (): Promise<void> => fetchData(EMPTY_STRING);

export const getRecommendedByArticleProducts = async (
  brand: string,
  number: string,
  limit: number
): Promise<RecommendedProductsResponse[] | void> =>
  fetchData('getRecommendedByArticleProducts', { brand, number, limit });

export const getRecommendedByBasketProducts = async (
  postData: RecommendedArticleProductsPostData
): Promise<RecommendedProductsResponse[] | void> =>
  fetchData('getRecommendedByBasketProducts', postData);
