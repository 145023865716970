import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useBasketStore } from '@shared/model/Store';
import { BasketPostData } from '@shared/model/Store/Basket/types';
import { EMPTY_OBJECT } from '@shared/constants/fallbacks';
import { checkBasketId, getBasket } from '@shared/model/Store/Basket/service';
import { getCountsProducts } from '../functions/getCountsProducts/getCountsProducts';
import { pathConfig } from '../constants/pathConfig';

export const useBasket = (id: string | number, clickedItem?: { [key: string]: string }) => {
  const [
    basketId,
    addToBasket,
    removeFromBasket,
    setCardCount,
    cardCounts,
    setBasket,
    setLoadItems,
    resetRecreateBasket,
    statusCheckBasketId
  ] = useBasketStore(state => [
    state.basketId,
    state.addToBasket,
    state.removeFromBasket,
    state.setCardCount,
    state.cardCounts,
    state.setBasket,
    state.setLoadItems,
    state.resetRecreateBasket,
    state.checkBasketId
  ]);

  const [isLoadingBasket, setIsLoadingBasket] = useState(false);
  const basketAddedData = clickedItem
    ? {
        article_name:
          'number' in clickedItem ? clickedItem.number?.toString() : clickedItem.article_name,
        brand_name: 'brand' in clickedItem ? clickedItem.brand : clickedItem.brand_name,
        supplier_code:
          'supplierCode' in clickedItem
            ? clickedItem.supplierCode?.toString()
            : clickedItem.supplier_code?.toString(),
        distributor_id:
          'distributorId' in clickedItem
            ? clickedItem.distributorId?.toString()
            : clickedItem.distributor_id?.toString(),
        part_hash: clickedItem.partHash || clickedItem.part_hash
      }
    : (EMPTY_OBJECT as BasketPostData);

  const navigate = useNavigate();
  const cardCount = cardCounts[id] || 0;
  const countNotZero = cardCount !== 0;

  const setBasketValue = useCallback(async () => {
    if (basketId) {
      const response = await checkBasketId(basketId);
      if (response) {
        setBasket(response);

        const newCounts = getCountsProducts(response.basket_parts);
        setCardCount(newCounts);
      }
    }

    setIsLoadingBasket(false);
    setLoadItems(false);
  }, [basketId, setBasket]);

  const handleAdd = useCallback(async () => {
    setIsLoadingBasket(true);
    setLoadItems(true);

    clickedItem && (await addToBasket(basketId, basketAddedData));

    await setBasketValue();
  }, [id, cardCount, setCardCount, addToBasket, basketId, basketAddedData, clickedItem]);

  const handleDelete = useCallback(async () => {
    setIsLoadingBasket(true);
    setLoadItems(true);

    if (cardCount <= 0) {
      return;
    }

    clickedItem && (await removeFromBasket(basketId, basketAddedData));

    if (cardCount <= 1) {
      statusCheckBasketId(basketId);

      return;
    }

    await setBasketValue();
  }, [cardCount, id, setCardCount, removeFromBasket, basketId, basketAddedData, clickedItem]);

  const handleBasketButton = () => {
    countNotZero ? navigate(pathConfig.BasketPath) : handleAdd();
  };

  return {
    basketId,
    addToBasket,
    removeFromBasket,
    setCardCount,
    cardCounts,
    getBasket,
    clickedItem,
    cardCount,
    handleBasketButton,
    handleDelete,
    handleAdd,
    countNotZero,
    navigate,
    isLoadingBasket,
    setBasketValue,
    resetRecreateBasket
  };
};
