import { MouseEvent } from 'react';
import { IconedText } from '@uremont/ui-kit';
import { NotesPen, Cart, Box, Plus, Security2 } from '@uremont/ui-icons-v2';
import { uremontLinks } from '@shared/constants/links';
import styles from './ProfileMenuAutopark.module.scss';
import { ProfileMenuAutoparkProps } from './types';

export const ProfileMenuAutopark = ({ onOpenModal }: ProfileMenuAutoparkProps) => {
  const handleOpenModal = (e: MouseEvent<HTMLElement>) => {
    e.preventDefault();
    onOpenModal();
  };

  return (
    <div className={styles.wrapper}>
      <a className={styles.link} href={uremontLinks.customerBids}>
        <IconedText icon={NotesPen} iconSize={24} textType='bodyTightRegular'>
          Заявки
        </IconedText>
      </a>
      <a className={styles.link} href={uremontLinks.customerCars}>
        <IconedText icon={Plus} iconSize={24} textType='bodyTightRegular'>
          Создать заявку
        </IconedText>
      </a>
      <div className={styles.link} onClick={handleOpenModal}>
        <IconedText icon={Security2} iconSize={24} textType='bodyTightRegular'>
          Страхование для ЮЛ
        </IconedText>
      </div>
      <a className={styles.link} href={uremontLinks.autopartsCart}>
        <IconedText icon={Cart} iconSize={24} textType='bodyTightRegular'>
          Корзина
        </IconedText>
      </a>
      <a className={styles.link} href={uremontLinks.autopartsOrders}>
        <IconedText icon={Box} iconSize={24} textType='bodyTightRegular'>
          Заказы
        </IconedText>
      </a>
    </div>
  );
};
