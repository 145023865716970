import { Link } from 'react-router-dom';
import { Skeleton } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';

import { CardLinkProps } from '@shared/components/RenderCard/lib/CardLink/types';
import styles from './styles.module.scss';

export const CardLink = ({ id, name, isLoading, linkTo, hasParts = false }: CardLinkProps) => {
  if (isLoading) {
    return <Skeleton height='24px' width='264px' />;
  }

  return (
    <Link
      className={styles.linkText}
      data-testid={dataTestId.GET_FOOTER_INFO_LINKS}
      key={id}
      to={linkTo ? linkTo(hasParts, name, id) : EMPTY_STRING}
    >
      {name}
    </Link>
  );
};
