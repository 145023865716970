import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useBasketStore } from '@shared/model/Store';
import { EMPTY_ARRAY, EMPTY_STRING } from '@shared/constants/fallbacks';
import { pathConfig } from '@shared/constants/pathConfig';
import { convertToProductCard } from '@pages/Basket/lib/functions/convertToProductCard/convertToProductCard';
import { basketDefaultValue } from '@shared/functions/basketDefaultValue/basketDefaultValue';
import { useRecommendedPurchaseStore } from '@shared/model/Store/RecommendedPurchase';
import { useOrders } from '@shared/hooks/use-orders';
import { useAuthStore } from '@shared/model/Store/Auth';
import { useScrollToTop } from '@shared/hooks/use-scroll-to-top';
import { BasketPostData } from '@shared/model/Store/Basket/types';
import { plural } from '@uremont/erp-ui';
import { ProductCard } from '@shared/components/ProductCard/ui/types';
import { skeletonProducts } from '../lib/constants/mockData';
import { BasketView } from './BasketView';

export const Basket = () => {
  const [
    basket,
    basketId,
    isLoadingCheck,
    removeFromBasket,
    setLoadItems,
    checkBasketId,
    checkBasketResponse,
    clearBasket
  ] = useBasketStore(state => [
    state.basket,
    state.basketId,
    state.isLoadingCheck,
    state.removeFromBasket,
    state.setLoadItems,
    state.checkBasketId,
    state.checkBasketResponse,
    state.clearBasket
  ]);

  const [recommendedBasketProducts] = useRecommendedPurchaseStore(state => [
    state.recommendedBasketProducts
  ]);

  const [isAuthorized] = useAuthStore(state => [state.isAuthorized]);

  const navigate = useNavigate();

  const basketValues = basketDefaultValue(checkBasketResponse);

  const { getOrderInformation } = useOrders();

  const productCards = useMemo(() => {
    if (!basket) return EMPTY_ARRAY;

    return basket.noChanges.map(convertToProductCard);
  }, [basket, convertToProductCard]);

  const changeCountProductsCards = useMemo(() => {
    if (!basket) return EMPTY_ARRAY;

    return basket.priceChanged.map(convertToProductCard);
  }, [basket, convertToProductCard]);

  const outOfProductsCards = useMemo(() => {
    if (!basket) return EMPTY_ARRAY;

    return basket.notFound.map(convertToProductCard);
  }, [basket, convertToProductCard]);

  const [products, setProducts] = useState(skeletonProducts);
  const [changeCountProducts, setChangeCountProducts] = useState(skeletonProducts);
  const [outOfProducts, setOutOfProducts] = useState(skeletonProducts);
  const [selectedAll, setSelectedAll] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(0);

  //TODO: Раскомментировать, когда будет необходимо добавить логику*/
  // const articles =
  //   basket?.basket_parts.map(item => ({
  //     brand: item.brand_name,
  //     number: item.article_name
  //   })) ?? EMPTY_ARRAY;
  // const postData = {
  //   articles: articles,
  //   limit: 4
  // };

  const handleSubmit = () => getOrderInformation(navigate, isAuthorized);

  const goToMain = () => navigate(pathConfig.MainPath);

  const priceChangeText = 'Cтоимость данных товаров  изменилась';

  const outOfProductsCount = outOfProductsCards.length;

  const outOfProductsPrice = useMemo(() => {
    return outOfProductsCards.reduce((total, product) => total + Number(product.price) || 0, 0);
  }, [products]);

  const isEmptyBasket =
    products.length === 0 && changeCountProducts.length === 0 && outOfProducts.length === 0;

  const formattedProductDays = (productCount: number | undefined): string =>
    productCount
      ? `${productCount} ${plural(productCount, 'товар', 'товара', 'товаров')}`
      : EMPTY_STRING;

  const outOfProductsText = `Убрали из корзины ${formattedProductDays(
    outOfProductsCount
  )} на ${outOfProductsPrice} ₽`;

  const updateSelectionState = (
    items: ProductCard[],
    setItems: React.Dispatch<React.SetStateAction<ProductCard[]>>,
    id: string,
    updateDisabledBtnDelete: React.Dispatch<React.SetStateAction<number>>
  ) => {
    const newItems = items.map(item => {
      if (item.part_hash === id) {
        if (!item.selected) {
          setSelectedAll(false);
          updateDisabledBtnDelete(prev => prev + 1);
        } else {
          updateDisabledBtnDelete(prev => prev - 1);
        }

        return { ...item, selected: !item.selected };
      }

      return item;
    });
    setItems(newItems);
  };

  const handleSelect = (id: string) => {
    updateSelectionState(products, setProducts, id, setDisabledBtnDelete);
    updateSelectionState(changeCountProducts, setChangeCountProducts, id, setDisabledBtnDelete);
    updateSelectionState(outOfProducts, setOutOfProducts, id, setDisabledBtnDelete);
  };

  const handleSelectAll = () => {
    const newSelectedAll = !selectedAll;
    const updateItemsSelection = (
      items: ProductCard[],
      setItems: React.Dispatch<React.SetStateAction<ProductCard[]>>
    ) => {
      const newItems = items.map(item => ({ ...item, selected: newSelectedAll }));
      setItems(newItems);
    };

    updateItemsSelection(products, setProducts);
    updateItemsSelection(changeCountProducts, setChangeCountProducts);
    updateItemsSelection(outOfProducts, setOutOfProducts);

    setDisabledBtnDelete(newSelectedAll ? products.length + changeCountProducts.length : 0);
    setSelectedAll(newSelectedAll);
  };

  const handleDelete = async () => {
    setIsLoading(true);
    setLoadItems(true);
    setDisabledBtnDelete(0);

    const productsForRemoval: BasketPostData[] = [];

    const newProducts = [...products, ...changeCountProducts].filter(product => {
      const matchingItem =
        checkBasketResponse &&
        checkBasketResponse.basket_parts.find(item => item.id === product.id);
      if (!matchingItem || !product.selected) {
        return true;
      }

      for (let i = 0; i < matchingItem.quantity; i++) {
        productsForRemoval.push({
          article_name: matchingItem.article_name,
          brand_name: matchingItem.brand_name,
          supplier_code: matchingItem.supplier_code.toString(),
          distributor_id: matchingItem.distributor_id.toString(),
          part_hash: matchingItem.part_hash || EMPTY_STRING
        });
      }

      return false;
    });

    if (selectedAll) {
      await clearBasket(basketId);
    } else {
      for (const item of productsForRemoval) {
        await removeFromBasket(basketId, item);
      }
    }

    await checkBasketId(basketId);

    if (newProducts.length && !selectedAll) {
      setSelectedAll(false);
    }
  };

  useScrollToTop();

  useEffect(() => {
    setProducts(productCards);
    setChangeCountProducts(changeCountProductsCards);
    setOutOfProducts(outOfProductsCards);
    setLoadItems(false);
    setIsLoading(false);
  }, [basket]);

  useEffect(() => {
    setIsLoading(prev => prev || isLoadingCheck);
  }, [isLoadingCheck]);

  useEffect(() => {
    setProducts(skeletonProducts);
    setChangeCountProducts(skeletonProducts);
    setOutOfProducts(skeletonProducts);

    basketId && checkBasketId(basketId);
  }, []);

  //TODO: Раскомментировать, когда будет необходимо добавить логику*/
  // useEffect(() => {
  //   postData.articles.length > 0 && getRecommendedBasketProductsData(postData);
  // }, []);

  return (
    <BasketView
      changeCountProducts={changeCountProducts}
      disabledBtnDelete={disabledBtnDelete}
      goToMain={goToMain}
      handleDelete={handleDelete}
      handleSelect={handleSelect}
      handleSelectAll={handleSelectAll}
      handleSubmit={handleSubmit}
      isEmptyBasket={isEmptyBasket}
      isLoadingBasketData={isLoading}
      outOfProducts={outOfProducts}
      outOfProductsText={outOfProductsText}
      priceChangeText={priceChangeText}
      products={products}
      recommendedBasketProducts={recommendedBasketProducts}
      selectedAll={selectedAll}
      values={basketValues}
    />
  );
};
