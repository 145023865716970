import { Button, Text } from '@uremont/erp-ui';
import React from 'react';

import { RequestBannerViewProps } from 'src/shared/components/RequestBanner/types';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { noop } from 'src/shared/constants/fallbacks';

import styles from './styles.module.scss';

export const RequestBannerView = ({
  Icon,
  buttonText,
  isMobile,
  subTitle,
  title,
  onPress = noop
}: RequestBannerViewProps) => {
  return (
    <div className={styles.requestContainer} data-testid={dataTestId.REQUEST_BANNER}>
      <div className={styles.requestContainer_textWrapper}>
        <Text className={styles.requestContainer_title} variant='headline-L'>
          {title}
        </Text>
        <Text className={styles.requestContainer_subTitle} variant='body-tight'>
          {subTitle}
        </Text>
        {isMobile && (
          <div className={styles.requestContainer_textWrapper_iconContainer}>{Icon}</div>
        )}
        <Button
          className={styles.requestContainer_button}
          data-testid={dataTestId.SEARCH_VIEW_BTN}
          onClick={onPress}
          variant='solid'
        >
          <Text variant='body-tight'>{buttonText}</Text>
        </Button>
      </div>
      {!isMobile && Icon}
    </div>
  );
};
