import { CSSObjectWithLabel } from 'react-select';

export const getStyles = () => {
  return {
    container: (base: CSSObjectWithLabel) => ({
      ...base,
      fontFamily: 'inherit',
      fontWeight: 400
    }),
    control: (base: CSSObjectWithLabel) => ({
      ...base,
      backgroudColor: 'var(--color-white)',
      borderStyle: 'solid',
      borderRadius: '0.5rem',
      boxShadow: 'none',
      font: 'inherit',
      height: '3.125rem',
      width: '100%',
      padding: '0 0.75rem',
      outline: '0',
      transition: 'border 0.2s',
      cursor: 'pointer',
      borderColor: 'var(--color-bg-divider)'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    valueContainer: (base: CSSObjectWithLabel) => ({
      ...base,
      color: 'var(--color-black)',
      padding: 0,
      font: 'inherit',
      fontSize: '1rem',
      textOverflow: 'ellipsis'
    }),
    placeholder: (base: CSSObjectWithLabel) => ({
      ...base,
      color: 'transparent',
      font: 'inherit',
      margin: 0,
      padding: 0
    }),
    option: (base: CSSObjectWithLabel) => ({
      ...base,
      cursor: 'pointer'
    }),
    input: (base: CSSObjectWithLabel) => ({
      ...base,
      margin: 0,
      padding: 0
    })
  };
};
