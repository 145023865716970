import React, { useEffect, useState } from 'react';
import {
  defaultActivePage,
  defaultItemOffset,
  defaultItemsPerPage,
  defaultPageSize,
  pageSizeOptions
} from '../lib/constants/defaultSettings';
import { PaginationInFrontView } from './PaginationInFrontView';
import { PaginationInFrontProps } from './types';

// Пагинация может принимать разные массивы данных, поэтому unknown
export const PaginationInFront = ({ items, setCurrentItems }: PaginationInFrontProps<unknown>) => {
  const [itemsPerPage, setItemsPerPage] = useState(defaultItemsPerPage);
  const [itemOffset, setItemOffset] = useState(defaultItemOffset);
  const [activePage, setActivePage] = useState(defaultActivePage);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  const pageCount = Math.ceil(items.length / itemsPerPage);
  const shouldDisablePagination = items.length <= pageSizeOptions[0];
  const shouldCutPageSizeOptions =
    items.length > pageSizeOptions[0] && items.length <= pageSizeOptions[1];

  const newPageSizeOptions = shouldCutPageSizeOptions
    ? pageSizeOptions.slice(0, 2)
    : pageSizeOptions;

  const onPageChange = (page: number) => {
    const newOffset = ((page - 1) * itemsPerPage) % items.length;

    setItemOffset(newOffset);
    setActivePage(page);
  };

  const onSizeChange = (pageSize: number) => {
    setPageSize(pageSize);
    setItemOffset(defaultItemOffset);
    setItemsPerPage(pageSize);
    setActivePage(defaultActivePage);
  };

  useEffect(() => {
    setActivePage(defaultActivePage);
    setPageSize(defaultPageSize);
  }, [items]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
  }, [itemOffset, itemsPerPage, items]);

  if (!shouldDisablePagination) {
    return (
      <PaginationInFrontView
        activePage={activePage}
        onPageChange={onPageChange}
        onSizeChange={onSizeChange}
        pageCount={pageCount}
        pageSize={pageSize}
        pageSizeOptions={newPageSizeOptions}
      />
    );
  }

  return null;
};
