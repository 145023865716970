import React from 'react';
import { Pagination } from '@uremont/erp-ui';

import { dataTestId } from 'src/shared/constants/dataTestId';

import { PaginationInFrontViewProps } from './types';
import styles from './styles.module.scss';

export const PaginationInFrontView = ({
  activePage,
  pageCount,
  onPageChange,
  onSizeChange,
  pageSize,
  pageSizeOptions
}: PaginationInFrontViewProps) => (
  <div className={styles.paginationWrapper} data-testid={dataTestId.PAGINATION_IN_FRONT_VIEW}>
    <Pagination
      activePage={activePage}
      className={styles.paginationWrapper_count}
      onPageChange={onPageChange}
      onSizeChange={onSizeChange}
      pageSize={pageSize}
      pageSizeOptions={pageSizeOptions}
      pagesCount={pageCount}
    />
  </div>
);
