import React from 'react';
import { Skeleton } from '@uremont/erp-ui';

import { BasketButtonView } from '@shared/components/BasketButton/ui/BasketButton';
import { IN_BASKET_TEXT } from '@shared/constants/ texts';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from '@shared/constants/fallbacks';
import { useBasket } from '@shared/hooks/use-basket';
import { useSearchDetailsStore } from '@shared/model/Store/SearchDetails';
import { useBasketStore } from '@shared/model/Store';
import { BasketButtonProps } from './types';

//Добавлено игнорирование, так как из за React.memo получаем ошибку eslint
// eslint-disable-next-line react/display-name
export const BasketButton = React.memo(
  ({
    id = EMPTY_STRING,
    btnText = IN_BASKET_TEXT,
    isLoading,
    articleProductUrl = EMPTY_STRING,
    className = EMPTY_STRING,
    isArticleScreen,
    rest
  }: BasketButtonProps) => {
    const [articleList, analoguesList] = useSearchDetailsStore(state => [
      state.articleList,
      state.analoguesList
    ]);

    const [cardCounts] = useBasketStore(state => [state.cardCounts]);

    const combinedArray = [...(articleList || EMPTY_ARRAY), ...(analoguesList || EMPTY_ARRAY)];

    const clickedItem = combinedArray.find(item => item?.partHash === id);

    const { handleAdd, handleBasketButton, handleDelete, countNotZero, isLoadingBasket } =
      useBasket(id, clickedItem ?? EMPTY_OBJECT);

    const cardCount = cardCounts[id] || 0;

    const isDisableAddMoreItem =
      (rest || rest === 0 ? rest <= cardCount : false) || isLoadingBasket;

    if (isLoading) {
      return <Skeleton height='48px' width='120px' />;
    }

    return (
      <BasketButtonView
        articleProductUrl={articleProductUrl}
        btnText={btnText}
        className={className}
        count={cardCount}
        countNotZero={countNotZero}
        handleAdd={handleAdd}
        handleBasketButton={handleBasketButton}
        handleDelete={handleDelete}
        id={id}
        isArticleScreen={isArticleScreen}
        isDisableAddMoreItem={isDisableAddMoreItem}
        isLoading={isLoading}
        isLoadingBasket={isLoadingBasket}
      />
    );
  }
);
