import React from 'react';
import { Button, Checkbox, IconXmark, Skeleton, Text } from '@uremont/erp-ui';

import { OrderPrice } from '@pages/PlacingAnOrder/lib/OrderPrice';
import { ProductCard } from '@shared/components/ProductCard';
import { dataTestId } from '@shared/constants/dataTestId';
import { getCheckboxName } from '@shared/functions/getCheckboxName/getCheckboxName';
import { Breadcrumbs } from '@shared/components/Breadcrumbs';
import { basketBreadcrumbsLinks } from '../lib/constants/basketBreadcrumbsLinks';

import { BasketViewProps } from './types';
import styles from './styles.module.scss';

export const BasketView = ({
  products,
  handleSelect,
  selectedAll,
  handleSelectAll,
  handleDelete,
  values,
  disabledBtnDelete,
  handleSubmit,
  isLoadingBasketData,
  goToMain,
  priceChangeText,
  outOfProductsText,
  changeCountProducts,
  outOfProducts,
  isEmptyBasket
}: BasketViewProps) => {
  return (
    <div className={styles.basket} data-testid={dataTestId.BASKET_VIEW}>
      <Breadcrumbs links={basketBreadcrumbsLinks} />
      <Text className={styles.basket_caption} variant='caption'>
        Корзина
      </Text>
      {isEmptyBasket && !isLoadingBasketData ? (
        <div className={styles.basket_emptyWrapper}>
          <Text className={styles.basket_empty} variant='caption'>
            Корзина пуста
          </Text>
          <Button className={styles.basket_empty_button} onClick={goToMain} variant='solid'>
            <Text className={styles.container_contentWrapper_button_text} variant='body-tight'>
              На главную
            </Text>
          </Button>
        </div>
      ) : (
        <>
          <div className={styles.basket_btnWrap}>
            {isLoadingBasketData ? (
              <>
                <Skeleton height='24px' width='120px' />
                <Skeleton height='24px' width='172px' />
              </>
            ) : (
              <>
                <Checkbox
                  boxAlign='start'
                  checked={selectedAll}
                  children={getCheckboxName('Выбрать все')}
                  className={styles.filter_brands_checkbox}
                  crossOrigin='anonymous'
                  data-testid={dataTestId.BASKET_VIEW_CHECKBOX}
                  onChange={handleSelectAll}
                />
                <Button
                  className={styles.basket_btnDelete}
                  disabled={!disabledBtnDelete}
                  icon={<IconXmark />}
                  iconAlign='start'
                  onClick={handleDelete}
                  size='md'
                  variant='control'
                >
                  <Text variant='body-tight'>Удалить выбранное</Text>
                </Button>
              </>
            )}
          </div>
          <div className={styles.basket_wrap}>
            <div className={styles.basket_list}>
              {products.map((item, index) => (
                <ProductCard
                  data-testid={`${dataTestId.FILTER_PAGE_LIST_PRODUCT}-${index}`}
                  handleSelect={() => item.part_hash && handleSelect(item.part_hash)}
                  horizontal={true}
                  isBasket={true}
                  isLoading={isLoadingBasketData}
                  item={item}
                  key={item.id}
                />
              ))}
              {changeCountProducts.length > 0 && !isLoadingBasketData && (
                <Text className={styles.basket_priceChange} variant='title'>
                  {priceChangeText}
                </Text>
              )}
              {changeCountProducts.map((item, index) => (
                <ProductCard
                  data-testid={`${dataTestId.FILTER_PAGE_LIST_PRODUCT}-${index}`}
                  handleSelect={() => item.part_hash && handleSelect(item.part_hash)}
                  horizontal={true}
                  isBasket={true}
                  isLastItem={index === changeCountProducts.length - 1}
                  isLoading={isLoadingBasketData}
                  item={item}
                  key={item.id}
                />
              ))}
              {outOfProducts.length > 0 && !isLoadingBasketData && (
                <>
                  <Text variant='title'>Эти товары закончились</Text>
                  <Text className={styles.basket_outOfProductsSubTitle}>{outOfProductsText}</Text>
                </>
              )}
              {outOfProducts.map((item, index) => (
                <ProductCard
                  data-testid={`${dataTestId.FILTER_PAGE_LIST_PRODUCT}-${index}`}
                  handleSelect={() => item.part_hash && handleSelect(item.part_hash)}
                  horizontal={true}
                  isBasket={true}
                  isLastItem={index === outOfProducts.length - 1}
                  isLoading={isLoadingBasketData}
                  isOutOfStockProducts
                  isShowCheckbox={false}
                  item={item}
                  key={item.id}
                />
              ))}
            </div>
            <div className={styles.basket_right}>
              <OrderPrice
                btnText='Перейти к оформлению'
                discount={values.discount}
                handleSubmit={handleSubmit}
                isBasketScreen
                price={values.price}
                quantity={values.quantity}
              />
            </div>
          </div>
          {/*TODO: Раскомментировать, когда будет необходимо добавить логику*/}
          {/*<Recommendations*/}
          {/*  caption='Рекомендуем также'*/}
          {/*  isLoading={false}*/}
          {/*  products={recommendedBasketProducts}*/}
          {/*/>*/}
        </>
      )}
    </div>
  );
};
