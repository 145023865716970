import { fetchData } from 'src/shared/api/apiConfig';
import { CollectionDefaultCityData } from 'src/shared/model/Store/Country/types';

export const getPopularCities = async (
  countryId: number
): Promise<CollectionDefaultCityData[] | void> =>
  fetchData('getPopularCities', {
    country_id: countryId
  });

export const getSearchCities = async (
  countryId: number,
  query: string
): Promise<CollectionDefaultCityData[] | void> =>
  fetchData('getSearchCities', {
    country_id: countryId,
    query
  });
