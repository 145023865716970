// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hysi96S28IjNBKHzht0p{display:flex;flex-direction:column;gap:4px}.SrymvROWYjU6o5cvNo9O{color:var(--color-bg);margin-bottom:16px}.DMnpmvdGqlyqsRPfLJIC{color:var(--color-bg)}.DMnpmvdGqlyqsRPfLJIC:hover{color:var(--color-bg);opacity:.8}`, "",{"version":3,"sources":["webpack://./src/features/AppLayout/components/Footer/lib/NavigationMenu/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,qBAAA,CACA,OAAA,CAGF,sBACE,qBAAA,CACA,kBAAA,CAGF,sBACE,qBAAA,CAEA,4BACE,qBAAA,CACA,UAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  gap: 4px;\n}\n\n.title {\n  color: var(--color-bg);\n  margin-bottom: 16px;\n}\n\n.linkText {\n  color: var(--color-bg);\n\n  &:hover {\n    color: var(--color-bg);\n    opacity: 0.8;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `hysi96S28IjNBKHzht0p`,
	"title": `SrymvROWYjU6o5cvNo9O`,
	"linkText": `DMnpmvdGqlyqsRPfLJIC`
};
export default ___CSS_LOADER_EXPORT___;
