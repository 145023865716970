import { Link, useNavigate } from 'react-router-dom';
import { Skeleton } from '@uremont/erp-ui';

import { getLinkRoute } from '@shared/functions/getLinkRoute/getLinkRoute';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { NoInfoView } from '@shared/components/NoInfo/ui/NoInfoView';
import { pathConfig } from '@shared/constants/pathConfig';
import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholder.png';

import { CardImageProps } from './types';
import styles from './styles.module.scss';

export const CardImage = ({ links, isLoading }: CardImageProps) => {
  const navigate = useNavigate();

  if (isLoading) {
    return <Skeleton height='264px' width='264px' />;
  }

  if (!Array.isArray(links) && links?.hasParts) {
    return (
      <Link key={links.name} to={getLinkRoute(links.name) || EMPTY_STRING}>
        <img alt={links.name} src={links.img || picturePlaceholder} width='400px' />
      </Link>
    );
  }

  return (
    <div className={styles.noInfoContainer}>
      <NoInfoView isSearchByDetails onClick={() => navigate(pathConfig.MainPath)} />
    </div>
  );
};
