import { Text } from '@uremont/erp-ui';

import { InsuranceLinkProps } from 'src/features/AppLayout/components/Footer/lib/InsuranceLink/types';
import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';

export const InsuranceLink = ({ insuranceCompaniesLinks }: InsuranceLinkProps) => {
  return (
    <div className={styles.insuranceLinksContainer} data-testid={dataTestId.INSURANCE_LINK}>
      {insuranceCompaniesLinks.map((item, index) => (
        <div className={styles.insuranceLinkWrapper} key={index}>
          <Text
            as='a'
            className={`${styles.insuranceLinkText} ${styles.contractText}`}
            href={item.link}
            key={item.title}
            rel='noreferrer'
            target='_blank'
            variant='overline'
          >
            {item.title.toUpperCase()}
          </Text>
          <Text className={styles.contractText} variant='overline'>
            ∏{item.contract}
          </Text>
        </div>
      ))}
    </div>
  );
};
