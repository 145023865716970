import React from 'react';

import { useBasketStore } from '@shared/model/Store';
import { roundedNumber } from '@shared/functions/formattedNumber/roundedNumber';
import { useOrders } from '@shared/hooks/use-orders';
import { OrderPriceView } from './OrderPriceView';

import { OrderPriceProps } from './types';

export const OrderPrice = ({
  btnText,
  quantity,
  price,
  discount,
  handleSubmit,
  isSelectedAddress,
  isBasketScreen = false
}: OrderPriceProps) => {
  const total = discount ? price - discount : price;
  const roundedTotal = roundedNumber(total);
  const roundedPrice = roundedNumber(price);
  const [isLoadingItems, isLoadingCheck] = useBasketStore(state => [
    state.isLoadingItems,
    state.isLoadingCheck
  ]);
  const { isLoading } = useOrders();
  const isMinCount = total < 1000;
  const isDisabledSubmit = (!isSelectedAddress && !isBasketScreen) || isMinCount;

  return (
    <OrderPriceView
      btnText={btnText}
      discount={discount}
      handleSubmit={handleSubmit}
      isDisabledSubmit={isDisabledSubmit}
      isLoading={isLoadingItems || isLoading || isLoadingCheck}
      isMinCount={isMinCount}
      price={roundedPrice}
      quantity={quantity}
      total={roundedTotal}
    />
  );
};
