export const additionalInfoLinks = [
  {
    link: 'https://policies.google.com/privacy',
    title: ' Политика конфиденциальности'
  },
  {
    link: 'https://policies.google.com/terms',
    title: 'Условия пользования'
  }
];
