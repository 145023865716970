import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { pathConfig } from 'src/shared/constants/pathConfig';
import { PaginationData, SearchDetailsStore } from './types';
import {
  getArticleAnalogues,
  getArticleOnlyAnalogues,
  getArticleTips,
  getSearchByGRZ,
  getSearchByArticle,
  getSearchByArticleOnly,
  getSearchByVIN
} from './service';

export const useSearchDetailsStore = create(
  devtools(
    persist<SearchDetailsStore>(
      set => {
        return {
          searchByGRZDetailsData: EMPTY_ARRAY,
          searchByVINDetailsData: EMPTY_ARRAY,
          searchByArticleDetailsData: null,
          articleList: EMPTY_ARRAY,
          articleWithPriceList: EMPTY_ARRAY,
          analoguesList: EMPTY_ARRAY,
          selectedArticle: null,
          articleTips: EMPTY_ARRAY,
          articleAnalogues: null,
          tip: null,
          isGRZError: false,
          isLoadingSearchByArticleDetailsData: false,
          isLoadingArticle: false,
          isLoading: false,
          articlePagination: EMPTY_OBJECT as PaginationData,
          analoguePagination: EMPTY_OBJECT as PaginationData,
          brand: EMPTY_STRING,
          article: EMPTY_STRING,
          originalTotalItems: null,
          analogueTotalItems: null,
          getSearchDetailsByGRZData: async (grz, navigateToPage) => {
            await executeRequest(
              () => getSearchByGRZ(grz),
              isLoading => set({ isLoading }),
              response => {
                const setGRZError = useSearchDetailsStore.getState().setIsGRZError;

                set({ searchByVINDetailsData: response ?? null });
                response ? navigateToPage(pathConfig.SearchByGrz) : setGRZError(true);
              }
            );
          },

          getSearchDetailsByVINData: async (vin, navigateToPage) => {
            await executeRequest(
              () => getSearchByVIN(vin),
              isLoading => set({ isLoading }),
              response => {
                set({ searchByVINDetailsData: response ?? null });

                if (response) {
                  navigateToPage(pathConfig.SearchByVin);
                }
              }
            );
          },

          getSearchDetailsByArticleData: async (article, brand, page, perPage, navigateToPage) => {
            set({ article, brand });
            navigateToPage && navigateToPage(pathConfig.SearchByArticlePath);

            await executeRequest(
              () => getSearchByArticle(article, brand, page, perPage),
              isLoading => set({ isLoadingSearchByArticleDetailsData: isLoading }),
              response => {
                set({
                  searchByArticleDetailsData: response ? response.collection : null,
                  articleList: response ? response.collection : EMPTY_ARRAY,
                  articleWithPriceList: response ? response.collection : EMPTY_ARRAY,
                  articlePagination: response
                    ? response.pagination
                    : (EMPTY_OBJECT as PaginationData),
                  originalTotalItems: response ? response.pagination?.total : null
                });
              }
            );
          },

          getSearchDetailsByArticleOnlyData: async (
            article,
            brand,
            page,
            perPage,
            navigateToPage
          ) => {
            set({ article, brand });
            navigateToPage && navigateToPage(pathConfig.SearchByArticlePath);

            await executeRequest(
              () => getSearchByArticleOnly(article, brand, page, perPage),
              isLoading => set({ isLoadingSearchByArticleDetailsData: isLoading }),
              response => {
                set({
                  searchByArticleDetailsData: response ? response.collection : null,
                  articlePagination: response
                    ? response.pagination
                    : (EMPTY_OBJECT as PaginationData),
                  originalTotalItems: response ? response.pagination?.total : null
                });
              }
            );
          },

          getArticleAnaloguesData: async (article, brand, page, perPage) => {
            await executeRequest(
              () => getArticleAnalogues(article, brand, page, perPage),
              isLoading => set({ isLoading }),
              response => {
                set({
                  articleAnalogues: response ? response.collection : null,
                  analoguePagination: response
                    ? response.pagination
                    : (EMPTY_OBJECT as PaginationData),
                  analogueTotalItems: response ? response.pagination?.total : null
                });
              }
            );
          },

          getArticleOnlyAnaloguesData: async (article, brand, page, perPage) => {
            await executeRequest(
              () => getArticleOnlyAnalogues(article, brand, page, perPage),
              isLoading => set({ isLoading }),
              response => {
                set({
                  articleAnalogues: response ? response.collection : null,
                  analoguePagination: response
                    ? response.pagination
                    : (EMPTY_OBJECT as PaginationData),
                  analogueTotalItems: response ? response.pagination?.total : null
                });
              }
            );
          },

          setArticleList: article => set({ articleList: article }),

          setArticleWithPriceList: article => set({ articleWithPriceList: article }),

          setAnaloguesList: analogues => set({ analoguesList: analogues }),

          setSelectedArticle: selectedItem => set({ selectedArticle: selectedItem }),

          getArticleTips: async article => {
            await executeRequest(
              () => getArticleTips(article),
              isLoading => set({ isLoadingArticle: isLoading }),
              response => set({ articleTips: response ?? EMPTY_ARRAY })
            );
          },

          setArticleTip: tip => {
            set({
              tip
            });
          },

          setIsGRZError: isError => {
            set({
              isGRZError: isError
            });
          },

          resetSearchDetailsState: () => {
            set({
              searchByGRZDetailsData: EMPTY_ARRAY,
              searchByVINDetailsData: EMPTY_ARRAY,
              searchByArticleDetailsData: null,
              articleList: EMPTY_ARRAY,
              analoguesList: EMPTY_ARRAY,
              articleTips: EMPTY_ARRAY,
              articleAnalogues: null,
              tip: null,
              isLoadingSearchByArticleDetailsData: false,
              isLoadingArticle: false,
              isLoading: false,
              analoguePagination: EMPTY_OBJECT as PaginationData,
              articlePagination: EMPTY_OBJECT as PaginationData,
              originalTotalItems: null,
              analogueTotalItems: null,
              brand: EMPTY_STRING,
              article: EMPTY_STRING
            });
          }
        };
      },
      {
        name: 'searchDetails-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
