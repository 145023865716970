import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';

import { useBasketStore } from '@shared/model/Store';
import { useOrders } from '@shared/hooks/use-orders';
import { EMPTY_STRING, noop } from '@shared/constants/fallbacks';
import { pathConfig } from '@shared/constants/pathConfig';
import { defaultValuesPlacingAnOrderForm } from '../lib/constants/constants';
import { placingAnOrderFormSchema } from '../lib/constants/schema';
import { PlacingAnOrderView } from './PlacingAnOrderView';

import { PlacingAnOrderProps } from './types';

export const PlacingAnOrder = () => {
  const [initialValues, setInitialValues] = useState<PlacingAnOrderProps>(
    defaultValuesPlacingAnOrderForm
  );

  const [basketId, checkBasketResponse] = useBasketStore(state => [
    state.basketId,
    state.checkBasketResponse
  ]);

  const { placeAnOrder, pointsData, orderValues, isLoading, getOrderInformationPlacinganorder } =
    useOrders();

  const [slides, setSlides] = useState(pointsData);
  const [currentPoint, setCurrentPoint] = useState<number[]>(pointsData[0]?.geometry);

  const sliderRef = useRef<Slider>(null);

  const navigate = useNavigate();

  const handleClickPoint = (indexItem: number) => {
    const newSlides = slides.map((item, index) => {
      if (index === indexItem) {
        setCurrentPoint(item.geometry);

        return {
          ...item,
          balloonOpen: true
        };
      }

      return {
        ...item,
        balloonOpen: false
      };
    });

    sliderRef?.current?.slickGoTo && sliderRef.current.slickGoTo(indexItem);

    setSlides(newSlides);
  };

  const pvzWithBalloonOpen = slides.find(pvz => pvz.balloonOpen);

  const selectedAddressId = pvzWithBalloonOpen?.id.toString() ?? EMPTY_STRING;

  const selectedAddress = pvzWithBalloonOpen?.address.toString() ?? EMPTY_STRING;

  const orderPostData = {
    basketId: basketId,
    shipmentAddress: selectedAddressId,
    comment: EMPTY_STRING
  };

  const formik = useFormik<PlacingAnOrderProps>({
    initialValues,
    validationSchema: placingAnOrderFormSchema,
    onSubmit: (values: PlacingAnOrderProps) => {
      setInitialValues(values);
    }
  });

  const handleSubmit = () => placeAnOrder(orderPostData, basketId, navigate);

  const { errors, values, handleChange } = formik;

  useEffect(() => {
    getOrderInformationPlacinganorder();
    !checkBasketResponse?.basket_parts.length && navigate(pathConfig.HistoryOrdersPath);
  }, [checkBasketResponse]);

  useEffect(() => {
    // unload отключает  механизм кеширования страниц в браузере - Back/Forward Cache, для корректного редиректа при нажатии в браузере кнопки "Назад"
    // eslint-disable-next-line no-empty-function, @typescript-eslint/no-empty-function
    window.addEventListener('unload', noop);
  }, []);

  return (
    <PlacingAnOrderView
      address={selectedAddress}
      currentPoint={currentPoint}
      errors={errors}
      handleChange={handleChange}
      handleClickPoint={handleClickPoint}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
      orderValues={orderValues}
      sliderRef={sliderRef}
      slides={slides}
      values={values}
    />
  );
};
