import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { executeRequest } from '@shared/functions/executeRequest/executeRequest';
import { EMPTY_ARRAY, EMPTY_OBJECT } from '@src/shared/constants/fallbacks';
import { defaultYears } from '@src/pages/HistoryOrdersPage/lib/consts';
import { PaginationData } from '../SearchDetails/types';
import { getHistoryOrders } from './service';
import { HistoryOrdersFilter, HistoryOrdersStore } from './types';
import {
  getYearsOrders,
  responseHistoryOrderToOrderDetail,
  responseHistoryOrdersToData
} from './functions';

export const useHistoryOrdersStore = create(
  devtools(
    persist<HistoryOrdersStore>(
      set => ({
        orders: EMPTY_ARRAY,
        order: null,
        isLoading: false,
        years: EMPTY_ARRAY,
        pagination: EMPTY_OBJECT as PaginationData,

        getHistoryOrdersData: async (filter: HistoryOrdersFilter) => {
          await executeRequest(
            () => getHistoryOrders(filter),
            isLoading => set({ isLoading }),
            response => {
              if (!response) {
                set({
                  orders: EMPTY_ARRAY,
                  order: null,
                  years: EMPTY_ARRAY,
                  pagination: EMPTY_OBJECT as PaginationData
                });

                return;
              }
              const years = useHistoryOrdersStore.getState().years;
              const orders = useHistoryOrdersStore.getState().orders;

              const newOrders = responseHistoryOrdersToData(response.collection);
              const yearsHistory = getYearsOrders(response.collection, years); // Список годов
              const newOrder = response.collection.filter(item => item.id === filter.order_id);
              const orderItem = responseHistoryOrderToOrderDetail(newOrder[0]);
              const currentOrders = filter.page !== 1 ? [...orders, ...newOrders] : newOrders;

              set({
                orders: currentOrders,
                order: orderItem,
                years: [defaultYears, ...yearsHistory],
                pagination: response.pagination
              });
            }
          );
        }
      }),
      {
        name: 'historyOrders-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
