import React from 'react';
import { convertHoursToDays } from '@utils/convertHoursToDays';
import { formattedDeliveryDays } from '@shared/functions/formattedText/formattedDeliveryDays';
import { TextUnderPriceView } from './TextUnderPriceView';
import { TextUnderPriceProps } from './types';

export const TextUnderPrice = ({ rest, days, isLoading }: TextUnderPriceProps) => {
  const daysText = formattedDeliveryDays(convertHoursToDays(days ?? 0));

  return <TextUnderPriceView days={daysText} isLoading={isLoading} rest={rest} />;
};
