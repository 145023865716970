import { HOME_BREADCRUMBS, OIL_BREADCRUMBS } from '@shared/constants/breadcrumbsText';
import { pathConfig } from '@shared/constants/pathConfig';

export const breadcrumbsLinks = [
  {
    active: false,
    href: pathConfig.MainPath,
    label: HOME_BREADCRUMBS
  },
  {
    active: true,
    label: OIL_BREADCRUMBS
  }
];
