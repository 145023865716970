import React from 'react';
import { useScreenSizeBreakpoints } from 'src/shared/hooks/use-screen-size-breakpoints';
import { ReactComponent as CarIcon } from 'src/assets/CarIcon.svg';
import { ReactComponent as PartCarIcon } from 'src/assets/PartCarIcon.svg';

export const Icon = () => {
  const { isTablet } = useScreenSizeBreakpoints();

  return isTablet ? <PartCarIcon /> : <CarIcon />;
};
