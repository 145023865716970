import React from 'react';
import { Button, IconXmark, Input, Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { RenderBrands } from '@shared/components/FilterComponent/lib/Filter/lib/RenderBrands/RenderBrands';

import { FilterViewProps } from './types';
import styles from './styles.module.scss';

export const FilterView = ({
  // TODO: Пропсы для Срок доставки пока скрыты, так как в будущем может потребуется вернуть этот функционал
  // deliveries,
  // selectedDelivery,
  // handleSelectedDelivery,
  // day,
  // handleDay,
  brands,
  isShowAllBrands,
  handleIsShowAllBrands,
  handleBrands,
  availability,
  handleAvailability,
  handleSearch,
  dirty,
  showBtnShowAll,
  showFilter,
  handleShowFilter,
  isLoading
}: FilterViewProps) => {
  return (
    <div
      className={cx(styles.filter, showFilter && styles.filter_show)}
      data-testid={dataTestId.FILTER_VIEW}
    >
      <div>
        <div className={styles.filter_top}>
          <Text variant='headline-L'>Фильтр</Text>
          <Button onClick={handleShowFilter} variant='control'>
            <IconXmark />
          </Button>
        </div>
        <Text className={styles.filter_caption} variant='headline-H3'>
          {isLoading ? <Skeleton height='24px' width='140px' /> : 'Бренды'}
        </Text>
        <div className={cx(styles.filter_brands, isShowAllBrands && styles.filter_brands_showAll)}>
          {RenderBrands({ brands, isLoading, handleBrands })}
          {isLoading ? (
            <Skeleton height='20px' width='25px' />
          ) : (
            !showBtnShowAll && (
              <Button
                className={styles.filter_brands_btnShowAll}
                data-testid={dataTestId.FILTER_PAGE_FILTER_BRANDS_BTN_SHOW_ALL}
                onClick={handleIsShowAllBrands}
                variant='control'
              >
                <Text
                  className={styles.filter_brands_btnShowAll_text}
                  color='brand'
                  variant='body-tight'
                >
                  {isShowAllBrands ? 'Скрыть' : 'Все'}
                </Text>
              </Button>
            )
          )}
        </div>
        {/* TODO: Срок доставки пока скрыт, так как в будущем может потребуется вернуть этот функционал */}
        {/* <Text className={styles.filter_caption} variant='headline-H3'>
          {isLoading ? <Skeleton height='24px' width='140px' /> : 'Срок доставки'}
        </Text>
        <div className={styles.filter_delivery_wrap}>
          <div className={styles.filter_delivery}>
            {RenderDeliveries({
              deliveries,
              isLoading,
              selectedDelivery,
              handleSelectedDelivery
            })}
            {isLoading ? (
              <Skeleton className={styles.filter_availability} height='40px' width='200px' />
            ) : (
              <Input
                className={styles.filter_delivery_input}
                data-testid={dataTestId.FILTER_PAGE_FILTER_DAY}
                name='day'
                onChange={e => handleDay(e.target.value)}
                placeholder='до 24 дней'
                type='string'
                value={day || EMPTY_STRING}
              />
            )}
          </div>
        </div> */}
        <Text className={styles.filter_caption} variant='headline-H3'>
          {isLoading ? <Skeleton height='24px' width='140px' /> : 'Наличие'}
        </Text>
        {isLoading ? (
          <Skeleton className={styles.filter_availability} height='40px' width='200px' />
        ) : (
          <Input
            className={styles.filter_availability}
            data-testid={dataTestId.FILTER_PAGE_FILTER_AVAILABILITY}
            name='availability'
            onChange={e => handleAvailability(e.target.value)}
            placeholder='от 124'
            type='string'
            value={availability || EMPTY_STRING}
          />
        )}
      </div>
      {isLoading ? (
        <Skeleton className={styles.filter_availability} height='40px' width='200px' />
      ) : (
        <Button
          className={styles.filter_searchBtn}
          data-testid={dataTestId.FILTER_PAGE_FILTER_BTN_SEARCH}
          disabled={!dirty}
          onClick={handleSearch}
          size='md'
          variant='solid'
        >
          <Text className={styles.filter_searchBtn_text} variant='body-tight'>
            Поиск
          </Text>
        </Button>
      )}
    </div>
  );
};
