import { dataTestId } from '@shared/constants/dataTestId';
import {
  mailRef,
  phoneRef,
  whatsappRef
} from '@features/AppLayout/components/Footer/lib/utils/refs';
import styles from '@features/AppLayout/components/Footer/ui/styles.module.scss';
import cx from 'classnames';
import { ReactComponent as AppStoreButtonIcon } from '@assets/AppStoreButton.svg';
import { ReactComponent as FooterMailIcon } from '@assets/FooterMailIcon.svg';
import { ReactComponent as FooterPhoneIcon } from '@assets/FooterPhoneIcon.svg';
import { ReactComponent as FooterWhatsappIcon } from '@assets/FooterWhatsappIcon.svg';
import { ReactComponent as GooglePlayButtonIcon } from '@assets/GooglePlayButton.svg';
import { ReactComponent as VKIcon } from '@assets/SocialVK.svg';
import { ReactComponent as YoutubeIcon } from '@assets/SocialYoutube.svg';
import { footerSocialContent } from '@features/AppLayout/components/Footer/lib/constants/links/groupDescriptionsOfLinks';
import { uremontLinks } from '@shared/constants/links';

export const socialLinks = [
  {
    Icon: FooterPhoneIcon,
    dataTestId: dataTestId.FOOTER_VIEW_PHONE,
    href: phoneRef,
    iconClassName: styles.footerView_link_socialIcon,
    itemClassName: styles.footerView_link_socialItem,
    text: footerSocialContent.phone
  },
  {
    Icon: FooterWhatsappIcon,
    dataTestId: dataTestId.FOOTER_VIEW_WHATSAPP_PHONE,
    href: whatsappRef,
    iconClassName: styles.footerView_link_socialIcon,
    text: footerSocialContent.whatsappPhone,
    itemClassName: cx(styles.footerView_link_socialItem, styles.footerView_link_socialItem_whatsapp)
  },
  {
    Icon: FooterMailIcon,
    dataTestId: dataTestId.FOOTER_VIEW_SOCIAL,
    href: mailRef,
    iconClassName: styles.footerView_link_socialIcon,
    itemClassName: styles.footerView_link_socialItem,
    text: footerSocialContent.social
  }
];

export const socialMediaLinks = [
  {
    Icon: VKIcon,
    dataTestId: dataTestId.FOOTER_VIEW_VK,
    href: uremontLinks.vk,
    isNewWindow: true,
    itemClassName: styles.footerView_link_item
  },
  {
    Icon: YoutubeIcon,
    dataTestId: dataTestId.FOOTER_VIEW_YOUTUBE,
    href: uremontLinks.youtube,
    isNewWindow: true,
    itemClassName: styles.footerView_link_item
  }
];

export const storeLinks = [
  {
    Icon: AppStoreButtonIcon,
    dataTestId: dataTestId.FOOTER_VIEW_APPSTORE,
    href: uremontLinks.appStore,
    isNewWindow: true
  },
  {
    Icon: GooglePlayButtonIcon,
    dataTestId: dataTestId.FOOTER_VIEW_GOOGLE_PLAY,
    href: uremontLinks.googlePlay,
    isNewWindow: true
  }
];
