import { IconChevronDown } from '@uremont/erp-ui';
import React from 'react';

import { ReactComponent as UpDownIcon } from 'src/assets/UpDownIcon.svg';

export const getIconForHeader = (header: string) => {
  if (header.toLowerCase() === 'год выпуска') return <UpDownIcon />;

  if (header.toLowerCase() === 'тип двигателя')
    return <IconChevronDown color='secondary' size='md' />;

  return null;
};
