import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { CarGroupParts } from '@shared/model/Store/CarCatalog/types';
import { breadCrumbsForCatalogCategory } from '../lib/constants/breadCrumbsForCatalogCategory';
import { CatalogCategoryView } from './CatalogCategoryView';

export const CatalogCategory = () => {
  const { category = EMPTY_STRING } = useParams();

  const {
    carGroupParts,
    isLoadingCarGroupParts,
    transformedSelectedModelData,
    getGroupPartsDetails,
    getSubGroupPartsDetails,
    nestedGroup
  } = useCarCatalog();

  const findBodyPart = (bodyParts: CarGroupParts[], category: string): CarGroupParts | null => {
    const findPart = (parts: CarGroupParts[]): CarGroupParts | null => {
      for (const part of parts) {
        if (part.id === category || part.parentId === category) {
          return part;
        } else if (part.subgroups && part.subgroups.length > 0) {
          const foundPart = findPart(part.subgroups);
          if (foundPart) {
            return foundPart;
          }
        }
      }

      return null;
    };

    return findPart(bodyParts);
  };

  const selectedPart = findBodyPart([...carGroupParts, ...nestedGroup], category);

  const parentName =
    (new URLSearchParams(location.search).get('parentName') ?? selectedPart?.name) || EMPTY_STRING;

  useEffect(() => {
    getGroupPartsDetails(category);
  }, [category]);

  return (
    <CatalogCategoryView
      breadcrumbs={breadCrumbsForCatalogCategory}
      getSubGroupPartsDetails={getSubGroupPartsDetails}
      isLoading={isLoadingCarGroupParts}
      nestedGroup={nestedGroup}
      selectedPart={selectedPart}
      title={parentName}
      transformedData={transformedSelectedModelData}
    />
  );
};
