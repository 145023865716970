// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.snaoXfkltG7IWzqKND0p{font-size:34px;margin-block:32px}`, "",{"version":3,"sources":["webpack://./src/pages/FilterPage/ui/styles.module.scss"],"names":[],"mappings":"AACE,sBACE,cAAA,CACA,iBAAA","sourcesContent":[".filterPage {\n  &_text {\n    font-size: 34px;\n    margin-block: 32px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"filterPage_text": `snaoXfkltG7IWzqKND0p`
};
export default ___CSS_LOADER_EXPORT___;
