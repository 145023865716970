import React from 'react';
import { PlacingAnOrderSuccessView } from '@pages/PlacingAnOrderSuccess/ui/PlacingAnOrderSuccessView';
import { useOrders } from '@shared/hooks/use-orders';
import { pathConfig } from '@shared/constants/pathConfig';
import { useNavigate } from 'react-router-dom';

export const PlacingAnOrderSuccess = () => {
  const { resetOrder } = useOrders();

  const navigate = useNavigate();

  const noInfoClick = () => {
    navigate(pathConfig.MainPath);
    resetOrder();
  };

  return <PlacingAnOrderSuccessView noInfoClick={noInfoClick} />;
};
