import React, { useState } from 'react';

import { EMPTY_STRING } from '@shared/constants/fallbacks';

import {
  checkingCorrectNumberAuto,
  deleteValue,
  removeChars,
  removeSpacesAndPipe,
  changingTheLineToTheCarNumber
} from '../lib/functions/textFormatting';

import { GRZInputView } from './GRZInputView';
import { GRZInputProps } from './types';

export const GRZInput = ({ action, onChange, handleButtonClick, placeholder }: GRZInputProps) => {
  const [grzValue, setGrzValue] = useState(EMPTY_STRING);
  const [grzPlaceholder, setGrzPlaceholder] = useState(placeholder);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const formattedText = removeSpacesAndPipe(e.target.value);
    const isValid = checkingCorrectNumberAuto(formattedText);
    let valueLength = 0;

    if (!isValid) {
      return;
    }

    if ((e.nativeEvent as InputEvent).inputType === 'deleteContentBackward') {
      e.target.value = deleteValue(e.target.value, grzValue);
      setGrzValue(e.target.value);

      valueLength = e.target.value.length;
    } else {
      const newValue = changingTheLineToTheCarNumber(formattedText).toUpperCase();

      setGrzValue(newValue);

      valueLength = newValue.length;
    }

    e.target.value = formattedText.toUpperCase();
    onChange && onChange(e);

    const newGRZPlaceholder = removeChars({
      str: placeholder,
      num: valueLength
    }).toUpperCase();

    setGrzPlaceholder(newGRZPlaceholder);
  };

  return (
    <GRZInputView
      action={action}
      handleButtonClick={handleButtonClick}
      onChange={handleSearchChange}
      placeholder={grzPlaceholder}
      value={grzValue}
    />
  );
};
