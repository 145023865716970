import { CHOICE_AUTO_BREADCRUMBS, HOME_BREADCRUMBS } from '@shared/constants/breadcrumbsText';
import { pathConfig } from '@shared/constants/pathConfig';

export const breadcrumbsForSearchLinks = [
  {
    active: false,
    href: pathConfig.MainPath,
    label: HOME_BREADCRUMBS
  },
  {
    active: true,
    label: CHOICE_AUTO_BREADCRUMBS
  }
];
