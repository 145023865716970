import { jwtDecode } from 'jwt-decode';

export const decodeJwtToken = (token: string) => {
  try {
    const decoded = jwtDecode(token);

    if (decoded.exp) {
      return decoded.exp * 1000;
    }
  } catch (e) {
    return 0;
  }

  return 0;
};
