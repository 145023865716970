import { fetchData } from 'src/shared/api/apiConfig';
import {
  CarBrandsData,
  CarGenerationsType,
  CarGroupParts,
  CarModelsData,
  SubgroupCatalogsData
} from './types';

export const getCarBrands = async (): Promise<CarBrandsData[] | void> => fetchData('getCarBrands');

export const getCarModels = async (brand: string): Promise<CarModelsData[] | void> =>
  fetchData('getCarModels', { brand });

export const getCarGenerations = async (
  brand: string,
  modelId: string
): Promise<CarGenerationsType | void> => fetchData('getCarGenerations', { brand, modelId });

export const getCarGroupParts = async (
  brand: string,
  carId: string,
  groupId?: string
): Promise<CarGroupParts[] | void> => fetchData('getCarGroupParts', { brand, carId, groupId });

export const getCarSubGroupParts = async (
  brand: string,
  carId: string,
  groupId: string
): Promise<SubgroupCatalogsData | void> =>
  fetchData('getCarSubGroupParts', { brand, carId, groupId });
