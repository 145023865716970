export enum apiUrls {
  COUNTRIES = '/countries/get-list-active',
  CURRENT_COUNTRY = '/countries/get-default',
  POPULAR_CITIES = '/cities/get-popular-list',
  SEARCH_CITIES = '/cities/search',
  AUTH = '/dashboard',
  SEARCH_BY_GRZ = '/search/grz',
  SEARCH_BY_VIN = '/search/vin',
  SEARCH_BY_ARTICLE = '/search/article',
  SEARCH_BY_ARTICLE_ONLY = '/search/article_only',
  GET_ARTICLE_ANALOGUES = '/search/analogues',
  GET_ARTICLE_ONLY_ANALOGUES = '/search/article_only_analogues',
  SEARCH_ARTICLE_TIPS = '/search/tips',
  CAR_BRANDS = '/car-catalog/brands',
  CAR_MODELS = '/car-catalog/models',
  CAR_GENERATIONS = '/car-catalog/generations',
  COLLABORATE = '/partners',
  CAR_GROUP_PARTS = '/car-catalog/groups',
  CAR_SUBGROUP_PARTS = '/car-catalog/autoparts',
  GET_BASKET_ID = '/basket/id',
  GET_BASKET = '/basket/{basketId}/get',
  ADD_TO_BASKET = '/basket/{basketId}/add',
  REMOVE_FROM_BASKET = '/basket/{basketId}/remove',
  CHECK_BASKET_ID = '/basket/check',
  TIRE_SELECTION = '/tire-selection', // TODO: Изменить, когда появится соответствующий метод на беке
  RECOMMENDED_PURCHASE = '/recommended-purchase', // TODO: Изменить, когда появится соответствующий метод на беке
  RECOMMENDED_BY_ARTICLE_PRODUCTS = '/advices',
  RECOMMENDED_BY_BASKET_PRODUCTS = '/advices/batch',
  PRODUCT_INFO = '/articles/info',
  ORDER_INFO = '/order/info',
  PLACE_AN_ORDER = 'order/{basketId}',
  FAQ = '/qna-publication/get10publications',
  BANNERS = '/banner/list',
  HISTORY_ORDERS = '/order/history',
  SEARCH_REQUEST = '/search-request',
  PROFILE_INFO = '/profile/info',
  INSURANCE_FORM = '/insurance-form',
  REFRESH_TOKEN = '/refresh-token',
  RECREATE_BASKET_BY_ORDER = '/basket/recreate',
  CLEAR_BASKET = '/basket/clear'
}
