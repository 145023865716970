import { EMPTY_STRING } from 'src/shared/constants/fallbacks';

export const formattedPhone = (phoneNumber: string): string => {
  const cleaned = phoneNumber ? phoneNumber.replace(/\D/g, '') : EMPTY_STRING;
  const match = cleaned?.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/) ?? EMPTY_STRING;

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}-${match[5]}`;
  }

  return phoneNumber;
};
