import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { CardContent } from '@shared/components/CardContent/CardContent';
import { CardLink } from '@shared/components/RenderCard/lib/CardLink/CardLink';
import { CardImage } from '@shared/components/RenderCard/lib/CardImage/CardImage';

import { RenderCardTypes } from './types';
import styles from './styles.module.scss';

export const RenderCardView = ({
  title,
  links,
  selectedTitle = false,
  isLoading,
  isLinks,
  cardContentClick,
  linkTo
}: RenderCardTypes) => {
  if (!selectedTitle) {
    return (
      <div className={styles.cardWrapper} key={title}>
        <Text className={styles.cardWrapper_titleText} variant='sub-headline'>
          {isLoading ? <Skeleton height='28px' width='100px' /> : title}
        </Text>
        <div className={styles.cardWrapper_itemWrapper}>
          {isLinks && Array.isArray(links) ? (
            links.map((link, index) => (
              <CardLink
                hasParts={link.hasParts}
                id={link.id}
                isLoading={isLoading}
                key={index}
                linkTo={linkTo}
                name={link.name}
              />
            ))
          ) : (
            <CardImage isLoading={isLoading} links={links} />
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      {isLinks && Array.isArray(links) ? (
        links.map(({ img, name = EMPTY_STRING, subgroups, hasParts, id }) => {
          return (
            <div
              key={`${name}${id}`}
              className={cx(styles.cardWrapper_cardWithImg, {
                [styles.pointerCursor]: hasParts
              })}
            >
              <Link key={name} to={linkTo ? linkTo(hasParts, name, id) : EMPTY_STRING}>
                <CardContent
                  img={img}
                  isLoading={isLoading}
                  name={name}
                  onClick={cardContentClick && cardContentClick(hasParts, id)}
                />
              </Link>
              {subgroups &&
                subgroups.length > 0 &&
                subgroups.map(item => (
                  <CardLink id={item.id} isLoading={isLoading} key={item.id} name={item.name} />
                ))}
              <div className={styles.cardWrapper_additionalLinks}></div>
            </div>
          );
        })
      ) : (
        <CardImage isLoading={isLoading} links={links} />
      )}
    </>
  );
};
