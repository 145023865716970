import { useNavigate } from 'react-router-dom';

import { DataOrder } from 'src/shared/components/OrderCard/types';
import { pathConfig } from 'src/shared/constants/pathConfig';
import { useBasketStore, useOrdersStore } from 'src/shared/model/Store';
import { formattedPhone } from 'src/shared/functions/formattedPhone/formattedPhone';
import { basketDefaultValue } from 'src/shared/functions/basketDefaultValue/basketDefaultValue';
import { EMPTY_ARRAY, EMPTY_STRING } from 'src/shared/constants/fallbacks';

export const useOrders = () => {
  const [
    orders,
    setOrder,
    orderInformation,
    getOrderInformation,
    placeAnOrderData,
    placeAnOrder,
    resetOrder,
    isLoading,
    getOrderInformationPlacinganorder
  ] = useOrdersStore(state => [
    state.orders,
    state.setOrder,
    state.orderInformation,
    state.getOrderInformation,
    state.placeAnOrderData,
    state.placeAnOrder,
    state.resetOrder,
    state.isLoading,
    state.getOrderInformationPlacinganorder
  ]);
  const [basket, checkBasketResponse] = useBasketStore(state => [
    state.basket,
    state.checkBasketResponse
  ]);

  const navigate = useNavigate();

  const orderValues = basketDefaultValue(checkBasketResponse);

  const onClick = () => {
    navigate(pathConfig.DetailedOrderPath);
  };
  const handleOptionChange = (option: DataOrder) => {
    setOrder(option);
    onClick();
  };

  const orderCardData = basket
    ? basket.noChanges.map(data => {
        return {
          id: data.id,
          name: data.description,
          vendorCode: data.article_name,
          count: data.quantity,
          img: EMPTY_STRING,
          brand: data.brand_name,
          partHash: data.part_hash || EMPTY_STRING
        };
      })
    : EMPTY_ARRAY;

  const pointsData = orderInformation.map(data => ({
    id: data?.id ?? 0,
    name: data?.title ?? EMPTY_STRING,
    address: data?.address ?? EMPTY_STRING,
    time: data?.work_time ?? EMPTY_STRING,
    phone: formattedPhone(data?.phone ?? EMPTY_STRING),
    balloonOpen: false,
    geometry: [
      parseFloat(data?.cordinates?.latitude ?? EMPTY_STRING),
      parseFloat(data?.cordinates?.longitude ?? EMPTY_STRING)
    ]
  }));

  return {
    handleOptionChange,
    orders,
    orderInformation,
    getOrderInformation,
    placeAnOrderData,
    placeAnOrder,
    pointsData,
    orderValues,
    orderCardData,
    resetOrder,
    isLoading,
    getOrderInformationPlacinganorder
  };
};
