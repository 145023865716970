import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { OrderCardItemViewProps } from '@shared/components/OrderCardItem/ui/types';
import { VENDOR_CODE_TEXT } from '@shared/constants/ texts';
import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholderForCard.png';
import { dataTestId } from '@shared/constants/dataTestId';
import styles from './styles.module.scss';

export const OrderCardItemView = ({
  item,
  isLastItem,
  isLoading,
  productUrl
}: OrderCardItemViewProps) => {
  return (
    <div
      data-testid={dataTestId.ORDER_CARD_ITEM_VIEW}
      className={cx(styles.orderCardItemContainer, {
        [styles.orderCardItemContainer_lastItem]: isLastItem
      })}
    >
      <div className={styles.orderCardItemContainer_logo}>
        {isLoading ? (
          <Skeleton height='34px' width='100%' />
        ) : (
          <img alt='Изображение' src={item.img || picturePlaceholder} />
        )}
      </div>
      <div className={styles.orderCardItemContainer_itemWrapper}>
        <div className={styles.orderCardItemContainer_itemWrapper_textWrapper}>
          {isLoading ? (
            <Skeleton height='24px' width='150px' />
          ) : (
            <Link className={styles.orderCard_button} to={productUrl}>
              <Text
                className={styles.orderCardItemContainer_itemWrapper_textWrapper_name}
                variant='headline-H3'
              >
                {item.name}
              </Text>
            </Link>
          )}
          <div className={styles.orderCardItemContainer_itemWrapper_textWrapper_article}>
            <Text className={styles.orderCardItemContainer_itemWrapper_textWrapper_article_text}>
              {isLoading ? <Skeleton height='24px' width='67px' /> : `${VENDOR_CODE_TEXT}:`}
            </Text>
            <Text
              className={styles.orderCardItemContainer_itemWrapper_textWrapper_article_vendorCode}
            >
              {isLoading ? <Skeleton height='24px' width='40px' /> : item.vendorCode}
            </Text>
          </div>
        </div>
        <div className={styles.orderCardItemContainer_itemWrapper_textWrapper_countContainer}>
          <Text
            className={styles.orderCardItemContainer_itemWrapper_textWrapper_article_text}
            variant='body-tight'
          >
            {isLoading ? <Skeleton height='20px' width='87px' /> : 'Количество:'}
          </Text>
          <Text
            className={styles.orderCardItemContainer_itemWrapper_textWrapper_article_vendorCode}
            variant='body-tight'
          >
            {isLoading ? <Skeleton height='20px' width='34px' /> : `${item.count} шт.`}
          </Text>
        </div>
      </div>
    </div>
  );
};
