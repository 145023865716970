import { CardsContainerProps } from 'src/shared/components/CardsContainer/types';
import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';

export const CardsContainer = ({ children }: CardsContainerProps) => (
  <div className={styles.container} data-testid={dataTestId.CARDS_CONTAINER}>
    {children}
  </div>
);
