import React, { useEffect, useState } from 'react';
import { useBasketStore } from '@shared/model/Store';
import { useNavigate } from 'react-router-dom';
import { defaultYears, initial_filter } from '@pages/HistoryOrdersPage/lib/consts';
import { HistoryOrdersPageView } from '@pages/HistoryOrdersPage';
import { useHistoryOrdersStore } from '@shared/model/Store/HistoryOrders';
import { filterHistoryOrders } from '../lib/functions/functions';

export const HistoryOrdersPage = () => {
  const [orders, years, pagination, isLoading, getHistoryOrdersData] = useHistoryOrdersStore(
    state => [
      state.orders,
      state.years,
      state.pagination,
      state.isLoading,
      state.getHistoryOrdersData
    ]
  );

  const [recreateBasketByOrder, isRecreateLoading, isLoadingCheck, resetRecreateBasket] =
    useBasketStore(state => [
      state.recreateBasketByOrder,
      state.isLoading,
      state.isLoadingCheck,
      state.resetRecreateBasket
    ]);

  const navigate = useNavigate();

  const [selectedYear, setSelectedYear] = useState(defaultYears);
  const [currentOrders, setCurrentOrders] = useState(orders);
  const [showAll, setShowAll] = useState(false);
  const [showBtnAll, setShowBtnAll] = useState(false);

  const handleSelectChange = (value: string) => {
    const visibleOrders = filterHistoryOrders(orders, value);

    setCurrentOrders(visibleOrders);
    setSelectedYear(value);
  };

  const handleRepeatOrder = (orderId: number) => {
    recreateBasketByOrder(orderId, navigate);
  };

  const handleShowAll = () => {
    if (showAll) {
      const filter = {
        page: pagination.page + 1
      };
      getHistoryOrdersData(filter);

      return;
    }

    getHistoryOrdersData(initial_filter);
  };

  useEffect(() => {
    getHistoryOrdersData(initial_filter);
    resetRecreateBasket();
  }, []);

  useEffect(() => {
    setCurrentOrders(orders);
    setShowBtnAll(pagination.page_count > 1);
    setShowAll(pagination.page !== pagination.page_count);
  }, [orders]);

  return (
    <HistoryOrdersPageView
      handleRepeatOrder={handleRepeatOrder}
      handleSelectChange={handleSelectChange}
      handleShowAll={handleShowAll}
      isLoading={isLoading || isRecreateLoading || isLoadingCheck}
      orderCardData={currentOrders}
      selectedYear={selectedYear}
      showAll={showAll}
      showBtnAll={showBtnAll}
      years={years}
    />
  );
};
