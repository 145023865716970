import React from 'react';
import { Skeleton } from '@uremont/erp-ui';
import { Map, Placemark, YMaps } from 'react-yandex-maps';

import { PlacemarkRef, PlacingAnOrderMapViewProps } from './types';

export const PlacingAnOrderMapView = ({
  points,
  dot,
  point,
  heightMap,
  state,
  isLoading,
  handleClickPoint,
  handleInstanceRef
}: PlacingAnOrderMapViewProps) => {
  return (
    <div>
      {isLoading ? (
        <Skeleton height={heightMap} width='100%' />
      ) : (
        <div style={{ height: heightMap }}>
          <YMaps>
            <Map defaultState={state} height={heightMap} state={state} width='100%'>
              {points.map((item, index) => (
                <Placemark
                  geometry={item.geometry}
                  instanceRef={(ref: PlacemarkRef) => handleInstanceRef(ref, item.balloonOpen)}
                  key={item.id}
                  modules={['geoObject.addon.balloon']}
                  onBalloonopen={() => handleClickPoint(index)}
                  properties={{
                    balloonContentBody: point, // Иконка при клике/фокусе
                    iconContent: dot, // Дефолтная иконка
                    balloonPanelMaxMapArea: 1
                  }}
                />
              ))}
            </Map>
          </YMaps>
        </div>
      )}
    </div>
  );
};
