import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { EMPTY_ARRAY } from 'src/shared/constants/fallbacks';
import { FAQStore } from './types';
import { getConvertedFAQResponse, getFAQ } from './service';

export const useFAQStore = create(
  devtools(
    persist<FAQStore>(
      set => ({
        isLoading: true,
        FAQItems: EMPTY_ARRAY,
        getFAQData: async () => {
          await executeRequest(
            getFAQ,
            isLoading => set({ isLoading }),
            response => {
              const newData = response ? getConvertedFAQResponse(response) : EMPTY_ARRAY;
              set({ FAQItems: newData });
            }
          );
        }
      }),
      {
        name: 'FAQ-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
