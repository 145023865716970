import React from 'react';
import { Text } from '@uremont/erp-ui';

import { ORDER_STATUSES } from '@shared/constants/enums';

import styles from './styles.module.scss';

export const getOrderStatus = (status: number) => {
  switch (status as ORDER_STATUSES) {
    case ORDER_STATUSES.ORDER_CREATED:
      return <Text className={styles.orderCreated}>Заказ создан</Text>;
    case ORDER_STATUSES.IN_WORK:
      return <Text className={styles.inWork}>В работе</Text>;
    case ORDER_STATUSES.DELIVERY:
      return <Text className={styles.delivery}>Доставка</Text>;
    case ORDER_STATUSES.TO_ISSUE:
      return <Text className={styles.toIssue}>К выдаче</Text>;
    case ORDER_STATUSES.ISSUED:
      return <Text className={styles.issued}>Выдан</Text>;
    case ORDER_STATUSES.REJECT:
      return <Text className={styles.reject}>Отказ</Text>;
    default:
      return <Text className={styles.reject}>Нет информации</Text>;
  }
};
