import React from 'react';
import { Skeleton } from '@uremont/erp-ui';

import styles from './styles.module.scss';

export const SkeletonCarGenerations = () => {
  return (
    <>
      {Array.from({ length: 8 }).map((_, index) => (
        <div className={styles.skeletonCarGenerations} key={index}>
          <Skeleton circle className={styles.skeletonCarGenerations_dot} />
          <Skeleton height='24px' width='230px' />
        </div>
      ))}
    </>
  );
};
