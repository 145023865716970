import React from 'react';
import { Button, Radio, Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';
import { engineCodeText } from '@shared/constants/engineCodeText';
import { getIconForHeader } from '@shared/lib/getIconForHeader';

import { CustomTableViewProps } from './types';
import styles from './styles.module.scss';

export const CustomTableView = ({
  data,
  selectedRow,
  isLoading,
  filteredHeaders,
  handleRadioChange
}: CustomTableViewProps<{
  [key: string]: string | number;
}>) => {
  return (
    <table className={styles.customTable} data-testid={dataTestId.CUSTOM_TABLE}>
      <thead>
        <tr>
          <th></th>
          {filteredHeaders.map((header, index) => (
            <th className={styles.customTable_headerCell} key={index}>
              <Text color='secondary' variant='body-tight'>
                <div
                  className={cx(styles.customTable_titleWrapper, {
                    [styles.customTable_titleWrapper_lastTitle]:
                      header.toLowerCase() === 'объем, л.'
                  })}
                >
                  {isLoading ? <Skeleton height='24px' width='120px' /> : header}
                  <Button
                    data-testid={`${dataTestId.CUSTOM_TABLE_HEADER_BTN}-${index}`}
                    variant='control'
                  >
                    {getIconForHeader(header)}
                  </Button>
                </div>
              </Text>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data &&
          data.map((row, rowIndex) => (
            <tr
              key={rowIndex}
              onClick={() => handleRadioChange(row)}
              className={cx(styles.customTable_row, {
                [styles.customTable_active]: selectedRow.id === row.id
              })}
            >
              <td>
                {isLoading ? (
                  <Skeleton circle height='24px' width='24px' />
                ) : (
                  <Radio
                    checked={selectedRow.id === row.id}
                    className={styles.customTable_radio}
                    data-testid={`${dataTestId.CUSTOM_TABLE_RADIO}-${rowIndex}`}
                    readOnly
                  />
                )}
              </td>
              {filteredHeaders.map((header, columnIndex) => (
                <td key={columnIndex}>
                  <Text
                    color={header.toLowerCase() === engineCodeText ? 'brand' : 'default'}
                    variant='body-tight'
                    className={cx(styles.customTable_items, {
                      [styles.customTable_codeCell]: header.toLowerCase() === engineCodeText,
                      [styles.customTable_lastCodeCell]: header.toLowerCase() === 'объем, л.'
                    })}
                  >
                    {isLoading ? <Skeleton height='24px' width='120px' /> : row[header]}
                  </Text>
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </table>
  );
};
