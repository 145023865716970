export const getMinMaxYearRange = (years: (string | null)[]): string => {
  const filteredArr = years.filter(value => value !== null) as string[];
  const sortedArr = filteredArr.sort();

  if (sortedArr.length === 0) {
    return '';
  }

  const minNumber = sortedArr[0];
  const maxNumber = sortedArr[sortedArr.length - 1];

  if (minNumber === maxNumber) {
    return minNumber;
  }

  return `${minNumber}-${maxNumber}`;
};
