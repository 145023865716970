import { IconChevronLeft, IconChevronRight } from '@uremont/erp-ui';

import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';
import { ArrowSliderProps } from './types';

export const ArrowSlider = ({
  className,
  onClick,
  arrowDirection,
  buttonShadow
}: ArrowSliderProps) => {
  const isRight = arrowDirection === 'right',
    classArrowDirection = isRight ? styles.btnArrow_right : styles.btnArrow_left;

  return (
    <>
      <div
        className={`${className} ${styles.btnArrow} ${classArrowDirection}`}
        data-testid={dataTestId.ARROW}
        onClick={onClick}
      >
        {isRight ? (
          <IconChevronRight color='brand' data-testid={dataTestId.ARROW_RIGHT} />
        ) : (
          <IconChevronLeft color='brand' data-testid={dataTestId.ARROW_LEFT} />
        )}
      </div>
      {buttonShadow && <div className={styles.boxShadow} />}
    </>
  );
};
