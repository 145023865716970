import { countryCitiesLinks } from 'src/features/AppLayout/components/Footer/lib/constants/links/countryCitiesLinks';
import { uremontLinks } from 'src/shared/constants/links';
import { ReactComponent as KZMap } from '@assets/maps/KZMap.svg';
import { ReactComponent as RUSMap } from '@assets/maps/RUSMap.svg';
import { ReactComponent as BLRMap } from '@assets/maps/BLRMap.svg';

const groupUremont = {
    name: 'Uremont.com',
    links: [
      {
        name: 'Ремонт авто',
        path: uremontLinks.newBid
      },
      {
        name: 'Автозапчасти',
        path: uremontLinks.autoparts,
        target: '_blank'
      },
      {
        name: 'Страхование',
        path: uremontLinks.insurance,
        target: '_blank'
      },
      {
        name: 'Автопарки',
        path: uremontLinks.autoparks,
        target: '_blank'
      },
      {
        name: 'Благотворительный фонд',
        path: uremontLinks.charity
      },
      {
        name: 'Розыгрыш призов',
        path: uremontLinks.faq
      }
    ]
  },
  groupInfo = {
    name: 'Информация',
    links: [
      {
        name: 'Соглашения',
        path: uremontLinks.agreement
      },
      {
        name: 'Оферта',
        path: uremontLinks.offer
      },
      {
        name: 'Оферта для СТО',
        path: uremontLinks.offerSTO,
        target: '_blank'
      },
      {
        name: 'Оферта для автозапчастей',
        path: uremontLinks.partsOffer,
        target: '_blank'
      },
      {
        name: 'Конфиденциальность',
        path: uremontLinks.privacy
      },
      {
        name: 'Поиск по карте',
        path: uremontLinks.map
      }
    ]
  },
  groupAbout = {
    name: 'О проекте',
    links: [
      {
        name: 'Как это работает',
        path: uremontLinks.howItWorks
      },
      {
        name: 'Контакты',
        path: uremontLinks.contacts
      },
      {
        name: 'Программа лояльности',
        path: uremontLinks.agreement
      },
      {
        name: 'Вопрос-ответ',
        path: uremontLinks.faq
      }
    ]
  },
  groupUremontBel = {
    name: 'UREMONT.COM',
    links: [
      {
        name: 'Ремонт авто',
        path: uremontLinks.newBid
      },
      {
        name: 'Поиск по карте',
        path: uremontLinks.map
      },
      {
        name: 'Вопрос-ответ',
        path: uremontLinks.faq
      },
      {
        name: 'Подключить автосервис',
        path: uremontLinks.registerService
      }
    ]
  },
  groupInfoBel = {
    name: 'Информация',
    links: [
      {
        name: 'Соглашения',
        path: uremontLinks.agreementBel
      },
      {
        name: 'Оферта',
        path: uremontLinks.offerBel
      },
      {
        name: 'Оферта для СТО',
        path: uremontLinks.offerSTOBel,
        target: '_blank'
      },
      {
        name: 'Конфиденциальность',
        path: uremontLinks.privacyBel
      }
    ]
  },
  groupAboutBel = {
    name: 'О проекте',
    links: [
      {
        name: 'Как это работает',
        path: uremontLinks.howItWorks
      },
      {
        name: 'Контакты',
        path: uremontLinks.contacts
      },
      {
        name: 'Благотворительный фонд',
        path: uremontLinks.charity
      }
    ]
  },
  groupUremontKz = {
    name: 'UREMONT.COM',
    links: [
      {
        name: 'Ремонт авто',
        path: uremontLinks.newBid
      },
      {
        name: 'Поиск по карте',
        path: uremontLinks.map
      },
      {
        name: 'Вопрос-ответ',
        path: uremontLinks.faq
      },
      {
        name: 'Подключить автосервис',
        path: uremontLinks.registerService
      }
    ]
  },
  groupInfoKz = {
    name: 'Информация',
    links: [
      {
        name: 'Соглашения',
        path: uremontLinks.agreement
      },
      {
        name: 'Оферта',
        path: uremontLinks.offer
      },
      {
        name: 'Оферта для СТО',
        path: uremontLinks.offerSTO,
        target: '_blank'
      },
      {
        name: 'Конфиденциальность',
        path: uremontLinks.privacy
      }
    ]
  },
  groupAboutKz = {
    name: 'О проекте',
    links: [
      {
        name: 'Как это работает',
        path: uremontLinks.howItWorks
      },
      {
        name: 'Контакты',
        path: uremontLinks.contacts
      },
      {
        name: 'Благотворительный фонд',
        path: uremontLinks.charity
      }
    ]
  },
  navigation = [groupUremont, groupInfo, groupAbout],
  navigationBelarus = [groupUremontBel, groupInfoBel, groupAboutBel],
  navigationKazakhstan = [groupUremontKz, groupInfoKz, groupAboutKz];

export const footerSocialContent = {
  phone: '+ 7 (800) 707-12-90',
  whatsappPhone: '+ 7 (925) 276-78-47',
  social: 'support@uremont.com'
};

export const menus = {
  1: {
    countryTitle: 'России',
    navigation: navigation,
    cities: countryCitiesLinks['1'], // phone: '8 (800) 707-12-90',
    icon: RUSMap
  },
  22: {
    countryTitle: 'Беларуси',
    navigation: navigationBelarus,
    cities: countryCitiesLinks['22'], // phone: '+375 (8200) 321-01-36'
    icon: BLRMap
  },
  82: {
    countryTitle: 'Казахстана',
    navigation: navigationKazakhstan,
    cities: countryCitiesLinks['82'], // phone: '8 (800) 707-12-90'
    icon: KZMap
  }
};
