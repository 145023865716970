import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { toast } from 'react-toastify';

import api from 'src/shared/api/api';
import { apiUrls } from 'src/shared/api/apiUrls';
import { EMPTY_STRING } from 'src/shared/constants/fallbacks';

interface ApiResponse<T> {
  data?: T;
  collection?: T;
  advices?: T;
  delivery_adresses?: T;
  success?: boolean;
}

interface ApiConfig<T, U = {}> {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  params?: U;
  handler: (response: AxiosResponse<ApiResponse<T>>) => T;
  config?: AxiosRequestConfig;
}

//TODO: тип данных будет any, пока не утвердят какой тип данных должен быть
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const apiConfig: Record<string, ApiConfig<any>> = {
  getCountries: {
    url: apiUrls.COUNTRIES,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data?.data
  },
  getCurrentCountry: {
    url: apiUrls.CURRENT_COUNTRY,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getPopularCities: {
    url: apiUrls.POPULAR_CITIES,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getSearchCities: {
    url: apiUrls.SEARCH_CITIES,
    method: 'GET',
    params: {
      country_id: 0,
      query: EMPTY_STRING
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  auth: {
    url: apiUrls.AUTH,
    method: 'GET',
    handler: response => response.data
  },
  getSearchByGRZ: {
    url: apiUrls.SEARCH_BY_GRZ,
    method: 'GET',
    params: { vin: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getSearchByVIN: {
    url: apiUrls.SEARCH_BY_VIN,
    method: 'GET',
    params: { vin: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getSearchByArticle: {
    url: apiUrls.SEARCH_BY_ARTICLE,
    method: 'GET',
    params: {
      article: EMPTY_STRING,
      brand: EMPTY_STRING,
      page: EMPTY_STRING,
      'per-page': EMPTY_STRING
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getSearchByArticleOnly: {
    url: apiUrls.SEARCH_BY_ARTICLE_ONLY,
    method: 'GET',
    params: {
      article: EMPTY_STRING,
      brand: EMPTY_STRING,
      page: EMPTY_STRING,
      'per-page': EMPTY_STRING
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getCarBrands: {
    url: apiUrls.CAR_BRANDS,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getCarModels: {
    url: apiUrls.CAR_MODELS,
    method: 'GET',
    params: { brand: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getCarGenerations: {
    url: apiUrls.CAR_GENERATIONS,
    method: 'GET',
    params: { brand: EMPTY_STRING, modelId: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  postCollaborate: {
    url: apiUrls.COLLABORATE,
    method: 'POST',
    handler: response => response.data
  },
  getTireSelection: {
    url: apiUrls.TIRE_SELECTION, // TODO: Изменить, когда появится соответствующий метод на беке
    method: 'GET',
    handler: response => response.data
  },
  getRecommendedPurchase: {
    url: apiUrls.RECOMMENDED_PURCHASE, // TODO: Изменить, когда появится соответствующий метод на беке
    method: 'GET',
    handler: response => response.data
  },
  getCarGroupParts: {
    url: apiUrls.CAR_GROUP_PARTS,
    method: 'GET',
    params: { brand: EMPTY_STRING, carId: EMPTY_STRING, groupId: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getCarSubGroupParts: {
    url: apiUrls.CAR_SUBGROUP_PARTS,
    method: 'GET',
    params: { brand: EMPTY_STRING, carId: EMPTY_STRING, groupId: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getArticleTips: {
    url: apiUrls.SEARCH_ARTICLE_TIPS,
    method: 'GET',
    params: { article: EMPTY_STRING },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getFAQ: {
    url: apiUrls.FAQ,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getBasketId: {
    url: apiUrls.GET_BASKET_ID,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data.basketId
  },
  getBasket: {
    url: apiUrls.GET_BASKET,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  addToBasket: {
    url: apiUrls.ADD_TO_BASKET,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  removeFromBasket: {
    url: apiUrls.REMOVE_FROM_BASKET,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  checkBasketId: {
    url: apiUrls.CHECK_BASKET_ID,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getArticleAnalogues: {
    url: apiUrls.GET_ARTICLE_ANALOGUES,
    method: 'GET',
    params: {
      article: EMPTY_STRING,
      brand: EMPTY_STRING,
      page: EMPTY_STRING,
      'per-page': EMPTY_STRING
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getArticleOnlyAnalogues: {
    url: apiUrls.GET_ARTICLE_ONLY_ANALOGUES,
    method: 'GET',
    params: {
      article: EMPTY_STRING,
      brand: EMPTY_STRING,
      page: EMPTY_STRING,
      'per-page': EMPTY_STRING
    },
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getRecommendedByArticleProducts: {
    url: apiUrls.RECOMMENDED_BY_ARTICLE_PRODUCTS,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    handler: response => response.data.data
  },
  getRecommendedByBasketProducts: {
    url: apiUrls.RECOMMENDED_BY_BASKET_PRODUCTS,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data[0].advices
  },
  getProductInfo: {
    url: apiUrls.PRODUCT_INFO,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  },
  getBanners: {
    url: apiUrls.BANNERS,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data.collection
  },
  getOrderInformation: {
    url: apiUrls.ORDER_INFO,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data.delivery_adresses
  },
  placeAnOrder: {
    url: apiUrls.PLACE_AN_ORDER,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  },
  getHistoryOrders: {
    url: apiUrls.HISTORY_ORDERS,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  },
  searchRequest: {
    url: apiUrls.SEARCH_REQUEST,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data
  },
  getProfileInfo: {
    url: apiUrls.PROFILE_INFO,
    method: 'GET',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  },
  insuranceRequest: {
    url: apiUrls.INSURANCE_FORM,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data
  },
  refreshToken: {
    url: apiUrls.REFRESH_TOKEN,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  },
  recreateBasketByOrder: {
    url: apiUrls.RECREATE_BASKET_BY_ORDER,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  },
  clearBasket: {
    url: apiUrls.CLEAR_BASKET,
    method: 'POST',
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return,@typescript-eslint/no-unsafe-member-access
    handler: response => response.data.data
  }
};

export const fetchData = async <T, U>(
  configName: string,
  params?: U,
  dynamicParams?: Record<string, string>
): Promise<T | void> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const config: ApiConfig<T> = apiConfig[configName];

    const isBodyForRequest =
      config.method === 'POST' || config.method === 'PUT' || config.method === 'PATCH';

    const data = new FormData();

    if (isBodyForRequest && params) {
      if (isBodyForRequest && params) {
        Object.entries(params).forEach(([key, value]) => {
          if (typeof value === 'string' || value instanceof Blob) {
            data.append(key, value);
          }
        });
      }
    }

    let dynamicURL = config.url;

    if (dynamicParams) {
      Object.entries(dynamicParams).forEach(([key, value]) => {
        const decodedValue = decodeURIComponent(value);
        dynamicURL = dynamicURL.replace(`{${key}}`, decodedValue);
      });
    }

    const instanceConfig = {
      ...config.config,
      method: config.method,
      url: dynamicParams ? dynamicURL : config.url,
      ...(isBodyForRequest ? { data } : { params })
    };

    const response: AxiosResponse<ApiResponse<T>> = await api.request(instanceConfig);

    return config.handler(response);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      if (error.response?.status !== 422) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
        toast.error(
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
          error.response?.data?.errors?.length
            ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              error.response.data.errors[0]
            : 'Произошла ошибка запроса'
        );
      }
    } else {
      // eslint-disable-next-line no-console
      console.error('Произошла ошибка:', error);
      toast.error('Что-то пошло не так, повторите попытку позже');
    }
  }
};
