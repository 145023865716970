import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { EMPTY_ARRAY, EMPTY_STRING } from 'src/shared/constants/fallbacks';

import { FilterComponentStore } from './types';

export const useFilterComponentStore = create(
  devtools(
    persist<FilterComponentStore>(
      set => ({
        selectedDelivery: Number.MAX_VALUE,
        selectedDeliveryPeriod: 0,
        day: 0,
        availability: 0,
        filterValue: EMPTY_STRING,
        brands: EMPTY_ARRAY,

        setSelectedDelivery: delivery => {
          set({
            selectedDelivery: delivery,
            selectedDeliveryPeriod: delivery
          });
        },

        setDay: newDay => {
          set({
            day: newDay,
            selectedDeliveryPeriod: newDay
          });
        },

        setAvailability: newAvailability => {
          set({
            availability: newAvailability
          });
        },

        setFilterValue: newFilterValue => {
          set({
            filterValue: newFilterValue
          });
        },

        setBrands: newBrands => {
          set({
            brands: newBrands
          });
        },

        resetFilter: () => {
          set({
            selectedDelivery: Number.MAX_VALUE,
            selectedDeliveryPeriod: Number.MAX_VALUE,
            day: 0,
            availability: null,
            filterValue: EMPTY_STRING,
            brands: EMPTY_ARRAY
          });
        }
      }),
      {
        name: 'filterComponent-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
