import React, { useState, useCallback } from 'react';
import { Skeleton } from '@uremont/erp-ui';

import { EMPTY_OBJECT, EMPTY_STRING } from '@shared/constants/fallbacks';
import { useBasket } from '@shared/hooks/use-basket';
import { useBasketStore } from '@shared/model/Store';
import { AddCardBasketView } from './AddCardBasketView';

import { AddCartBasketProps } from './types';

//Добавлено игнорирование, так как из за React.memo получаем ошибку eslint
// eslint-disable-next-line react/display-name
export const AddCardBasket = React.memo(
  ({ item, isLoading, className = EMPTY_STRING, rest }: AddCartBasketProps) => {
    if (isLoading) {
      return <Skeleton height='48px' width='100%' />;
    }
    const { part_hash } = item;

    const [basket] = useBasketStore(state => [state.basket]);

    const clickedItem = basket
      ? [...basket.noChanges, ...basket.priceChanged].find(
          basketItem => basketItem?.part_hash === part_hash
        ) || EMPTY_OBJECT
      : EMPTY_OBJECT;

    const { handleDelete, handleAdd, cardCount, isLoadingBasket } = useBasket(
      part_hash ? part_hash : EMPTY_STRING,
      clickedItem ?? EMPTY_STRING
    );

    const [blockMinusBtn, setBlockMinusBtn] = useState(false);

    const isDisableAddMoreItem =
      (rest || rest === 0 ? rest <= cardCount : false) || isLoading || isLoadingBasket;

    const isDisableDeleteItem = isLoading || blockMinusBtn || isLoadingBasket;

    const handleBasketAdd = useCallback(async () => {
      if (cardCount === 1) {
        setBlockMinusBtn(false);
      }
      await handleAdd();
    }, [cardCount, handleAdd]);

    return (
      <AddCardBasketView
        className={className}
        count={cardCount}
        handleAdd={handleBasketAdd}
        handleDelete={handleDelete}
        id={item.id}
        isDisableAddMoreItem={isDisableAddMoreItem}
        isDisableDeleteItem={isDisableDeleteItem}
      />
    );
  }
);
