import React from 'react';
import { useNavigate } from 'react-router-dom';

import { NoInfoView } from 'src/shared/components/NoInfo/ui/NoInfoView';
import { NoInfoProps } from 'src/shared/components/NoInfo/ui/types';
import { pathConfig } from 'src/shared/constants/pathConfig';

export const NoInfo = ({
  isSearchByDetails = false,
  isPlacingAnOrder = false,
  isError = false
}: NoInfoProps) => {
  const navigate = useNavigate();
  const goToMainHandler = () => navigate(pathConfig.MainPath);

  return (
    <NoInfoView
      isError={isError}
      isPlacingAnOrder={isPlacingAnOrder}
      isSearchByDetails={isSearchByDetails}
      onClick={goToMainHandler}
    />
  );
};
