import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useScrollToTop } from '@shared/hooks/use-scroll-to-top';
import { useCarCatalogStore } from '@shared/model/Store/CarCatalog';
import { EMPTY_ARRAY, EMPTY_STRING } from '@shared/constants/fallbacks';

export const useCarCatalog = () => {
  const [
    getCarBrands,
    getCarModels,
    carBrands,
    carModels,
    getCarGenerations,
    carGenerations,
    getCarGroupParts,
    carGroupParts,
    setSelectedModel,
    selectedModel,
    subgroupParts,
    getCarSubGroupParts,
    isLoadingCarBrands,
    isLoadingCarModels,
    isLoadingCarGenerations,
    isLoadingCarGroupParts,
    isLoadingSubgroupParts,
    isLoading,
    nestedGroup
  ] = useCarCatalogStore(state => [
    state.getCarBrands,
    state.getCarModels,
    state.carBrands,
    state.carModels,
    state.getCarGenerations,
    state.carGenerations,
    state.getCarGroupParts,
    state.carGroupParts,
    state.setSelectedModel,
    state.selectedModel,
    state.subgroupParts,
    state.getCarSubGroupParts,
    state.isLoadingCarBrands,
    state.isLoadingCarModels,
    state.isLoadingCarGenerations,
    state.isLoadingCarGroupParts,
    state.isLoadingSubgroupParts,
    state.isLoading,
    state.nestedGroup
  ]);

  const navigate = useNavigate();

  const getGroupPartsDetails = (groupId: string) => {
    if (selectedModel) {
      const brand = selectedModel.brand || selectedModel['Марка'];
      getCarGroupParts(brand.toLowerCase(), selectedModel.id, navigate, groupId, true);
    }
  };

  const getSubGroupPartsDetails = (groupId: string) => {
    if (selectedModel) {
      const brand = selectedModel.brand || selectedModel['Марка'];
      getCarSubGroupParts(brand.toLowerCase(), selectedModel.id, groupId);
    }
  };

  const sortedBrands = useMemo(() => carBrands.sort((a, b) => a.name.localeCompare(b.name)), []);
  const carNames = useMemo(() => sortedBrands.map(item => item.name), [sortedBrands]);
  const carIds = useMemo(
    () => [...carBrands].map(item => item.id).sort((a, b) => a.localeCompare(b)),
    [carBrands]
  );
  const getCurrentCarModelId = (selectedModelValue: string) => {
    const currentCarModel = carModels.find(car => car.name === selectedModelValue);

    return currentCarModel?.id ?? EMPTY_STRING;
  };

  const transformedSelectedModelData = selectedModel
    ? [
        { title: 'Название', name: selectedModel.name || selectedModel['Марка'] },
        { title: 'Год', name: selectedModel['Год'] },
        { title: 'Регион', name: selectedModel['Регион'] },
        {
          title: 'Описание',
          name: selectedModel.description || selectedModel['Обозначенные модели']
        }
      ]
    : EMPTY_ARRAY;

  useScrollToTop();

  return {
    getCarBrands,
    getCarModels,
    carBrands,
    carNames,
    carIds,
    carModels,
    getCarGenerations,
    getCurrentCarModelId,
    carGenerations,
    getCarGroupParts,
    carGroupParts,
    setSelectedModel,
    selectedModel,
    transformedSelectedModelData,
    getCarSubGroupParts,
    subgroupParts,
    isLoadingCarBrands,
    isLoadingCarModels,
    isLoadingCarGenerations,
    isLoadingCarGroupParts,
    isLoadingSubgroupParts,
    isLoading,
    getGroupPartsDetails,
    getSubGroupPartsDetails,
    nestedGroup
  };
};
