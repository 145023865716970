import React, { useEffect, useState } from 'react';

import logoIcon from '@assets/basket/logoIcon.png';
import { PlacingAnOrderMapView } from './PlacingAnOrderMapView';

import { PlacemarkRef, PlacingAnOrderMapProps } from './types';
import styles from './styles.module.scss';

export const PlacingAnOrderMap = ({
  points,
  currentPoint,
  isLoading,
  handleClickPoint
}: PlacingAnOrderMapProps) => {
  const [heightMap, setHeightMap] = useState('456px');

  const dot = `<div class='${styles.placingAnOrderMap_dot}'></div>`,
    point = `
  <svg width='48' height='60' viewBox='0 0 48 60' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0 23.2258C0 10.3985 10.7452 0 24 0C37.2548 0 48 10.3985 48 23.2258C48 42.5806 24 60 24 60C24 60 0 42.5806 0 23.2258Z'
      fill='#227DF5'
    />
  </svg>
  <img width='40px' height='40px' class='${styles.placingAnOrderMap_logo}' src=${logoIcon} alt="iconMap" />
  `,
    state = {
      center: currentPoint,
      zoom: 12
    },
    handleInstanceRef = (ref: PlacemarkRef, balloonOpen: boolean) => {
      if (balloonOpen) {
        ref && ref.balloon.open();
      }
    };

  useEffect(() => {
    const handleResize = () => {
      const { innerWidth } = window;

      if (innerWidth < 366) {
        setHeightMap('670px');
      } else if (innerWidth < 431) {
        setHeightMap('560px');
      } else {
        setHeightMap('456px');
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <PlacingAnOrderMapView
      dot={dot}
      handleClickPoint={handleClickPoint}
      handleInstanceRef={handleInstanceRef}
      heightMap={heightMap}
      isLoading={isLoading}
      point={point}
      points={points}
      state={state}
    />
  );
};
