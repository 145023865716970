import {
  Basket,
  BasketParts,
  BasketPostData,
  RecreateBasket
} from '@shared/model/Store/Basket/types';
import { fetchData } from '@shared/api/apiConfig';

export const getBasketId = async (): Promise<string | void> => fetchData('getBasketId');

export const getBasket = async (basketId: string): Promise<Basket | void> =>
  await fetchData('getBasket', null, { basketId: basketId });

export const addToBasket = async (
  basketId: string,
  data: BasketPostData
): Promise<BasketParts | void> => await fetchData('addToBasket', data, { basketId: basketId });

export const removeFromBasket = async (
  basketId: string,
  data: BasketPostData
): Promise<BasketParts | void> => await fetchData('removeFromBasket', data, { basketId: basketId });

export const checkBasketId = async (basket_id: string): Promise<Basket | void> =>
  await fetchData('checkBasketId', { basket_id });

export const recreateBasketByOrder = async (orderId: string): Promise<RecreateBasket | void> =>
  await fetchData('recreateBasketByOrder', { orderId });

export const clearBasket = async (basket_id: string): Promise<BasketParts | void> =>
  await fetchData('clearBasket', { basket_id });
