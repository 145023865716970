export const SEARCH_MENU_ITEMS_FOR_SCREEN = {
  searchAutoNumber: 'Поиск по ГРЗ',
  searchVINNumber: 'Поиск по артикулу/VIN номеру',
  searchModelAuto: 'Поиск по марке/модели автомобиля'
};

export const SEARCH_MENU_ITEMS_FOR_MOBILE_SCREEN = {
  searchAutoNumber: 'ГРЗ',
  searchVINNumber: 'Артикул/VIN номер',
  searchModelAuto: 'Марка/модель'
};

export const headers = {
  brand: 'Модификация',
  year: 'Год выпуска',
  engine_type: 'Тип двигателя',
  power: 'Мощность',
  engine_code: 'Код двигателя',
  engine_volume: 'Объем, л.'
};

export const steps = [
  { label: 'Шаг 1', caption: 'Марка и модель' },
  { label: 'Шаг 2', caption: 'Поколение' },
  { label: 'Шаг 3', caption: 'Модификация' }
];

export const SELECT_CAR_STEP = 1;
export const SELECT_GENERATION_STEP = 2;
export const SELECT_MODIFICATION_STEP = 3;

export const searchAutoNumber = 'searchAutoNumber';
export const searchVINNumber = 'searchVINNumber';
export const enterNumberText = 'Введите артикул или VIN номер';
export const AUTO_NUMBER_MASK = 'A 000 AA | 00';
export const searchModelAuto = 'searchModelAuto';
