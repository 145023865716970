import FilterImg from 'src/assets/recommendations/filter.png';

export const recommendationsDefaultData = [
  {
    id: 1,
    img: FilterImg,
    name: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    description: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    vendorCode: '26300-2Y500',
    brand: 'Hyundai — KIA',
    distributorId: '26300-2Y500',
    price: 354,
    url: '/',
    rest: 24,
    days: 24,
    availability: 24,
    deliveryPeriod: 2,
    selected: false
  },
  {
    id: 2,
    img: FilterImg,
    name: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    description: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    vendorCode: '26300-2Y500',
    brand: 'Hyundai — KIA',
    distributorId: '26300-2Y500',
    price: 354,
    url: '/',
    rest: 2,
    days: 2,
    availability: 24,
    deliveryPeriod: 2,
    selected: false
  },
  {
    id: 3,
    img: FilterImg,
    name: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    description: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    vendorCode: '26300-2Y500',
    brand: 'Hyundai — KIA',
    distributorId: '26300-2Y500',
    price: 354,
    url: '/',
    rest: 2,
    days: 2,
    availability: 24,
    deliveryPeriod: 2,
    selected: false
  },
  {
    id: 4,
    img: FilterImg,
    name: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    description: 'Фильтр масляный 26300-2Y500/AMD.FL14 AMD - 263002Y500',
    vendorCode: '26300-2Y500',
    distributorId: '26300-2Y500',
    brand: 'Hyundai — KIA',
    price: 354,
    url: '/',
    rest: 2,
    days: 2,
    availability: 24,
    deliveryPeriod: 2,
    selected: false
  }
];
