export const convertHoursToDays = (hours: number): number => {
  if (hours < 24) {
    return 1;
  }

  const daysWithoutRounding = hours / 24;
  const hasFractionalPart = daysWithoutRounding % 1 >= 0.5;

  return Math.floor(daysWithoutRounding) + (hasFractionalPart ? 1 : 0);
};
