import { Text } from '@uremont/erp-ui';
import React from 'react';

import { AdditionalInfoWithLinksProps } from 'src/features/AppLayout/components/Footer/lib/AdditionalInfoWithLinks/types';
import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';

export const AdditionalInfoWithLinks = ({ additionalInfoLinks }: AdditionalInfoWithLinksProps) => {
  return (
    <Text
      className={styles.additionalInfoText_container}
      data-testid={dataTestId.ADDITIONAL_INFO_WITH_LINKS}
    >
      <Text className={styles.additionalInfoText} variant='caption'>
        Этот сайт защищен reCAPTCHA. Применяется
      </Text>
      {additionalInfoLinks.map((info, index) => (
        <React.Fragment key={index}>
          <Text className={styles.additionalInfoText} variant='caption'>
            {index !== 0 && ' и '}
          </Text>
          <Text
            as={'a'}
            className={`${styles.additionalInfoText} ${styles.additionalInfoLink}`}
            href={info.link}
            rel={'noopener noreferrer nofollow'}
            target={'_blank'}
            variant='caption'
          >
            {info.title}
          </Text>
        </React.Fragment>
      ))}
    </Text>
  );
};
