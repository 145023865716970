import * as Yup from 'yup';

import * as expect from '@utils/validationExpect';
import * as messages from '@utils/validationMessages';

import { SearchSchema } from '../../ui/types';

// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
export const searchFormSchema: Yup.SchemaOf<SearchSchema> = Yup.object()
  .shape({
    phone: Yup.string()
      .test('phone', messages.correctPhone, expect.phoneNumber)
      .required(messages.required),
    comment: Yup.string()
      .max(300, messages.maxLength(300))
      .min(10, messages.minLength(10))
      .required(messages.required)
  })
  .required(messages.required);
