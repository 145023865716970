import React from 'react';
import { Button, Checkbox, Cluster, Input, InputPhone, Text, Textarea } from '@uremont/erp-ui';
import cx from 'classnames';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { FilePreview } from '../FilePreview/ui';
import styles from './styles.module.scss';
import { InputsFormProps } from './types';

export const InputsForm = ({
  errors,
  values,
  handleChange,
  handleSubmit,
  dragOver,
  fileDrop,
  onChangeFileInput,
  isInputName = false,
  isCommentInput = false,
  isInputMail = false,
  isInputPhone = false,
  isInputFile = false,
  isLotFiles = false,
  isShowCheckbox = false,
  isModal = false,
  deleteFile,
  touched,
  handleBlur,
  submitDisabled = false
}: InputsFormProps) => {
  return (
    <form
      className={styles.formContainer}
      data-testid={dataTestId.COLLABORATE_VIEW_FORM}
      onSubmit={handleSubmit}
    >
      <div
        className={cx(styles.form, {
          [styles.isModal]: isModal
        })}
      >
        <div className={styles.form_column}>
          {isInputName && (
            <div className={styles.inputWrap}>
              <label className={styles.form_label} htmlFor='nameFormInput'>
                <Text className={styles.form_label_caption} variant='caption-regular'>
                  Фамилия и имя
                </Text>
              </label>
              <Input
                className={styles.form_input}
                data-testid={dataTestId.COLLABORATE_VIEW_FORM_INPUT_NAME}
                name='name'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='Константинопольский Константин'
                value={values.name}
              />
              {touched?.name && errors?.name && (
                <Text color='danger' variant='caption'>
                  {errors?.name}
                </Text>
              )}
            </div>
          )}
          {isInputPhone && (
            <div className={styles.inputWrap}>
              <label className={styles.form_label} htmlFor='phoneFormInput'>
                <Text className={styles.form_label_caption} variant='caption-regular'>
                  Номер телефона
                </Text>
              </label>
              <InputPhone
                className={styles.form_input}
                data-testid={dataTestId.COLLABORATE_VIEW_FORM_INPUT_PHONE}
                name='phone'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='+7(900) 999-99-99'
                value={values.phone}
              />
              {errors?.phone && (
                <Text color='danger' variant='caption'>
                  {errors?.phone}
                </Text>
              )}
            </div>
          )}
          {isInputMail && (
            <div className={styles.inputWrap}>
              <label className={styles.form_label} htmlFor='emailFormInput'>
                <Text className={styles.form_label_caption} variant='caption-regular'>
                  Почта
                </Text>
              </label>
              <Input
                className={styles.form_input}
                data-testid={dataTestId.COLLABORATE_VIEW_FORM_INPUT_EMAIL}
                name='email'
                onBlur={handleBlur}
                onChange={handleChange}
                placeholder='pochta@domen.com'
                value={values.email}
              />
              {touched?.email && errors?.email && (
                <Text color='danger' variant='caption'>
                  {errors?.email}
                </Text>
              )}
            </div>
          )}
        </div>
        <div className={styles.form_column}>
          {isCommentInput && (
            <>
              <div className={styles.form_comment_caption}>
                <label htmlFor='commentFormInput'>
                  <Text className={styles.form_label_caption} variant='caption-regular'>
                    Комментарий или вопрос
                  </Text>
                </label>
                <Text variant='caption'>{`${values.comment?.length}/300`}</Text>
              </div>
              <div className={styles.form_comment}>
                <Textarea
                  data-testid={dataTestId.COLLABORATE_VIEW_FORM_INPUT_COMMENT}
                  maxLength={500}
                  name='comment'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  placeholder='Введите сюда ваш вопрос или комментарий'
                  rows={6}
                  value={values.comment}
                />
                {touched?.comment && errors?.comment && (
                  <Text color='danger' variant='caption'>
                    {errors?.comment}
                  </Text>
                )}
              </div>
            </>
          )}
          {isInputFile && (
            <Cluster vGap='md'>
              <FilePreview
                deleteFile={deleteFile}
                dragOver={dragOver}
                file={values.file}
                fileDrop={fileDrop}
                isLotFiles={isLotFiles}
                onChangeFileInput={onChangeFileInput}
              />
            </Cluster>
          )}
        </div>
      </div>
      <div
        className={cx(styles.form_bottom, {
          [styles.isModal]: isModal
        })}
      >
        <div className={styles.form_column}>
          {isShowCheckbox && (
            <>
              <Checkbox
                boxAlign='start'
                checked={values.agreement}
                className={styles.form_checkbox}
                crossOrigin='anonymous'
                data-testid={dataTestId.COLLABORATE_VIEW_FORM_INPUT_AGREEMENT}
                name='agreement'
                onChange={handleChange}
                children={
                  <Text variant='footnote'>
                    Даю согласие на обработку своих персональных данных на условиях,
                    <a
                      data-testid={dataTestId.COLLABORATE_VIEW_FORM_INPUT_URL_AGREEMENT}
                      href='/'
                      rel='nofollow noreferrer noopener'
                      target='_blank'
                    >
                      &nbsp; указанных здесь
                    </a>
                  </Text>
                }
              />
              {touched?.agreement && errors?.agreement && (
                <Text color='danger' variant='caption'>
                  {errors?.agreement}
                </Text>
              )}
            </>
          )}
        </div>
        <div
          className={cx(styles.form_column, {
            [styles.isModal]: isModal
          })}
        >
          <Button
            data-testid={dataTestId.COLLABORATE_VIEW_BTN_SUBMIT}
            disabled={submitDisabled}
            onClick={() => handleSubmit()}
            variant='solid'
            className={cx(styles.form_btnSubmit, {
              [styles.isModal]: isModal
            })}
          >
            <Text className={styles.form_btnSubmit_text} variant='body-tight'>
              Отправить
            </Text>
          </Button>
        </div>
      </div>
    </form>
  );
};
