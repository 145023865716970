import React from 'react';
import { Input, Text } from '@uremont/erp-ui';

import { dataTestId } from 'src/shared/constants/dataTestId';

import { GRZInputViewProps } from './types';
import styles from './styles.module.scss';

export const GRZInputView = ({
  action,
  onChange,
  handleButtonClick,
  placeholder,
  value
}: GRZInputViewProps) => {
  return (
    <div className={styles.inputWrap}>
      <Input
        action={action}
        className={styles.input}
        data-testid={dataTestId.GRP_INPUT_VIEW}
        onChange={onChange}
        onKeyDown={e => e.key === 'Enter' && handleButtonClick()}
        value={value}
      />
      <Text className={styles.input_placeholderGRZ} variant='body-tight'>
        {placeholder}
      </Text>
    </div>
  );
};
