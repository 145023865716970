import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware';

import { auth } from 'src/shared/model/Store/Auth/service';

import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { AuthStore } from './types';

export const useAuthStore = create(
  devtools(
    persist<AuthStore>(
      set => ({
        isAuthorized: false,
        isLoading: false,
        auth: async () => {
          await executeRequest(
            auth,
            isLoading => set({ isLoading }),
            response => set({ isAuthorized: response ? response.success : false })
          );
        }
      }),
      {
        name: 'auth-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
