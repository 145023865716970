import React from 'react';
import { Button, Text } from '@uremont/erp-ui';

import { CustomSelect } from '@shared/components/CustomSelect/CustomSelect';
import { OrderCard } from '@shared/components/OrderCard/OrderCard';
import { dataTestId } from '@shared/constants/dataTestId';

import { HistoryOrdersPageProps } from './types';
import styles from './styles.module.scss';

export const HistoryOrdersPageView = ({
  selectedYear,
  handleSelectChange,
  orderCardData,
  isLoading,
  years,
  showBtnAll,
  showAll,
  handleShowAll,
  handleRepeatOrder
}: HistoryOrdersPageProps) => (
  <div className={styles.orderContainer} data-testid={dataTestId.ORDER_PAGE}>
    <Text className={styles.orderContainer_headerText} variant='header'>
      Заказы
    </Text>
    {orderCardData.length > 0 ? (
      <>
        <CustomSelect
          className={styles.orderContainer_select}
          data-testid={dataTestId.ORDER_SELECT}
          defaultValue={years[0]}
          onChange={handleSelectChange}
          options={years}
        />
        <div className={styles.orderContainer_chipWrapper}>
          {years.slice(1).map(
            (item, index) =>
              (selectedYear === years[0] || selectedYear === item) && (
                <Text
                  className={styles.orderContainer_chipWrapper_chip}
                  key={index}
                  variant='caption-regular'
                >
                  {item}
                </Text>
              )
          )}
        </div>
        {orderCardData.map(orderItem => (
          <OrderCard
            data-testid={`${dataTestId.ORDER_CARD_DATA}-${orderItem.id}`}
            dataOrder={orderItem}
            handleRepeatOrder={handleRepeatOrder}
            isLoading={isLoading}
            key={orderItem.id}
            orderCardData={orderCardData}
          />
        ))}
        {showBtnAll && (
          <Button
            className={styles.orderContainer_btn}
            data-testid={dataTestId.ORDER_CARD_DATA_BTN_SEARCH}
            onClick={handleShowAll}
            size='md'
          >
            <Text className={styles.orderContainer_btn_text} variant='body-tight'>
              {!showAll ? 'Скрыть' : 'Больше заказов'}
            </Text>
          </Button>
        )}
      </>
    ) : (
      <Text className={styles.orderContainer_empty} variant='caption'>
        История заказов пуста
      </Text>
    )}
  </div>
);
