import React from 'react';
import { Button, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { ReactComponent as MinusIcon } from '@assets/MinusIcon.svg';
import { ReactComponent as PlusIcon } from '@assets/PlusIcon.svg';
import { dataTestId } from '@shared/constants/dataTestId';

import { AddCartBasketViewProps } from './types';
import styles from './styles.module.scss';

export const AddCardBasketView = ({
  id,
  className,
  count,
  handleDelete,
  handleAdd,
  isDisableDeleteItem,
  isDisableAddMoreItem
}: AddCartBasketViewProps) => {
  return (
    <div className={cx(styles.btnWrap, className)}>
      <div className={styles.btnWrap_counterWrap}>
        <Button
          className={styles.btnWrap_counterWrap_btnChange}
          data-testid={`${dataTestId.PRODUCT_PAGE_MINUS}-${id}`}
          disabled={isDisableDeleteItem}
          onClick={handleDelete}
        >
          <MinusIcon />
        </Button>
        <Text className={styles.btnWrap_counterWrap_count} variant='body-tight'>
          {count}
        </Text>
        <Button
          className={styles.btnWrap_counterWrap_btnChange}
          data-testid={`${dataTestId.PRODUCT_PAGE_PLUS}-${id}`}
          disabled={isDisableAddMoreItem}
          onClick={handleAdd}
        >
          <PlusIcon />
        </Button>
      </div>
    </div>
  );
};
