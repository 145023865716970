import { useEffect } from 'react';

export const useScrollToTop = () => {
  useEffect(() => {
    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    scrollToTop();

    return () => {
      window.removeEventListener('scroll', scrollToTop);
    };
  }, []);
};
