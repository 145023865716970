import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { SearchRequestStore } from '@shared/model/Store/SearchRequest/types';
import { searchRequest } from '@shared/model/Store/SearchRequest/service';
import { toast } from 'react-toastify';

export const useSearchRequestStore = create(
  devtools(
    persist<SearchRequestStore>(
      set => ({
        isSuccess: false,
        isLoading: false,

        searchRequest: async values => {
          await executeRequest(
            () => searchRequest(values),
            isLoading => set({ isLoading }),
            response => {
              if (response?.success) {
                toast.success('Заявка успешно отправлена');
              }

              set({ isSuccess: response ? response.success : false });
            }
          );
        }
      }),
      {
        name: 'searchRequest-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
