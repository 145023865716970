export const executeRequest = async <T>(
  apiCall: () => Promise<T>,
  setLoading: (isLoading: boolean) => void,
  setData: (data: T) => void
): Promise<void> => {
  try {
    setLoading(true);
    const response: T = await apiCall();
    setData(response);
  } finally {
    setLoading(false);
  }
};
