import { fetchData } from 'src/shared/api/apiConfig';
import {
  OrderResponse,
  PlaceAnOrderParams,
  PlaceAnOrderResponse
} from 'src/shared/model/Store/Orders/types';

export const getOrderInformation = async (): Promise<OrderResponse[] | void> =>
  fetchData('getOrderInformation');

export const placeAnOrder = async (
  values: PlaceAnOrderParams,
  id: string
): Promise<PlaceAnOrderResponse | void> => fetchData('placeAnOrder', values, { basketId: id });
