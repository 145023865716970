import {
  CHOICE_AUTO_BREADCRUMBS,
  HOME_BREADCRUMBS,
  SPARE_PARTS_BREADCRUMBS
} from '@shared/constants/breadcrumbsText';
import { pathConfig } from '@shared/constants/pathConfig';

export const breadCrumbsForCatalogCategory = [
  {
    active: false,
    href: pathConfig.MainPath,
    label: HOME_BREADCRUMBS
  },
  {
    active: false,
    href: pathConfig.PartsCatalogByCarPath,
    label: CHOICE_AUTO_BREADCRUMBS
  },
  {
    active: true,
    label: SPARE_PARTS_BREADCRUMBS
  }
];
