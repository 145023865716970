import { EMPTY_OBJECT } from '@shared/constants/fallbacks';
import { AccordionItem, AccordionItemLink } from '@shared/components/CustomAccordion/types';

const skeletonRenderFAQDataLink = Array.from(
  { length: 3 },
  () => EMPTY_OBJECT as AccordionItemLink
);

export const skeletonRenderFAQData = Array.from({ length: 3 }, () => ({
  ...(EMPTY_OBJECT as AccordionItem),
  links: skeletonRenderFAQDataLink
}));
