import React, { useId } from 'react';
import { FilePreviewView } from './FilePreviewView';
import { FilePreviewProps } from './types';

export const FilePreview = ({
  isLotFiles = false, // Если можно прикреплять больше одного файла, нужно передавать true
  onChangeFileInput,
  file,
  dragOver,
  fileDrop,
  deleteFile
}: FilePreviewProps) => {
  const id = useId();
  const showInput = isLotFiles || !file?.length;

  return (
    <FilePreviewView
      deleteFile={deleteFile}
      dragOver={dragOver}
      file={file}
      fileDrop={fileDrop}
      id={id}
      onChangeFileInput={onChangeFileInput}
      showInput={showInput}
    />
  );
};
