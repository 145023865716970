export const addressRequired = 'Необходимо выбрать населенный пункт';
export const validDate = 'Введите корректную дату';
export const validTime = 'Введите корректное время';
export const validEmail = 'Введите корректный email';
export const validNumber = 'Введите корректный номер';
export const validPhone = 'Введите корректный номер телефона';
export const validLicensePlate = 'Введите корректный госномер';
export const validVin = 'Введите корректный vin';
export const cyrillic = 'Поле может содержать только кириллицу';
export const cyrillicAndNumber = 'Поле может содержать только кириллицу и цифры';
export const latinAndNumber = 'Поле может содержать только латинницу и цифры';
export const cyrillicAndLatin = 'Поле может содержать только кириллицу или латиницу';
export const cyrillicAndLatinAndNumber = 'Поле может содержать только кириллицу, латинницу и цифры';
export const correctEmail = 'Введите корректный email';
export const correctPhone = 'Введите корректный номер телефона';
export const numeric = 'Это поле может содержать только цифры';
export const passwordConfirm = 'Пароли должны совпадать';
export const required = 'Это поле обязательно для заполнения';
export const checkCountry = 'Выберите страну';
export const checkRegion = 'Выберите регион';
export const checkCity = 'Выберите город';
export const checkDistrict = 'Выберите округ';
export const checkMetro = 'Выберите метро';
export const carModelMark = 'Выберите марку';
export const carModel = 'Выберите модель';
export const correctName = 'Введите корректное значание';
export const withoutSpace = 'Поле может содержать только кириллицу без пробелов';

export const maxLength = (count: number) => `Максимальное количество символов: ${count}`;
export const minLength = (count: number) => `Минимальное количество символов: ${count}`;
