import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { persist, createJSONStorage } from 'zustand/middleware';
import { EMPTY_ARRAY } from 'src/shared/constants/fallbacks';
import { executeRequest } from 'src/shared/functions/executeRequest/executeRequest';
import { pathConfig } from 'src/shared/constants/pathConfig';
import { CarCatalogStore } from './types';
import {
  getCarBrands,
  getCarGenerations,
  getCarGroupParts,
  getCarModels,
  getCarSubGroupParts
} from './service';

export const useCarCatalogStore = create(
  devtools(
    persist<CarCatalogStore>(
      set => ({
        carBrands: EMPTY_ARRAY,
        carModels: EMPTY_ARRAY,
        carGenerations: null,
        carGroupParts: EMPTY_ARRAY,
        subgroupParts: null,
        nestedGroup: EMPTY_ARRAY,
        selectedModel: null,
        isLoadingCarBrands: false,
        isLoadingCarModels: false,
        isLoadingCarGenerations: false,
        isLoadingCarGroupParts: false,
        isLoadingSubgroupParts: false,
        isLoading: false,

        getCarBrands: async () => {
          await executeRequest(
            getCarBrands,
            isLoading => set({ isLoadingCarBrands: isLoading }),
            response => set({ carBrands: response ?? EMPTY_ARRAY })
          );
        },

        getCarModels: async brand => {
          await executeRequest(
            () => getCarModels(brand),
            isLoading => set({ isLoadingCarModels: isLoading }),
            response => set({ carModels: response ?? EMPTY_ARRAY })
          );
        },

        getCarGenerations: async (brand, modelId) => {
          await executeRequest(
            () => getCarGenerations(brand, modelId),
            isLoading => set({ isLoadingCarGenerations: isLoading }),
            response => set({ carGenerations: response ? response : null })
          );
        },

        getCarGroupParts: async (
          brand,
          carId,
          navigateToPage,
          groupId,
          isSearchByCarHandler = false
        ) => {
          await executeRequest(
            () => getCarGroupParts(brand, carId, groupId),
            isLoading => set({ isLoadingCarGroupParts: isLoading }),
            response => {
              set({
                [isSearchByCarHandler ? 'nestedGroup' : 'carGroupParts']: response ?? EMPTY_ARRAY
              });

              if (response && !isSearchByCarHandler) {
                navigateToPage(pathConfig.CatalogPath);
              }
            }
          );
        },

        getCarSubGroupParts: async (brand, carId, groupId) => {
          await executeRequest(
            () => getCarSubGroupParts(brand, carId, groupId),
            isLoading => set({ isLoadingSubgroupParts: isLoading }),
            response => set({ subgroupParts: response ? response : null })
          );
        },

        setSelectedModel: model => {
          set({
            selectedModel: model
          });
        },

        resetCarCatalogData: () => {
          set({
            carBrands: EMPTY_ARRAY,
            carModels: EMPTY_ARRAY,
            carGenerations: null,
            carGroupParts: EMPTY_ARRAY,
            subgroupParts: null,
            selectedModel: null,
            isLoading: false
          });
        }
      }),
      {
        name: 'carCatalog-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
