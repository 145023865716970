import React from 'react';
import { Button, Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';
import Slider from 'react-slick';

import { ReactComponent as PointLogoIcon } from '@assets/basket/PointLogoIcon.svg';
import { dataTestId } from '@shared/constants/dataTestId';
import { PlacingAnOrderMap } from '../../PlacingAnOrderMap';

import { PickupPointViewProps } from './types';
import styles from './styles.module.scss';

export const PickupPointView = ({
  settings,
  slides,
  sliderWrapRef,
  currentPoint,
  slideHeight,
  isLoading,
  handleClickPoint,
  sliderRef
}: PickupPointViewProps) => {
  return (
    <div className={styles.pickupPoint} data-testid={dataTestId.PICKUP_POINT_VIEW}>
      <Text className={styles.pickupPoint_titleText} variant='headline-H2'>
        Пункт самовывоза
      </Text>
      <div ref={sliderWrapRef}>
        <Slider {...settings} ref={sliderRef}>
          {slides.map((item, index) => (
            <div className={styles.pickupPoint_pointWrap} key={item.id}>
              <Button
                data-testid={dataTestId.PICKUP_POINT_VIEW_SLIDE}
                onClick={() => handleClickPoint(index)}
                style={{ height: slideHeight }}
                className={cx(styles.pickupPoint_point, {
                  [styles.pickupPoint_point_active]: item.balloonOpen
                })}
              >
                <div className={styles.pickupPoint_point_top}>
                  <div className={styles.pickupPoint_point_logo}>
                    {isLoading ? <Skeleton circle height='48px' width='48px' /> : <PointLogoIcon />}
                  </div>
                  <Text variant='headline-H3'>
                    {isLoading ? <Skeleton height='24px' width='150px' /> : item.name}
                  </Text>
                </div>
                <Text className={styles.pickupPoint_point_address} color='secondary' variant='body'>
                  {isLoading ? <Skeleton height='48px' width='235px' /> : item.address}
                </Text>
                <div className={styles.pickupPoint_point_timeWrap}>
                  <Text className={styles.pickupPoint_point_time} color='secondary' variant='body'>
                    {isLoading ? <Skeleton height='24px' width='111px' /> : 'Время работы:'}
                  </Text>
                  <Text variant='body'>
                    {isLoading ? <Skeleton height='24px' width='92px' /> : item.time}
                  </Text>
                </div>
                <div className={styles.pickupPoint_point_phoneWrap}>
                  <Text className={styles.pickupPoint_point_phone} color='secondary' variant='body'>
                    {isLoading ? <Skeleton height='24px' width='70px' /> : 'Телефон:'}
                  </Text>
                  <Text variant='body'>
                    {isLoading ? <Skeleton height='24px' width='146px' /> : item.phone}
                  </Text>
                </div>
              </Button>
            </div>
          ))}
        </Slider>
      </div>
      <PlacingAnOrderMap
        currentPoint={currentPoint}
        handleClickPoint={handleClickPoint}
        isLoading={isLoading}
        points={slides}
      />
    </div>
  );
};
