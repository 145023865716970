export const headers = [
  'Марка',
  'Год',
  'Двигатель',
  'Мощность',
  'Трансмиссия',
  'Тип коробки передач',
  'Тип кузова',
  'Привод',
  'Рулевое управление',
  'Код двигателя',
  'Регион',
  'Объем, л.'
];
