import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { EMPTY_ARRAY, EMPTY_STRING } from '@shared/constants/fallbacks';
import { useScrollToTop } from '@shared/hooks/use-scroll-to-top';
import { InfoCardDataType } from '@shared/components/InfoCard/types';
import { getDetailInfo } from '../lib/functions';
import { breadCrumbsForCatalogProduct } from '../lib/constants/breadCrumbsForCatalogProduct';
import { CatalogProductView } from './CatalogProductView';

export const CatalogProduct = () => {
  const {
    category,
    product,
    brand = EMPTY_STRING,
    carId,
    name_detail = EMPTY_STRING,
    year_detail = EMPTY_STRING,
    region_detail = EMPTY_STRING,
    description_detail = EMPTY_STRING
  } = useParams();

  const navigate = useNavigate();

  const { subgroupParts, isLoadingSubgroupParts, getCarSubGroupParts } = useCarCatalog();

  const [activeCardIndexes, setActiveCardIndexes] = useState<(number | null)[]>(EMPTY_ARRAY);
  const [currentTransformedSelectedModelData, setCurrentTransformedSelectedModelData] =
    useState<InfoCardDataType['infoCardData']>(EMPTY_ARRAY);

  const handleActiveChevronClick = (layerIndex: number, cardIndex: number) => {
    setActiveCardIndexes(prevState => {
      const newState = [...prevState];
      newState[layerIndex] = newState[layerIndex] === cardIndex ? null : cardIndex;

      return newState.map((state, index) => (index > layerIndex ? null : state));
    });
  };
  const goBack = () => {
    navigate(-1);
  };
  const isCardIndexActive = (layerIndex: number, cardIndex: number) =>
    activeCardIndexes[layerIndex] === cardIndex;

  useScrollToTop();

  useEffect(() => {
    if (brand && carId && category) {
      const transformedBrand = brand.toLowerCase().replace(/\s+/g, '-');

      getCarSubGroupParts(transformedBrand, carId, category);
    }
  }, [brand, carId, category]);

  useEffect(() => {
    const detailInfoCar = getDetailInfo([
      name_detail,
      year_detail,
      region_detail,
      description_detail
    ]);
    setCurrentTransformedSelectedModelData(detailInfoCar);
  }, [name_detail, year_detail, region_detail, description_detail]);

  return (
    <CatalogProductView
      brand={brand}
      breadcrumbs={breadCrumbsForCatalogProduct}
      goBack={goBack}
      handleActiveChevronClick={handleActiveChevronClick}
      isCardIndexActive={isCardIndexActive}
      isLoading={isLoadingSubgroupParts}
      subgroupParts={subgroupParts}
      title={product}
      transformedSelectedModelData={currentTransformedSelectedModelData}
    />
  );
};
