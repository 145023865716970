import React from 'react';
import { Text } from '@uremont/erp-ui';

import { OrderCardItemList } from '@pages/PlacingAnOrder/lib/OrderCardItemList/OrderCardItemList';

import { LocationAffectedDeliveryViewyProps } from './types';
import styles from './styles.module.scss';

export const LocationAffectedDeliveryView = ({
  orderCardData,
  address,
  isLoading
}: LocationAffectedDeliveryViewyProps) => (
  <div className={styles.locationAffectedDelivery}>
    <div className={styles.locationAffectedDelivery_header}>
      <Text variant='headline-H2'>Детали заказа</Text>
      <Text className={styles.locationAffectedDelivery_addressText} color='secondary'>
        {address}
      </Text>
    </div>
    <OrderCardItemList isLoading={isLoading} orderCardData={orderCardData} />
  </div>
);
