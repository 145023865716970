export const capitalizeFirstLetter = (str: string): string => {
  if (str.length === 0) {
    return str;
  }

  const words = str.split(' ');
  const capitalizedWords = words.map((word, index) =>
    index === 0 ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() : word.toLowerCase()
  );

  return capitalizedWords.join(' ');
};
