import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholder.png';

import { CardContentProps } from '@shared/components/CardContent/types';
import styles from './styles.module.scss';

export const CardContent = ({ img, name, isLoading, onClick }: CardContentProps) => {
  return (
    <div onClick={onClick}>
      {isLoading ? (
        <Skeleton className={styles.cardContent_img_skeleton} />
      ) : (
        <img alt='Изображение' height='264px' src={img || picturePlaceholder} width='264px' />
      )}
      <Text className={styles.cardText} variant='sub-headline'>
        {isLoading ? <Skeleton height='28px' width='100px' /> : name}
      </Text>
    </div>
  );
};
