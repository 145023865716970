import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';

import { EMPTY_STRING, noop } from '@shared/constants/fallbacks';
import { RenderDetailedDescriptionView } from '@pages/CatalogProduct/lib/RenderDetailedDescription/RenderDetailedDescriptionView';
import { Position } from '@shared/model/Store/CarCatalog/types';
import { useScreenSizeBreakpoints } from '@shared/hooks/use-screen-size-breakpoints';

import { RenderDetailedDescriptionTypes } from './types';

export const RenderDetailedDescription = ({
  handleActiveChevronClick = noop,
  subgroupParts,
  isCardIndexActive,
  isLoading,
  brand
}: RenderDetailedDescriptionTypes) => {
  const imageRef = useRef<HTMLImageElement>(null);
  const infoRef = useRef<HTMLImageElement>(null);
  const { category = EMPTY_STRING, product = EMPTY_STRING } = useParams();

  const { windowWidthSize } = useScreenSizeBreakpoints();

  const [selectedDetail, setSelectedDetail] = useState(EMPTY_STRING);
  const [clickedDetail, setClickedDetail] = useState(EMPTY_STRING);
  const [scaleX, setScaleX] = useState(1);
  const [scaleY, setScaleY] = useState(1);
  const [screenWidthDiff, setScreenWidthDiff] = useState(1);
  const [screenHeightDiff, setScreenHeightDiff] = useState(1);
  const [currentWidth, setCurrentWidth] = useState(1);
  const [currentHeight, setCurrentHeight] = useState(560);

  const [isZoomActive, setIsZoomActive] = useState(false);
  const [magnifierPosition, setMagnifierPosition] = useState({ x: 0, y: 0 });
  const partUrlProduct = `/catalog/${category}/${product}`;

  const handleDetailHover = (number: string) => {
    setSelectedDetail(number);
  };

  const handleListItemClick = (number: string) => {
    setClickedDetail(number);
    setIsZoomActive(false);
  };

  const handleMouseAction = (
    event: React.MouseEvent<HTMLImageElement>,
    details: Position[],
    isClick: boolean
  ) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

    setMagnifierPosition({ x, y });

    for (const { coordinates, number } of details) {
      const [detailX, detailY, detailWidth, detailHeight] = coordinates;

      if (
        x >= detailX * scaleX &&
        x <= (detailX + detailWidth) * scaleX &&
        y >= detailY * scaleY &&
        y <= (detailY + detailHeight) * scaleY
      ) {
        isClick ? setClickedDetail(number) : setSelectedDetail(number);

        return;
      }
    }

    setSelectedDetail(EMPTY_STRING);
  };

  const handleMouseHover = (event: React.MouseEvent<HTMLImageElement>, details: Position[]) => {
    handleMouseAction(event, details, false);
  };

  const handleDetailClick = (event: React.MouseEvent<HTMLImageElement>, details: Position[]) => {
    handleMouseAction(event, details, true);
  };

  const handleImageClick = () => {
    setIsZoomActive(!isZoomActive);
  };

  const handleImageFirstLoad = (imageElement: HTMLImageElement) => {
    const { width, height, naturalWidth, naturalHeight } = imageElement;

    setScreenHeightDiff(naturalHeight / height - 1 || 1);
    setScreenWidthDiff(naturalWidth / width - 1 || 1);
  };

  const handleImageLoad = (imageElement: HTMLImageElement) => {
    const { width, height, naturalWidth, naturalHeight } = imageElement;
    const computedScaleX = width / naturalWidth || 1;
    const computedScaleY = height / naturalHeight || 1;

    setScaleX(computedScaleX);
    setScaleY(computedScaleY);
  };

  const magnifierTransformStyle = {
    transform: isZoomActive
      ? `translate(${-magnifierPosition.x * screenWidthDiff}px, ${
          -magnifierPosition.y * screenHeightDiff
        }px)`
      : 'none'
  };

  const cardItemStyle = (coordinates: number[]) => {
    return {
      width: `${coordinates[2] * scaleX}px`,
      height: `${coordinates[3] * scaleY}px`,
      transform: `translate(${coordinates[0] * scaleX}px, ${coordinates[1] * scaleY}px)`
    };
  };

  const copyText = (text: string) => {
    navigator.clipboard.writeText(text);
    toast.success(`Текст "${text}" скопирован в буфер обмена`);
  };

  useEffect(() => {
    const imageElement = imageRef.current;
    if (imageElement) {
      const handleLoad = () => {
        setCurrentWidth(imageElement.width);
        setCurrentHeight(imageElement.height);
        handleImageFirstLoad(imageElement);
      };

      imageElement.addEventListener('load', handleLoad);

      return () => {
        imageElement.removeEventListener('load', handleLoad);
      };
    }
  }, [imageRef, handleImageFirstLoad]);

  useEffect(() => {
    const imageElement = imageRef.current;

    if (imageElement) {
      handleImageLoad(imageElement);

      if (imageElement.complete) {
        handleImageLoad(imageElement);
      } else {
        imageElement.addEventListener('load', () => handleImageLoad(imageElement));
      }

      return () => {
        imageElement.removeEventListener('load', () => handleImageLoad(imageElement));
      };
    }
  }, [imageRef, handleMouseAction, windowWidthSize]);

  useEffect(() => {
    const selectedDetailElement = document.getElementById(`detail-${clickedDetail}`);
    if (!selectedDetailElement) return;

    const scrollToDetail = (element: HTMLElement, isMobileView: boolean) => {
      if (infoRef.current && !isMobileView) {
        const topPos = element.offsetTop - infoRef.current.offsetTop;
        infoRef.current.scrollTo({ top: topPos, behavior: 'smooth' });
      } else {
        element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'start' });
      }
    };

    scrollToDetail(selectedDetailElement, windowWidthSize <= 1032);
  }, [clickedDetail, windowWidthSize]);

  return (
    <RenderDetailedDescriptionView
      brand={brand}
      cardItemStyle={cardItemStyle}
      clickedDetail={clickedDetail}
      copyText={copyText}
      currentHeight={currentHeight}
      currentWidth={currentWidth}
      handleActiveChevronClick={handleActiveChevronClick}
      handleDetailClick={handleDetailClick}
      handleDetailHover={handleDetailHover}
      handleImageClick={handleImageClick}
      handleListItemClick={handleListItemClick}
      handleMouseHover={handleMouseHover}
      imageRef={imageRef}
      infoRef={infoRef}
      isCardIndexActive={isCardIndexActive}
      isLoading={isLoading}
      isZoomActive={isZoomActive}
      magnifierTransformStyle={magnifierTransformStyle}
      partUrlProduct={partUrlProduct}
      selectedDetail={selectedDetail}
      subgroupParts={subgroupParts}
    />
  );
};
