import React from 'react';
import { Dropdown, Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';

import { ArticleDropdownProps } from './types';
import styles from './styles.module.scss';

export const ArticleDropdown = ({
  dropdownRef,
  articleTips,
  handleDropdownItemClick,
  searchValue,
  isLoading
}: ArticleDropdownProps) => {
  return (
    <div className={styles.dropdownWrapper}>
      <Dropdown
        className={styles.dropdown}
        data-testid={dataTestId.SEARCH_DROPDOWN}
        ref={dropdownRef}
      >
        {isLoading
          ? Array.from({ length: 4 }).map((_, index) => (
              <div
                className={cx(styles.itemsWrapper_item, styles.itemsWrapper_item_skeleton)}
                key={index}
              >
                <Skeleton height='24px' width='100%' />
              </div>
            ))
          : articleTips.map((tip, index) => {
              return (
                <div
                  className={styles.itemsWrapper_item}
                  data-testid={`${dataTestId.SEARCH_LABEL}-${index}`}
                  key={index}
                  onClick={() => handleDropdownItemClick(tip)}
                >
                  <Text className={styles.itemsWrapper_item_label} variant='title'>
                    {tip.brand}
                  </Text>
                  <div className={styles.itemsWrapper_item_label_numberContainer}>
                    {tip?.number?.split(EMPTY_STRING).map((digit, i) => (
                      <span
                        key={i}
                        className={cx({
                          [styles.itemsWrapper_item_label_numberContainer_bold]:
                            digit === searchValue[i]
                        })}
                      >
                        {digit}
                      </span>
                    ))}
                  </div>
                  <Text className={styles.itemsWrapper_item_label} color='secondary'>
                    {tip.description}
                  </Text>
                </div>
              );
            })}
      </Dropdown>
    </div>
  );
};
