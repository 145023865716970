import { Button, Input, Text, plural } from '@uremont/erp-ui';
import React from 'react';

import { headers } from 'src/pages/SearchByVIN/lib/constants/consts';
import { SearchByVINViewProps } from 'src/pages/SearchByVIN/ui/types';
import { Breadcrumbs } from 'src/shared/components/Breadcrumbs';
import { breadcrumbsForSearchLinks } from 'src/shared/constants/breadcrumbsForSearchLinks';
import { dataTestId } from 'src/shared/constants/dataTestId';
import {
  BANNER_BUTTON_TEXT,
  BANNER_SUBTITLE_TEXT,
  BANNER_TITLE_TEXT
} from 'src/shared/constants/ texts';
import { CustomTable } from 'src/shared/components/CustomTable';
import { RequestBanner } from 'src/shared/components/RequestBanner';
import { EMPTY_REQUEST_TEXT } from 'src/shared/constants/ texts';
import { CustomModal } from 'src/shared/components/CustomModal/CustomModal';
import styles from './styles.module.scss';

export const SearchByVINView = ({
  Icon,
  VINValue,
  searchWithClearButton,
  handleSearchChange,
  searchValue,
  hasSearchResults,
  carsModificationData,
  selectedRow,
  handleRadioChange,
  handleNext,
  renderChildren,
  toggleModal,
  isOpenModal,
  isLoadingCarGroupParts
}: SearchByVINViewProps) => {
  return (
    <div className={styles.searchContainer} data-testid={dataTestId.SEARCH_BY_VIN_VIEW}>
      <Breadcrumbs links={breadcrumbsForSearchLinks} />
      <Input
        action={searchWithClearButton}
        className={styles.searchContainer_input}
        data-testid={dataTestId.SEARCH_BY_VIN_VIEW_SEARCH_INPUT_BTN}
        onChange={handleSearchChange}
        placeholder='Введите VIN'
        value={searchValue}
      />
      <div className={styles.tableContainer}>
        <Text className={styles.tableContainer_titleText} variant='header'>
          {hasSearchResults ? (
            <>
              По запросу <span className={styles.tableContainer_vinText}>{VINValue}</span>
              &nbsp;{plural(hasSearchResults, 'найден', 'найдено', 'найдены')} {hasSearchResults}
              &nbsp;{plural(hasSearchResults, 'автомобиль', 'автомобиля', 'автомобилей')}
            </>
          ) : (
            EMPTY_REQUEST_TEXT
          )}
        </Text>
        {Boolean(hasSearchResults) && (
          <>
            <Text className={styles.tableContainer_subTitleText} variant='headline'>
              Выберите модификацию
            </Text>
            <div className={styles.tableContainer_table}>
              <CustomTable
                data={carsModificationData}
                handleRadioChange={handleRadioChange}
                headers={headers}
                isLoading={false}
                selectedRow={selectedRow}
              />
            </div>
            <div className={styles.tableContainer_buttonContainer}>
              <Button
                data-testid={dataTestId.SEARCH_BY_VIN_VIEW_BTN}
                loading={isLoadingCarGroupParts}
                onClick={handleNext}
                variant='solid'
              >
                Далее
              </Button>
            </div>
          </>
        )}
      </div>
      <div className={styles.tableContainer_requestBanner}>
        <RequestBanner
          Icon={Icon}
          buttonText={BANNER_BUTTON_TEXT}
          onPress={toggleModal}
          subTitle={BANNER_SUBTITLE_TEXT}
          title={BANNER_TITLE_TEXT}
        />
      </div>
      <CustomModal
        children={renderChildren}
        isOpen={isOpenModal}
        title='Заявка на поиск запчастей'
        toggleModal={toggleModal}
        width='xs'
      />
    </div>
  );
};
