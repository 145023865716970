import { InfoCardDataType } from '@shared/components/InfoCard/types';
import { EMPTY_STRING } from '@shared/constants/fallbacks';

const detailInfoSplit = (text: string): string[] => {
  return text.split(':-');
};

export const getDetailInfo = (res: string[]): InfoCardDataType['infoCardData'] =>
  res.map(item => {
    const newItem = detailInfoSplit(item);

    return {
      title: newItem[0],
      name: newItem[1] ?? EMPTY_STRING
    };
  });
