import { useCallback, useEffect, useRef, useState } from 'react';

import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { useCitiesStore, useCountriesStore } from 'src/shared/model/Store';

export const useGeoLocation = () => {
  const [countries, getCountriesData, currentCountry] = useCountriesStore(state => [
      state.countries,
      state.getCountriesData,
      state.currentCountry,
      state.getCurrentCountry
    ]),
    [
      cities,
      getPopularCitiesData,
      selectedCity,
      setSelectedCity,
      getSearchCitiesData,
      searchCities,
      setSearchCitiesEmptyData,
      countryId,
      isLoading,
      setSelectedCountryCityId,
      selectedCountryCityId
    ] = useCitiesStore(state => [
      state.cities,
      state.getPopularCitiesData,
      state.selectedCity,
      state.setSelectedCity,
      state.getSearchCitiesData,
      state.searchCities,
      state.setSearchCitiesEmptyData,
      state.countryId,
      state.isLoading,
      state.setSelectedCountryCityId,
      state.selectedCountryCityId
    ]),
    [isCountrySelectShown, setCountrySelectShown] = useState(false),
    [showPopup, setShowPopup] = useState(false),
    [referenceElement, setReferenceElement] = useState<HTMLDivElement | null>(null),
    [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null),
    [inputCityValue, setInputCityValue] = useState(EMPTY_STRING),
    [inputSelectedCity, setInputSelectedCity] = useState(EMPTY_STRING),
    [dropdownOpen, setDropdownOpen] = useState(false),
    [isCityClicked, setIsCityClicked] = useState(false),
    dropdownRef = useRef<HTMLDivElement>(null),
    selectedCityName = selectedCity?.title,
    selectedCityId = selectedCity?.id,
    isSelectedCity = cities[0]?.country_id === selectedCity?.country_id,
    currentCountryName = !countryId ? EMPTY_STRING : selectedCityName || 'Город не определен',
    handleInputCityChange = (value: string) => {
      setInputCityValue(value);
      if (isCityClicked) {
        setIsCityClicked(false);
      }
    };

  const handleClick = () => {
    if (!countries.length) {
      getCountriesData();
    }
    setShowPopup(!showPopup);
  };
  const handleDocumentClick = useCallback(
    (e: MouseEvent) => {
      const isClickInsidePopup = popperElement?.contains(e.target as Node),
        isClickInsideReference = referenceElement?.contains(e.target as Node);

      if (!isClickInsidePopup && !isClickInsideReference) {
        setShowPopup(false);
        setCountrySelectShown(false);
      }
    },
    [popperElement, referenceElement, setCountrySelectShown]
  );
  const handleClickCitySearchOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setDropdownOpen(false);
    }
  };
  const handleCityClick = (cityName: string) => {
    setInputCityValue(cityName);
    setInputSelectedCity(cityName);
    setDropdownOpen(false);
    setIsCityClicked(true);
  };
  const amount = 12;

  useEffect(() => {
    if (searchCities.length > 0 && !isCityClicked) {
      setDropdownOpen(true);
      setIsCityClicked(false);
    }

    document.addEventListener('mousedown', handleClickCitySearchOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickCitySearchOutside);
    };
  }, [searchCities, isCityClicked]);

  useEffect(() => {
    if (inputCityValue.length >= 3 && countryId) {
      getSearchCitiesData(countryId, inputCityValue);
    } else {
      setSearchCitiesEmptyData();
    }
  }, [inputCityValue, getSearchCitiesData, setSearchCitiesEmptyData]);

  useEffect(() => {
    if (currentCountry && !selectedCityName) {
      setSelectedCity(cities[0]);
    }
  }, [currentCountry, selectedCityName, setSelectedCity]);

  useEffect(() => {
    isSelectedCity && countryId && setSelectedCountryCityId(countryId);
  }, [cities, selectedCity]);

  return {
    amount,
    cities,
    countries,
    countryId,
    currentCountry,
    currentCountryName,
    selectedCityName,
    dropdownOpen,
    dropdownRef,
    getPopularCitiesData,
    handleCityClick,
    handleClick,
    handleClickCitySearchOutside,
    handleDocumentClick,
    handleInputCityChange,
    inputCityValue,
    inputSelectedCity,
    isCountrySelectShown,
    isLoading,
    popperElement,
    referenceElement,
    selectedCityId,
    setCountrySelectShown,
    setInputCityValue,
    setPopperElement,
    setReferenceElement,
    setSelectedCity,
    setShowPopup,
    showPopup,
    searchCities,
    setDropdownOpen,
    selectedCountryCityId,
    getCountriesData
  };
};
