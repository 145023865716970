import React from 'react';
import { Skeleton, Text, plural } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';
import { useSearchDetailsStore } from '@shared/model/Store/SearchDetails';

import { SearchFoundTextProps } from './types';
import styles from './styles.module.scss';

export const SearchFoundText = ({
  isLoading,
  tips,
  searchValue,
  resultLength
}: SearchFoundTextProps) => {
  const [originalTotalItems] = useSearchDetailsStore(state => [state.originalTotalItems]);

  const totalItems = originalTotalItems || resultLength || 0;

  return (
    <Text
      className={styles.searchFoundText}
      data-testid={dataTestId.SEARCH_FOUNT_TEXT}
      variant='header'
    >
      {isLoading ? (
        <Skeleton height='40px' width='100%' />
      ) : (
        tips && (
          <>
            По запросу
            <span className={styles.searchFoundText_request}> {searchValue}</span>
            {` найдено ${totalItems} ${plural(
              totalItems,
              'совпадение',
              'совпадения',
              'совпадений'
            )}`}
          </>
        )
      )}
    </Text>
  );
};
