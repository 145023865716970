import React from 'react';
import { ToastContainer } from 'react-toastify';
import { Footer, Header } from '@features/AppLayout';

import { dataTestId } from '@shared/constants/dataTestId';
import { AuthRefresher } from '../AuthRefresher';
import { AUTO_CLOSE_TIME } from './lib/constants/toast';

import { PageLayoutProps } from './types';
import 'react-toastify/dist/ReactToastify.css';
import styles from './styles.module.scss';

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <>
      <Header />
      <div className={styles.container} data-testid={dataTestId.CONTENT}>
        {children}
      </div>
      <ToastContainer
        autoClose={AUTO_CLOSE_TIME}
        className={styles.toast}
        closeOnClick
        hideProgressBar={true}
        pauseOnFocusLoss
        position='bottom-left'
      />
      <Footer />
      <AuthRefresher />
    </>
  );
};
