import React from 'react';
import { Text } from '@uremont/erp-ui';
import { dataTestId } from '@shared/constants/dataTestId';
import { CountryCitiesLinks } from '@features/AppLayout/components/Footer/ui/types';
import { EMPTY_OBJECT } from '@shared/constants/fallbacks';

import styles from './styles.module.scss';

export const SubItemAccordion = (links: CountryCitiesLinks[]) => {
  return links.map((link, index) => {
    const ComponentTag = link.path ? 'a' : 'p';
    const commonProps = {
      className: link.path ? styles.subItemAccoridon : styles.subItemAccoridon_noUrl,
      ...(link.target && { target: link.target })
    };

    return (
      <Text
        as={ComponentTag}
        data-testid={dataTestId.SUB_ITEM_ACCORDION}
        key={index}
        variant='body-tight'
        {...(link.path ? { href: link.path } : EMPTY_OBJECT)}
        {...commonProps}
      >
        {link.name}
      </Text>
    );
  });
};
