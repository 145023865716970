import { BASKET_BREADCRUMBS, HOME_BREADCRUMBS } from '@shared/constants/breadcrumbsText';
import { pathConfig } from '@shared/constants/pathConfig';

export const basketBreadcrumbsLinks = [
  {
    active: false,
    href: pathConfig.MainPath,
    label: HOME_BREADCRUMBS
  },
  {
    active: true,
    label: BASKET_BREADCRUMBS
  }
];
