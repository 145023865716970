import { EMPTY_STRING } from 'src/shared/constants/fallbacks';
import { SearchByArticleData } from '../SearchDetails/types';
import { ProductInfo, ProductInfoResponse } from './types';
import { skeletonProductData } from './constants';

export const responseProductInfoToData = (
  response: ProductInfoResponse,
  currentItem: SearchByArticleData
): ProductInfo => ({
  ...response,
  images: response.images.length ? response.images : skeletonProductData.images,
  vendorCode: response.number || EMPTY_STRING,
  item_key: currentItem.itemKey,
  deliveryPeriod: currentItem.deliveryPeriod,
  price: currentItem.price,
  availability: currentItem.availability,
  supplierDescription: currentItem.supplierDescription,
  part_hash: currentItem.partHash
});
