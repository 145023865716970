import { useEffect, useRef, RefObject } from 'react';

export const useFocusTrap = (isEnabled: boolean): RefObject<HTMLDivElement> => {
  const trapRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Tab' || event.keyCode === 9) {
        const trapElement = trapRef.current;

        if (trapElement) {
          const focusableElements = Array.from(
            trapElement.querySelectorAll<HTMLElement>(
              'a[href], button:not([disabled]), textarea, input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), select:not([disabled])'
            )
          ).filter(el => !el.hasAttribute('disabled') && el.offsetParent !== null);

          if (focusableElements.length === 0) return;

          const firstFocusableElement = focusableElements[0];
          const lastFocusableElement = focusableElements[focusableElements.length - 1];

          if (!event.shiftKey && document.activeElement === lastFocusableElement) {
            event.preventDefault();
            firstFocusableElement.focus();
          } else if (event.shiftKey && document.activeElement === firstFocusableElement) {
            event.preventDefault();
            lastFocusableElement.focus();
          }
        }
      }
    };

    if (isEnabled) {
      document.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEnabled]);

  return trapRef;
};
