import React from 'react';
import cx from 'classnames';
import { Checkbox, Divider, Skeleton, Text } from '@uremont/erp-ui';

import { BasketButton } from '@shared/components/BasketButton';
import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { roundedNumber } from '@shared/functions/formattedNumber/roundedNumber';
import { ProductCardInfoProduct } from '../../ProductCardInfoProduct/ProductCardInfoProduct';
import { AddCardBasket } from '../lib/AddCartBasket';
import { TextUnderPrice } from '../lib/TextUnderPrice';

import { ProductCardViewProps } from './types';
import styles from './styles.module.scss';

export const ProductCardView = ({
  item,
  btnText,
  horizontal,
  isLoading,
  isBasket,
  showDivider,
  articleProductUrl,
  isHistoryCard,
  isArticleScreen,
  productUrl,
  price,
  cardCount,
  handleSelect,
  handleSelectedArticleClicked,
  isOutOfStockProducts,
  isShowCheckbox
}: ProductCardViewProps) => {
  return (
    <div className={cx(horizontal && styles.productCard_horizontalWrap)}>
      <div
        className={cx(styles.productCard, horizontal && styles.productCard_horizontal)}
        data-testid={`${dataTestId.PRODUCT_CARD_VIEW}-${item.id}`}
      >
        {isBasket && isShowCheckbox && (
          <Checkbox
            boxAlign='start'
            checked={item.selected}
            className={styles.productCard_checkbox}
            crossOrigin='anonymous'
            data-testid={dataTestId.PRODUCT_CARD_VIEW_CHECKBOX}
            onChange={() => handleSelect && handleSelect(item.partHash ?? EMPTY_STRING)}
          />
        )}
        <ProductCardInfoProduct
          handleSelectedArticleClicked={handleSelectedArticleClicked}
          horizontal={horizontal}
          isBasket={isBasket}
          isHistoryCard={isHistoryCard}
          isLoading={isLoading}
          isOutOfStockProducts={isOutOfStockProducts}
          item={item}
          price={price}
          productUrl={productUrl}
        />
        {!isOutOfStockProducts && (
          <div
            className={cx(horizontal && styles.productCard_right)}
            data-testid={dataTestId.INFO_BLOCK}
          >
            <Text className={styles.productCard_price} variant='headline-H2'>
              {!isArticleScreen &&
                (isLoading ? (
                  <Skeleton height='32px' width='128px' />
                ) : (
                  `${isBasket ? roundedNumber(Number(price) * cardCount) : price} ₽`
                ))}
            </Text>
            {horizontal && (
              <TextUnderPrice
                days={item.deliveryPeriod}
                isLoading={isLoading}
                rest={item.availability}
              />
            )}
            {isBasket ? (
              <AddCardBasket isLoading={isLoading} item={item} rest={item.availability} />
            ) : (
              <BasketButton
                articleProductUrl={articleProductUrl}
                btnText={btnText}
                id={item?.partHash?.toString()}
                isArticleScreen={isArticleScreen}
                isLoading={isLoading}
                rest={item.availability}
              />
            )}
          </div>
        )}
      </div>
      {showDivider && <Divider className={styles.productCard_divider} />}
    </div>
  );
};
