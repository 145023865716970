import React from 'react';
import { IconChevronLeft, Skeleton, Text } from '@uremont/erp-ui';

import { Breadcrumbs } from '@shared/components/Breadcrumbs';
import { InfoCard } from '@shared/components/InfoCard/InfoCard';
import { TO_GROUPS } from '@shared/constants/category';
import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { RenderDetailedDescription } from '@pages/CatalogProduct/lib/RenderDetailedDescription';
import { skeletonRenderDetailedDescriptionData } from '../lib/constants/skeletonRenderDetailedDescriptionData';

import { CatalogProductViewProps } from './types';
import styles from './styles.module.scss';

export const CatalogProductView = ({
  breadcrumbs,
  title = EMPTY_STRING,
  handleActiveChevronClick,
  transformedSelectedModelData,
  subgroupParts,
  goBack,
  isCardIndexActive,
  isLoading,
  brand
}: CatalogProductViewProps) => {
  return (
    <div className={styles.searchContainer} data-testid={dataTestId.CATALOG_PRODUCT_VIEW}>
      <Breadcrumbs links={breadcrumbs} />
      <InfoCard infoCardData={transformedSelectedModelData} isLoading={isLoading} />
      <div className={styles.backButton} onClick={goBack}>
        <IconChevronLeft /> {TO_GROUPS}
      </div>
      <Text className={styles.searchContainer_titleText} variant='header'>
        {isLoading ? <Skeleton height='40px' width='100%' /> : title}
      </Text>
      <RenderDetailedDescription
        brand={brand}
        data-testid={dataTestId.RENDER_DESCRIPTION_CARD}
        handleActiveChevronClick={handleActiveChevronClick}
        isCardIndexActive={isCardIndexActive}
        isLoading={isLoading}
        subgroupParts={isLoading ? skeletonRenderDetailedDescriptionData : subgroupParts}
      />
    </div>
  );
};
