import {
  BasketItems,
  BasketParts,
  OldBasketParts,
  TransformBasketData
} from '@shared/model/Store/Basket/types';

export const compareAndTransform = (
  basketParts: BasketParts[],
  recreate: BasketItems
): TransformBasketData => {
  if (!recreate || !recreate.priceChanged || !recreate.noChanges || !recreate.notFound) {
    return { noChanges: basketParts, notFound: [], priceChanged: [] };
  }

  const basketPartsById = new Map(basketParts.map(item => [item.id, item]));
  const priceChanged: BasketParts[] = [];
  const noChanges: BasketParts[] = [];
  const notFound: OldBasketParts[] = [];

  const processedIds = new Set<number>();

  Object.values(recreate.priceChanged).forEach(({ basketPart }) => {
    if (basketPartsById.has(basketPart.id)) {
      priceChanged.push(basketPartsById.get(basketPart.id)!);
      processedIds.add(basketPart.id);
    }
  });

  Object.values(recreate.notFound).forEach(({ oldOrderPart }) => {
    if (oldOrderPart.id) {
      notFound.push(oldOrderPart);
      processedIds.add(oldOrderPart.id);
    }
  });

  recreate.noChanges.forEach(({ basketPart }) => {
    if (basketPartsById.has(basketPart.id)) {
      noChanges.push(basketPartsById.get(basketPart.id)!);
      processedIds.add(basketPart.id);
    }
  });

  basketParts.forEach(part => {
    if (!processedIds.has(part.id)) {
      noChanges.push(part);
    }
  });

  return { priceChanged, noChanges, notFound };
};
