import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { AboutProductViewProps } from './types';
import styles from './styles.module.scss';

export const AboutProductView = ({ infoList, isLoading, list }: AboutProductViewProps) => (
  <>
    <Text className={styles.aboutProduct_caption} variant='headline'>
      {isLoading ? <Skeleton height='32px' width='110px' /> : 'О товаре'}
    </Text>
    {list.map((optionKey, index) => (
      <div className={styles.aboutProduct} key={index}>
        <Text className={styles.aboutProduct_left} color='secondary' variant='body'>
          {isLoading ? <Skeleton height='24px' width='115px' /> : optionKey}
        </Text>
        <Text variant='body'>
          {isLoading ? <Skeleton height='24px' width='115px' /> : infoList[optionKey]}
        </Text>
      </div>
    ))}
  </>
);
