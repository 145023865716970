import React from 'react';

import { StickyInfoProductView } from './StickyInfoProductView';
import { StickyInfoProductProps } from './types';

export const StickyInfoProduct = ({
  isPriceBlockScrolled,
  mainPhoto,
  name,
  countNotZero,
  handleDelete,
  textBtn,
  count,
  handleAdd,
  isLoading,
  item
}: StickyInfoProductProps) => {
  if (!isPriceBlockScrolled) {
    return null;
  }

  return (
    <StickyInfoProductView
      count={count}
      countNotZero={countNotZero}
      handleAdd={handleAdd}
      handleDelete={handleDelete}
      isLoading={isLoading}
      item={item}
      mainPhoto={mainPhoto}
      name={name}
      textBtn={textBtn}
    />
  );
};
