import React from 'react';
import { AccordionItemView } from './AccordionItemView';
import { AccordionItemViewProps } from './types';

export const AccordionItem = ({
  title,
  links,
  classNameContainer,
  textVariant,
  isLoading,
  isActive,
  setIsActive
}: AccordionItemViewProps) => (
  <AccordionItemView
    classNameContainer={classNameContainer}
    isActive={isActive}
    isLoading={isLoading}
    links={links}
    setIsActive={setIsActive}
    textVariant={textVariant}
    title={title}
  />
);
