import React from 'react';
import { useNavigate } from 'react-router-dom';
import { PlacingAnOrderRejectView } from '@pages/PlacingAnOrderReject/ui/PlacingAnOrderRejectView';
import { pathConfig } from '@shared/constants/pathConfig';

export const PlacingAnOrderReject = () => {
  const navigate = useNavigate();

  const goBack = () => {
    navigate(pathConfig.PlacingAnOrderPath);
  };

  return <PlacingAnOrderRejectView toOrderClick={goBack} />;
};
