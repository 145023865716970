import React from 'react';
import { useGeoLocation } from '@shared/hooks/use-geo-location';
import { menus } from '@features/AppLayout/components/Footer/lib/constants/links/groupDescriptionsOfLinks';
import { useScreenSizeBreakpoints } from '@shared/hooks/use-screen-size-breakpoints';
import { FooterView } from './FooterView';

export const Footer = () => {
  const { selectedCountryCityId } = useGeoLocation();
  const { isTablet, isMobile } = useScreenSizeBreakpoints();

  const selectedMenu = menus[selectedCountryCityId as keyof typeof menus];
  const IconMap = menus[selectedCountryCityId as keyof typeof menus].icon;

  const selectedBelCountry = selectedCountryCityId === 22;

  const isLargeScreen = !isTablet && !isMobile;

  const participantLink = 'https://sk.ru/';

  return (
    <FooterView
      icon={<IconMap />}
      isLargeScreen={isLargeScreen}
      participantLink={participantLink}
      selectedBelCountry={selectedBelCountry}
      selectedCountryCityId={selectedCountryCityId}
      selectedMenu={selectedMenu}
    />
  );
};
