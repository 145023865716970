import React, { useState } from 'react';
import { getUpdatedList } from '@shared/components/FilterComponent/lib/functions/getUpdatedList';
import { areAllItemsShown } from '@utils/areAllItemsShown';
import { getIsShowBtnAll } from '@utils/getIsShowBtnAll';
import { EMPTY_ARRAY, EMPTY_OBJECT, EMPTY_STRING, noop } from '@shared/constants/fallbacks';
import { PaginationData } from '@shared/model/Store/SearchDetails/types';
import { INITIAL_PAGE } from '@shared/constants/consts';
import { useSearchDetailsStore } from '@shared/model/Store/SearchDetails';
import {
  getArticleOnlyAnalogues,
  getSearchByArticleOnly
} from '@shared/model/Store/SearchDetails/service';
import { useFormik } from 'formik';
import { InputsFormValues, useInputsForm } from '@shared/hooks/use-inputs-form';
import { searchFormSchema } from '@pages/Main/lib/Search/lib/constants/schema';
import { defaultValuesSearchForm } from '@pages/Main/lib/Search/lib/constants/constants';
import { useSearchRequestStore } from '@shared/model/Store/SearchRequest';
import { INITIAL_NUMBER_OF_SHOWN, TITLE_ARTICLE, TITLE_ANALOGUES } from '../constants/consts';
import { FilterPageListProps } from './types';
import { FilterPageListView } from './FilterPageList';

export const FilterPageList = ({
  title,
  products,
  isLoading,
  handleSelectedArticleClicked,
  pagination = EMPTY_OBJECT as PaginationData,
  totalPages,
  setTotalPages = noop,
  setProductsList = noop,
  showAll = false,
  setShowAll = noop,
  setIsFirstAllBtnClick = noop,
  isFirstAllBtnClick = true,
  originDetailsData
}: FilterPageListProps) => {
  const [
    article,
    brand,
    searchByArticleDetailsData,
    setArticleList,
    articleAnalogues,
    setAnaloguesList
  ] = useSearchDetailsStore(state => [
    state.article,
    state.brand,
    state.searchByArticleDetailsData,
    state.setArticleList,
    state.articleAnalogues,
    state.setAnaloguesList
  ]);

  const [searchRequest] = useSearchRequestStore(state => [state.searchRequest]);
  const [initialValues, setInitialValues] = useState<InputsFormValues>(defaultValuesSearchForm);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isLoadingNewItems, setIsLoadingNewItems] = useState(false);

  const isTitleMatch = (matchTitle: string) => title === matchTitle;

  const isOnePageCount = pagination?.page_count === INITIAL_PAGE;

  const isLoadingData = isLoading || isLoadingNewItems;

  const handleShowMore = async () => {
    if (isTitleMatch(TITLE_ARTICLE) && isFirstAllBtnClick && searchByArticleDetailsData) {
      const setList = isTitleMatch(TITLE_ARTICLE) ? setArticleList : setAnaloguesList;
      const dataList = isTitleMatch(TITLE_ARTICLE) ? searchByArticleDetailsData : articleAnalogues;

      setList(dataList ?? EMPTY_ARRAY);
      setIsFirstAllBtnClick(false);
      setIsLoadingNewItems(false);

      if (
        products &&
        originDetailsData &&
        isOnePageCount &&
        products.length <= originDetailsData.length
      ) {
        setShowAll(true);
      }

      return;
    }

    const response = isTitleMatch(TITLE_ARTICLE)
      ? await getSearchByArticleOnly(article, brand, totalPages, INITIAL_NUMBER_OF_SHOWN)
      : await getArticleOnlyAnalogues(article, brand, totalPages, INITIAL_NUMBER_OF_SHOWN);

    if (response) {
      setTotalPages(prevState => prevState + 1);
      if (areAllItemsShown(response.pagination) && setShowAll) {
        setShowAll(true);
      }

      const updatedProductList = products
        ? getUpdatedList(products, response, searchByArticleDetailsData)
        : EMPTY_ARRAY;

      setProductsList(updatedProductList);
    }
  };

  const handleHiddenItems = () => {
    setIsFirstAllBtnClick(true);
    setTotalPages(2);

    const dataList = isTitleMatch(TITLE_ARTICLE)
      ? searchByArticleDetailsData && searchByArticleDetailsData.slice(0, 2)
      : articleAnalogues;
    const setList = isTitleMatch(TITLE_ARTICLE) ? setArticleList : setProductsList;

    setList(dataList ? dataList : EMPTY_ARRAY);

    if (isTitleMatch(TITLE_ANALOGUES)) {
      const analogueListStartingPosition = document.getElementById('analogues');
      if (analogueListStartingPosition) {
        const elementPosition = analogueListStartingPosition.offsetTop;
        window.scrollTo(0, elementPosition - 94);
      }
    } else {
      window.scrollTo(0, 0);
    }

    setShowAll(false);
  };

  const handleShowAll = async () => {
    setIsLoadingNewItems(true);
    showAll && products ? handleHiddenItems() : await handleShowMore();
    setIsLoadingNewItems(false);
  };

  const id = isTitleMatch(TITLE_ARTICLE) ? 'article' : 'analogues';
  const noInfo = !isLoadingData && (!products || !products.length);

  const formik = useFormik<InputsFormValues>({
    initialValues,
    validationSchema: searchFormSchema,
    onSubmit: (values: InputsFormValues) => {
      setInitialValues(values);
    }
  });
  const { errors, values, handleChange, resetForm, handleBlur, setFieldValue, touched } = formik;

  const searchRequestValue = {
    phone: values.phone?.replace(/\D/g, EMPTY_STRING) ?? EMPTY_STRING,
    query: values.comment ?? EMPTY_STRING,
    file: values.file ? values.file[0] : null
  };

  const toggleModal = () => {
    setIsOpenModal(prevState => !prevState);
    resetForm();
  };

  const handleSubmit = async () => {
    await searchRequest(searchRequestValue);
    toggleModal();
  };

  const { renderChildren } = useInputsForm({
    values,
    setFieldValue,
    errors,
    handleChange,
    handleSubmit,
    handleBlur,
    touched
  });

  const isShowBtnAll = () => {
    if (originDetailsData && products) {
      if (isOnePageCount) {
        if (isTitleMatch(TITLE_ARTICLE)) {
          return products.length >= 2;
        }

        return products.length < 2 || products.length < originDetailsData.length;
      }

      return (
        (products.length < originDetailsData.length && isFirstAllBtnClick) ||
        getIsShowBtnAll(pagination)
      );
    }

    return false;
  };

  return (
    <FilterPageListView
      handleSelectedArticleClicked={handleSelectedArticleClicked}
      handleShowAll={handleShowAll}
      id={id}
      isLoading={isLoadingData}
      isOpenModal={isOpenModal}
      noInfo={noInfo}
      onClick={toggleModal}
      products={products}
      renderChildren={renderChildren()}
      showAll={showAll}
      showBtnAll={isShowBtnAll()}
      title={title}
    />
  );
};
