import React from 'react';
import { FilePreviewItemView } from './FilePreviewItemView';
import { FilePreviewItemProps } from './types';

export const FilePreviewItem = ({ id, item, deleteFile }: FilePreviewItemProps) => {
  const urlImg = URL.createObjectURL(item);
  const isImg = item.type.startsWith('image/');

  const handleDeleteFile = () => {
    deleteFile(id);
  };

  return (
    <FilePreviewItemView
      deleteFile={handleDeleteFile}
      isImg={isImg}
      name={item.name}
      urlImg={urlImg}
    />
  );
};
