import React from 'react';

import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { pathConfig } from '@shared/constants/pathConfig';
import { OrderCardItemView } from './OrderCardItemView';

import { OrderCardItemProps } from './types';

export const OrderCardItem = ({ item, isLastItem, isLoading }: OrderCardItemProps) => {
  const brandAndArticle = encodeURIComponent(`${item.brand}_${item.vendorCode}`);

  const searchByArticleUrl = `${pathConfig.SearchByArticlePath}/search/${item.brand}/${item.vendorCode}`;
  const productUrl =
    `${pathConfig.SearchByArticlePath}/${brandAndArticle}/${brandAndArticle}_${item.partHash}` ??
    EMPTY_STRING;
  const url = item.partHash ? productUrl : searchByArticleUrl;

  return (
    <OrderCardItemView isLastItem={isLastItem} isLoading={isLoading} item={item} productUrl={url} />
  );
};
