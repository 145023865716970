import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Button, createPortalNode, IconXmark, Text } from '@uremont/erp-ui';
import cx from 'classnames';
import { useFocusTrap } from 'src/shared/hooks/use-focus-trap';
import { useScreenSizeBreakpoints } from 'src/shared/hooks/use-screen-size-breakpoints';
import { ModalProps } from './types';
import styles from './styles.module.scss';

const portalNode = createPortalNode('overlays');

export const CustomModal = ({
  children,
  title = 'Модальное окно',
  width = 'auto',
  toggleModal,
  isOpen
}: ModalProps) => {
  const trapRef = useFocusTrap(isOpen);
  const { isTablet, isMobile } = useScreenSizeBreakpoints();

  useEffect(() => {
    const originalStyle = window.getComputedStyle(document.body).overflow;
    document.body.appendChild(portalNode);
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      try {
        if (document.body.contains(portalNode)) {
          document.body.removeChild(portalNode);
        }
        document.body.style.overflow = originalStyle;
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error removing portal node:', error);
      }
    };
  }, [isOpen]);

  if (isOpen) {
    return ReactDOM.createPortal(
      <div className={styles.modalOverlay} ref={trapRef}>
        <div
          className={cx(styles.modalContent, styles[`modalContent-width-${width}`], {
            [styles['modalContent-fullscreen']]: isTablet || isMobile
          })}
        >
          <div className={styles.modalContent_title}>
            <Text variant='headline-L'>{title}</Text>
            <Button
              className={styles.modalContent_closeButton}
              icon={<IconXmark size='lg' />}
              onClick={toggleModal}
              size='sm'
              variant='empty'
            />
          </div>
          {children && <div className={styles.modalContent_childrenContainer}>{children}</div>}
        </div>
      </div>,
      portalNode
    );
  }

  return null;
};
