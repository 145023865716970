import React from 'react';
import { PhotosBtnView } from './PhotosBtnView';
import { PhotosBtnProps } from './types';

export const PhotosBtn = ({ handleChangePhoto, isLoading, photos }: PhotosBtnProps) => {
  if (!photos) {
    return null;
  }

  return (
    <PhotosBtnView handleChangePhoto={handleChangePhoto} isLoading={isLoading} photos={photos} />
  );
};
