import { Text } from '@uremont/erp-ui';

import { SocialLinkProps } from 'src/features/AppLayout/components/Footer/lib/SocialLink/types';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { EMPTY_STRING } from 'src/shared/constants/fallbacks';

export const SocialLink = ({
  Icon,
  href,
  iconClassName = EMPTY_STRING,
  isNewWindow = false,
  itemClassName = EMPTY_STRING,
  text = EMPTY_STRING
}: SocialLinkProps) => {
  return (
    <Text
      as='a'
      className={itemClassName}
      data-testid={dataTestId.SOCIAL_LINK}
      href={href}
      target={isNewWindow ? '_blank' : '_self'}
      variant='footnote-bold'
    >
      <Icon className={iconClassName} />
      {text}
    </Text>
  );
};
