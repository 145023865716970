import { DataOrder } from '@src/shared/components/OrderCard/types';

// Пример: 18 апреля 2024 -> 2024
const convertDate = (res: string): string => {
  const date = res.split(' ');

  return String(date[2]);
};

export const filterHistoryOrders = (orders: DataOrder[], selectedYear: string): DataOrder[] => {
  return orders.filter(item => {
    switch (selectedYear) {
      case 'Все':
        return item;
      case convertDate(item.dateOrder):
        return item;
    }
  });
};
