export const capitalizeCarNameLetter = (name: string[]) =>
  name.map(item => {
    const words = item.split('-');
    const formattedWords = words.map(word => {
      if (word.length <= 3) {
        return word.toUpperCase();
      }

      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });

    return formattedWords.join('-');
  });
