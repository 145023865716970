import { useEffect, useState } from 'react';
import { VIN_VALUE_LENGTH } from 'src/shared/constants/consts';
import { useSearchDetailsStore } from 'src/shared/model/Store/SearchDetails';

export const useSearch = (searchValue: string) => {
  const [isEmptyDataShow, setIsEmptyDataShow] = useState(false);
  const [
    getSearchDetailsByGRZData,
    getSearchDetailsByArticleData,
    getSearchDetailsByArticleOnlyData,
    getSearchDetailsByVINData,
    getArticleTips,
    articleTips,
    setArticleTip,
    isLoadingArticle,
    tip,
    searchByArticleDetailsData,
    isLoadingSearchByArticleDetailsData,
    getArticleAnaloguesData,
    getArticleOnlyAnaloguesData,
    articleAnalogues,
    setIsGRZError,
    isGRZError,
    isLoading
  ] = useSearchDetailsStore(state => [
    state.getSearchDetailsByGRZData,
    state.getSearchDetailsByArticleData,
    state.getSearchDetailsByArticleOnlyData,
    state.getSearchDetailsByVINData,
    state.getArticleTips,
    state.articleTips,
    state.setArticleTip,
    state.isLoadingArticle,
    state.tip,
    state.searchByArticleDetailsData,
    state.isLoadingSearchByArticleDetailsData,
    state.getArticleAnaloguesData,
    state.getArticleOnlyAnaloguesData,
    state.articleAnalogues,
    state.setIsGRZError,
    state.isGRZError,
    state.isLoading
  ]);

  useEffect(() => {
    setIsEmptyDataShow(
      articleTips.length === 0 && Boolean(searchValue) && searchValue.length !== VIN_VALUE_LENGTH
    );
  }, [articleTips, searchValue]);

  return {
    isEmptyDataShow,
    setIsEmptyDataShow,
    isLoadingArticle,
    setArticleTip,
    getArticleTips,
    getSearchDetailsByGRZData,
    getSearchDetailsByArticleData,
    getSearchDetailsByArticleOnlyData,
    getSearchDetailsByVINData,
    articleTips,
    tip,
    searchByArticleDetailsData,
    isLoadingSearchByArticleDetailsData,
    getArticleAnaloguesData,
    getArticleOnlyAnaloguesData,
    articleAnalogues,
    setIsGRZError,
    isGRZError,
    isLoading
  };
};
