import { create } from 'zustand';
import { persist, createJSONStorage, devtools } from 'zustand/middleware';
import { executeRequest } from '@shared/functions/executeRequest/executeRequest';
import { getProfileInfo } from './service';
import { PROFILE_EMPTY } from './constants';
import { ProfileStore } from './types';

export const useProfileStore = create(
  devtools(
    persist<ProfileStore>(
      set => ({
        isLoading: false,
        profile: PROFILE_EMPTY,

        getProfileInfoData: async () => {
          await executeRequest(
            () => getProfileInfo(),
            isLoading => set({ isLoading }),
            response =>
              set({
                profile: response ?? PROFILE_EMPTY
              })
          );
        }
      }),
      {
        name: 'profile-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
