import {
  SearchByArticleData,
  SearchByArticleResponse
} from '@shared/model/Store/SearchDetails/types';

export const getUpdatedList = (
  list: SearchByArticleData[],
  response: SearchByArticleResponse,
  searchByArticleDetailsData: SearchByArticleData[] | null
) => {
  return searchByArticleDetailsData
    ? [
        ...list,
        ...response.collection.reduce((acc, newArticle) => {
          if (!list.some(existingArticle => existingArticle.partHash === newArticle.partHash)) {
            acc.push(newArticle);
          }

          return acc;
        }, [] as SearchByArticleData[])
      ]
    : list;
};
