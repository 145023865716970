import { Basket } from '@shared/model/Store/Basket/types';
import { EMPTY_OBJECT } from '@shared/constants/fallbacks';
import { BasketValueProps } from '@pages/Basket/ui/types';
import { initialValue } from '@pages/Basket/lib/constants/initialValue';

export const basketDefaultValue = (basket: Basket | null) => {
  if (!basket) return EMPTY_OBJECT as BasketValueProps;

  return basket?.basket_parts.reduce((accumulator, item) => {
    return {
      quantity: accumulator.quantity + item.quantity,
      weight: accumulator.weight + item.weight * item.quantity,
      price: accumulator.price + item.price * item.quantity,
      discount: accumulator.discount
        ? accumulator.discount + (item.discount || 0)
        : item.discount || 0
    };
  }, initialValue);
};
