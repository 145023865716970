import React from 'react';
import { Button, Text } from '@uremont/erp-ui';
import cx from 'classnames';
import { Link } from 'react-router-dom';

import { ReactComponent as MinusIcon } from '@assets/MinusIcon.svg';
import { ReactComponent as PlusIcon } from '@assets/PlusIcon.svg';
import { IN_BASKET_TEXT } from '@shared/constants/ texts';
import { dataTestId } from '@shared/constants/dataTestId';

import { BasketButtonViewProps } from './types';
import styles from './styles.module.scss';

export const BasketButtonView = ({
  id,
  btnText,
  countNotZero,
  count,
  articleProductUrl,
  handleDelete,
  handleAdd,
  className,
  handleBasketButton,
  isLoadingBasket,
  isArticleScreen,
  isDisableAddMoreItem
}: BasketButtonViewProps) => {
  return (
    <div className={cx(styles.btnWrap, className)}>
      {countNotZero && (
        <div className={styles.btnWrap_counterWrap}>
          <Button
            className={styles.btnWrap_counterWrap_btnChange}
            data-testid={`${dataTestId.PRODUCT_PAGE_MINUS}-${id}`}
            disabled={isLoadingBasket}
            onClick={handleDelete}
          >
            <MinusIcon />
          </Button>
          <Text className={styles.btnWrap_counterWrap_count} variant='body-tight'>
            {count}
          </Text>
          <Button
            className={styles.btnWrap_counterWrap_btnChange}
            data-testid={`${dataTestId.PRODUCT_PAGE_PLUS}-${id}`}
            disabled={isDisableAddMoreItem || isLoadingBasket}
            onClick={handleAdd}
          >
            <PlusIcon />
          </Button>
        </div>
      )}
      {isArticleScreen ? (
        <Link className={styles.btnWrap_link} to={articleProductUrl}>
          <Text className={styles.btnWrap_link_text}>{btnText}</Text>
        </Link>
      ) : (
        <Button
          className={styles.btnWrap_btnBasket}
          data-testid={`${dataTestId.PRODUCT_PAGE_ADD}-${id}`}
          loading={isLoadingBasket}
          onClick={handleBasketButton}
          size='md'
          variant='solid'
        >
          <Text className={styles.btnWrap_btnBasket_text} variant='body-tight'>
            {countNotZero ? IN_BASKET_TEXT : btnText}
          </Text>
        </Button>
      )}
    </div>
  );
};
