import { Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { getFooterInfoLinks } from 'src/features/AppLayout/components/Footer/lib/functions/getFooterInfoLinks/getFooterInfoLinks';
import { MenuGroup } from 'src/features/AppLayout/components/Footer/ui/types';
import { dataTestId } from 'src/shared/constants/dataTestId';

import styles from './styles.module.scss';

export const NavigationMenu = ({ name, links, navigationMenuClass }: MenuGroup) => {
  return (
    <div
      className={cx(styles.container, navigationMenuClass)}
      data-testid={dataTestId.NAVIGATION_MENU}
    >
      <Text className={styles.title} variant='body-bold'>
        {name.toUpperCase()}
      </Text>
      {getFooterInfoLinks(links, styles.linkText)}
    </div>
  );
};
