import React from 'react';
import cx from 'classnames';
import { Button, IconChevronDown, IconChevronUp, Skeleton, Text } from '@uremont/erp-ui';

import { ApplicabilityTextViewProps } from './types';
import styles from './styles.module.scss';

export const ApplicabilityTextView = ({
  isLoading,
  text,
  showText,
  handleShowAllText
}: ApplicabilityTextViewProps) => (
  <>
    <Text className={styles.applicabilityText_caption} variant='headline'>
      {isLoading ? <Skeleton height='32px' width='180px' /> : 'Применимость'}
    </Text>
    <Text
      variant='body'
      className={cx(styles.applicabilityText_text, {
        [styles.applicabilityText_text_active]: showText
      })}
    >
      {isLoading ? <Skeleton height='24px' width='100%' /> : text}
    </Text>
    {isLoading ? (
      <Skeleton height='48px' width='153px' />
    ) : (
      <Button className={styles.applicabilityText_text_btn} onClick={() => handleShowAllText()}>
        <Text color='brand' variant='body'>
          {showText ? (
            <>
              Показать меньше
              <IconChevronUp />
            </>
          ) : (
            <>
              Показать больше
              <IconChevronDown />
            </>
          )}
        </Text>
      </Button>
    )}
  </>
);
