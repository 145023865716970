import {
  BASKET_BREADCRUMBS,
  HOME_BREADCRUMBS,
  PLACINGANORDER_BREADCRUMBS
} from '@shared/constants/breadcrumbsText';
import { pathConfig } from '@shared/constants/pathConfig';

export const placingAnOrderBreadcrumbsLinks = [
  {
    active: false,
    href: pathConfig.MainPath,
    label: HOME_BREADCRUMBS
  },
  {
    active: false,
    href: pathConfig.BasketPath,
    label: BASKET_BREADCRUMBS
  },
  {
    active: true,
    label: PLACINGANORDER_BREADCRUMBS
  }
];
