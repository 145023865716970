import React from 'react';
import { Button, Dimmer, IconButton, Input, MaskedField, TextButton } from '@uremont/ui-kit';
import { Close } from '@uremont/ui-icons';
import { ModalFormViewProps } from './types';
import styles from './index.module.scss';

export const ModalFormView = ({
  name,
  phone,
  isSucceed,
  isSubmitting,
  onClose,
  onSubmit,
  onChange
}: ModalFormViewProps) => (
  <Dimmer active fixed overflow='auto' zIndex={2001}>
    <div className={styles.content}>
      <div className={styles.title}>
        <span>Страхование</span>
        <IconButton icon={Close} onClick={onClose} />
      </div>
      {!isSucceed ? (
        <form onSubmit={onSubmit}>
          <p className={styles.text}>
            В форме ниже укажите имя и&nbsp;номер телефона, чтобы получить подробную консультацию
            по&nbsp;страхованию корпоративных парков
          </p>
          <div className={styles.inputsContainer}>
            <Input label='ФИО' name='name' onChange={onChange} required value={name} />
            <MaskedField
              label='Телефон'
              mask='+7 999 999-99-99'
              name='phone'
              onChange={onChange}
              required
              type='tel'
              value={phone}
            />
          </div>
          <div className={styles.buttonsContainer}>
            <TextButton disabled={isSubmitting} onClick={onClose}>
              Закрыть
            </TextButton>
            <Button loading={isSubmitting} type='submit'>
              Отправить
            </Button>
          </div>
        </form>
      ) : (
        <>
          <p className={styles.text}>Спасибо за заявку!</p>
          <p className={styles.text}>Наш менеджер свяжется с вами в ближайшее время</p>
          <TextButton onClick={onClose}>Закрыть</TextButton>
        </>
      )}
    </div>
  </Dimmer>
);
