import React, { useEffect } from 'react';
import { removeCookie } from '@uremont/erp-ui';
import { COOKIE_NAME } from '@shared/constants/enums';
import { getDomainForCookie } from '@utils/getDomainForCookie';
import { useBasketStore } from '@shared/model/Store';
import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { MainView } from './MainView';

export const Main = () => {
  const { getCarBrands, getCarModels, carIds } = useCarCatalog();
  const domain = getDomainForCookie();

  const [resetRecreateBasket] = useBasketStore(state => [state.resetRecreateBasket]);

  useEffect(() => {
    getCarBrands();
    carIds.length && getCarModels(carIds[0]);
  }, [carIds.length, getCarBrands, getCarModels]);

  useEffect(() => {
    removeCookie(COOKIE_NAME.REDIRECT_LINK, domain);
    resetRecreateBasket();
  }, []);

  return <MainView />;
};
