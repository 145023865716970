import React from 'react';
import { IconChevronDown, Skeleton, Text } from '@uremont/erp-ui';
import cx from 'classnames';

import { dataTestId } from '@shared/constants/dataTestId';
import { SubItemAccordion } from '../lib/SubItemAccordion/SubItemAccordion';

import { AccordionItemViewProps } from './types';
import styles from './styles.module.scss';

export const AccordionItemView = ({
  title,
  links,
  classNameContainer,
  textVariant,
  isLoading,
  isActive,
  setIsActive
}: AccordionItemViewProps) => (
  <div
    className={`${styles.accordion} ${classNameContainer}`}
    data-testid={dataTestId.CUSTOM_ACCORDION}
  >
    <div
      className={styles.accordion_title}
      data-testid={dataTestId.CUSTOM_ACCORDION_TITLE}
      onClick={() => setIsActive()}
    >
      <Text className={styles.accordion_title_text} variant={textVariant}>
        {isLoading ? <Skeleton height='25px' width='100%' /> : title}
      </Text>
      <div>
        <IconChevronDown
          className={cx(styles.accordion_arrow, {
            [styles.accordion_arrow_active]: isActive
          })}
        />
      </div>
    </div>
    <div
      className={cx(styles.accordion_content, {
        [styles.accordion_content_active]: isActive,
        [styles.accordion_content_hide]: !isActive
      })}
    >
      {isLoading ? <Skeleton height='20px' width='100%' /> : SubItemAccordion(links)}
    </div>
  </div>
);
