import React from 'react';
import { Button, IconChevronDown, IconChevronUp, Skeleton, Text } from '@uremont/erp-ui';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { ReactComponent as CopyIcon } from '@assets/CopyIcon.svg';
import { dataTestId } from '@shared/constants/dataTestId';
import { noop, EMPTY_STRING } from '@shared/constants/fallbacks';
import { SkeletonButton } from '@shared/components/SkeletonButton';
import { IMAGE_TYPE } from '@shared/constants/ texts';
import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholder.png';
import { removeLeadingZero } from '@shared/functions/removeLeadingZero/removeLeadingZero';

import { RenderDetailedDescriptionViewTypes } from './types';
import styles from './styles.module.scss';

export const RenderDetailedDescriptionView = ({
  handleActiveChevronClick = noop,
  subgroupParts,
  isCardIndexActive,
  isLoading,
  selectedDetail,
  handleMouseHover,
  handleDetailHover,
  clickedDetail,
  brand,
  handleDetailClick,
  handleListItemClick,
  imageRef,
  handleImageClick,
  isZoomActive,
  currentWidth,
  copyText,
  currentHeight,
  magnifierTransformStyle,
  partUrlProduct,
  cardItemStyle,
  infoRef
}: RenderDetailedDescriptionViewTypes) => {
  return (
    <div className={styles.detailedDescriptionContainer}>
      {isLoading ? (
        <Skeleton height='400px' width='400px' />
      ) : (
        subgroupParts?.positions && (
          <div
            className={styles.imgContainer}
            style={{
              height: isZoomActive ? `${currentHeight}px` : 'auto'
            }}
          >
            <div
              className={styles.imgWrapper}
              onClick={e => handleDetailClick(e, subgroupParts?.positions)}
              onMouseMove={e => handleMouseHover(e, subgroupParts?.positions)}
              style={{
                width: isZoomActive ? `${currentWidth}px` : 'auto'
              }}
            >
              {subgroupParts.positions.map(({ number, coordinates }, index) => (
                <div className={styles.activeCardWrapper} key={`${number}${index}`}>
                  <div
                    style={cardItemStyle(coordinates)}
                    className={cx(styles.activeCardWrapper_cardItem, {
                      [styles.activeCardWrapper_cardItem_active]: selectedDetail === number,
                      [styles.activeCardWrapper_cardItem_clicked]: clickedDetail === number
                    })}
                  />
                </div>
              ))}
              <img
                alt={subgroupParts?.imgDescription || IMAGE_TYPE}
                onClick={handleImageClick}
                ref={imageRef}
                src={subgroupParts?.img || picturePlaceholder}
                style={magnifierTransformStyle}
                className={cx(styles.detailImage, {
                  [styles.detailImage_active]: isZoomActive
                })}
              />
            </div>
          </div>
        )
      )}
      <div
        className={styles.detailedDescriptionContainer_info}
        ref={infoRef}
        style={{ height: `${currentHeight}px` }}
      >
        {subgroupParts?.partGroups &&
          subgroupParts?.partGroups.map(({ description = EMPTY_STRING, parts }, layerIndex) => (
            <div className={styles.detailedDescriptionContainer_block} key={layerIndex}>
              <Text variant='sub-headline'>
                {isLoading ? <Skeleton height='24px' width='150px' /> : description}
              </Text>
              <div className={styles.detailedDescriptionContainer_block_detailsInfo}>
                {parts.map(
                  ({ description, id, name, number, positionNumber, notice }, cardIndex) => (
                    <React.Fragment key={`${id}${cardIndex}`}>
                      <div
                        className={styles.detailedDescriptionContainer_block_detailsInfo_detailCard}
                      >
                        <div
                          className={
                            styles.detailedDescriptionContainer_block_detailsInfo_detailCard_wrapper
                          }
                        >
                          {isLoading ? (
                            <Skeleton height='24px' width='30px' />
                          ) : (
                            <div
                              id={`detail-${positionNumber}`}
                              key={cardIndex}
                              onClick={() => handleListItemClick(positionNumber)}
                              onMouseEnter={() => handleDetailHover(positionNumber)}
                              onMouseLeave={() => handleDetailHover(EMPTY_STRING)}
                              className={cx(
                                styles.detailedDescriptionContainer_block_detailsInfo_detailCard_number,
                                {
                                  [styles.detailedDescriptionContainer_block_detailsInfo_detailCard_number_active]:
                                    selectedDetail === positionNumber,
                                  [styles.detailedDescriptionContainer_block_detailsInfo_detailCard_number_clicked]:
                                    clickedDetail === positionNumber
                                }
                              )}
                            >
                              <Text variant='caption'>{removeLeadingZero(positionNumber)}</Text>
                            </div>
                          )}
                          <div
                            className={
                              styles.detailedDescriptionContainer_block_detailsInfo_detailCard_text
                            }
                          >
                            <Text variant='title'>
                              {isLoading ? (
                                <Skeleton height='24px' width='200px' />
                              ) : (
                                name || notice
                              )}
                            </Text>
                            <Button
                              data-testid={dataTestId.COPY_BTN}
                              onClick={() => copyText(number)}
                              variant='control'
                              className={
                                styles.detailedDescriptionContainer_block_detailsInfo_detailCard_text_copyLink
                              }
                            >
                              {isLoading ? (
                                <Skeleton height='48px' width='200px' />
                              ) : (
                                number && (
                                  <>
                                    <CopyIcon />
                                    <Text
                                      variant='body-tight'
                                      className={
                                        styles.detailedDescriptionContainer_block_detailsInfo_detailCard_text_copyLink_text
                                      }
                                    >
                                      {number}
                                    </Text>
                                  </>
                                )
                              )}
                            </Button>
                          </div>
                        </div>
                        <div
                          className={
                            styles.detailedDescriptionContainer_block_detailsInfo_detailCard_buttonWrapper
                          }
                        >
                          {isLoading ? (
                            <>
                              <SkeletonButton
                                className={
                                  styles.detailedDescriptionContainer_block_detailsInfo_detailCard_buttonWrapper_button_skeleton
                                }
                              />
                              <Skeleton
                                height='48px'
                                width='48px'
                                className={
                                  styles.detailedDescriptionContainer_block_detailsInfo_detailCard_chevron_Skeleton
                                }
                              />
                            </>
                          ) : (
                            <>
                              <Link
                                data-testid={dataTestId.RENDER_DETAILED_DESCRIPTION_BTN}
                                to={`${partUrlProduct}/${brand}/${id}`}
                                className={cx(
                                  styles.detailedDescriptionContainer_block_detailsInfo_detailCard_buttonWrapper_button,
                                  {
                                    [styles.detailedDescriptionContainer_block_detailsInfo_detailCard_buttonWrapper_button_disabled]:
                                      !number
                                  }
                                )}
                              >
                                <Text variant='headline-small'>Узнать цену</Text>
                              </Link>
                              {description && (
                                <div
                                  data-testid={dataTestId.RENDER_DETAILED_DESCRIPTION_CHEVRON}
                                  onClick={() => handleActiveChevronClick(layerIndex, cardIndex)}
                                  className={
                                    styles.detailedDescriptionContainer_block_detailsInfo_detailCard_chevron
                                  }
                                >
                                  <div>
                                    {isCardIndexActive(layerIndex, cardIndex) ? (
                                      <IconChevronUp />
                                    ) : (
                                      <IconChevronDown />
                                    )}
                                  </div>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                      {isCardIndexActive(layerIndex, cardIndex) && (
                        <div
                          className={
                            styles.detailedDescriptionContainer_block_detailsInfo_additionalDescription
                          }
                        >
                          {description}
                        </div>
                      )}
                    </React.Fragment>
                  )
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
