// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.XBwOh8qV1_coK4jQu2ib{display:flex}`, "",{"version":3,"sources":["webpack://./src/features/AppLayout/components/Footer/lib/LogoLink/styles.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA","sourcesContent":[".logo {\n  display: flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": `XBwOh8qV1_coK4jQu2ib`
};
export default ___CSS_LOADER_EXPORT___;
