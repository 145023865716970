import { Button, IconMagnifyingGlass, IconXmark } from '@uremont/erp-ui';
import React from 'react';
import { dataTestId } from '@shared/constants/dataTestId';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import styles from './styles.module.scss';

export const SearchWithClearButton = (
  searchValue: string,
  handleSearchChange: (value: string) => void,
  handleButton: () => void
) => (
  <div className={styles.searchWithClearButton} data-testid={dataTestId.SEARCH_WITH_CLEAR_BUTTON}>
    <Button icon={<IconMagnifyingGlass />} iconAlign='end' onClick={handleButton} variant='solid' />
    {searchValue && (
      <div className={styles.closeIcon}>
        <IconXmark onClick={() => handleSearchChange(EMPTY_STRING)} />
      </div>
    )}
  </div>
);
