import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { dataTestId } from '@shared/constants/dataTestId';
import picturePlaceholder from '@assets/picturePlaceholder/picturePlaceholderForCard.png';
import { BasketButton } from '@shared/components/BasketButton';
import { EMPTY_STRING } from '@shared/constants/fallbacks';

import { StickyInfoProductViewProps } from './types';
import styles from './styles.module.scss';

export const StickyInfoProductView = ({
  mainPhoto,
  name,
  textBtn,
  isLoading,
  item
}: StickyInfoProductViewProps) => {
  return (
    <div className={styles.stickyInfoProduct} data-testid={dataTestId.STICKY_INFO_PRODUCT_VIEW}>
      <div className={styles.stickyInfoProduct_content}>
        <div className={styles.stickyInfoProduct_content_left}>
          <div className={styles.stickyInfoProduct_img}>
            {isLoading ? (
              <Skeleton height='100%' width='100%' />
            ) : (
              <img
                alt='Изображение'
                id='productPage_photos_list_main_MainPhoto'
                src={mainPhoto || picturePlaceholder}
              />
            )}
          </div>
          <Text className={styles.stickyInfoProduct_name} variant='headline-L'>
            {isLoading ? <Skeleton height='36px' width='600px' /> : name}
          </Text>
        </div>
        <div className={styles.stickyInfoProduct_content_right}>
          <Text className={styles.stickyInfoProduct_price} variant='title'>
            {isLoading ? (
              <Skeleton height='36px' width='49px' />
            ) : (
              `${item.price || EMPTY_STRING} ₽`
            )}
          </Text>
          <BasketButton btnText={textBtn} id={item?.item_key?.toString()} isLoading={isLoading} />
        </div>
      </div>
    </div>
  );
};
