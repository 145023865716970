import React from 'react';

import { useOrders } from '@shared/hooks/use-orders';
import { useBasketStore } from '@shared/model/Store';
import { LocationAffectedDeliveryView } from './LocationAffectedDeliveryVIew';

import { LocationAffectedDeliveryProps } from './types';

export const LocationAffectedDelivery = ({ address }: LocationAffectedDeliveryProps) => {
  const [isLoading] = useBasketStore(state => [state.isLoading]);
  const { orderCardData } = useOrders();

  return (
    <LocationAffectedDeliveryView
      address={address}
      isLoading={isLoading}
      orderCardData={orderCardData}
    />
  );
};
