import React from 'react';
import { Link } from 'react-router-dom';

import { LogoLinkProps } from 'src/features/AppLayout/components/Footer/lib/LogoLink/types';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { uremontLinks } from 'src/shared/constants/links';

import styles from './styles.module.scss';

export const LogoLink = ({ children }: LogoLinkProps) => {
  return (
    <Link className={styles.logo} data-testid={dataTestId.LOGO_LINK} to={uremontLinks.mainUremont}>
      {children}
    </Link>
  );
};
