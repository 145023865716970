import React from 'react';
import { generatePath } from 'react-router-dom';

import { EMPTY_STRING, noop } from '@shared/constants/fallbacks';
import { getLinkRoute } from '@shared/functions/getLinkRoute/getLinkRoute';
import { useCarCatalog } from '@shared/hooks/use-car-catalog';
import { pathConfig } from '@shared/constants/pathConfig';
import { RenderCardView } from './RenderCardView';

import { RenderCardTypes } from './types';

export const RenderCard = ({
  title,
  links,
  selectedTitle = false,
  isLoading,
  cardItemHandler
}: RenderCardTypes) => {
  if (Array.isArray(links) && !links.length) {
    return null;
  }

  const { selectedModel, transformedSelectedModelData } = useCarCatalog();

  const isLinks = Array.isArray(links) && links.length > 0;

  const linkTo = (hasParts: boolean, name: string, id: string) => {
    if (hasParts && selectedModel) {
      const product = getLinkRoute(name) ?? EMPTY_STRING;
      const brand = transformedSelectedModelData[0].name.toLowerCase();
      const carId = selectedModel.id ?? EMPTY_STRING;

      const nameDetail = `Название:-${encodeURIComponent(
        transformedSelectedModelData[0]?.name ?? EMPTY_STRING
      )}`;
      const yearDetail = `Год:-${encodeURIComponent(
        transformedSelectedModelData[1]?.name ?? EMPTY_STRING
      )}`;
      const regionDetail = `Регион:-${encodeURIComponent(
        transformedSelectedModelData[2]?.name ?? EMPTY_STRING
      )}`;
      const descriptionDetail = `Описание:-${encodeURIComponent(
        transformedSelectedModelData[3]?.name ?? EMPTY_STRING
      )}`;

      return generatePath(pathConfig.CatalogProductPath, {
        category: id ?? EMPTY_STRING,
        product: product,
        brand: brand,
        carId: carId,
        name_detail: nameDetail,
        year_detail: yearDetail,
        region_detail: regionDetail,
        description_detail: descriptionDetail
      });
    }

    return `/catalog/${id || EMPTY_STRING}?parentName=${name}`;
  };

  const cardContentClick = (hasParts: boolean, id: string) =>
    hasParts ? noop : cardItemHandler ? () => cardItemHandler(id) : noop;

  return (
    <RenderCardView
      cardContentClick={cardContentClick}
      cardItemHandler={cardItemHandler}
      isLinks={isLinks}
      isLoading={isLoading}
      linkTo={linkTo}
      links={links}
      selectedTitle={selectedTitle}
      title={title}
    />
  );
};
