import React from 'react';
import { Skeleton, Text } from '@uremont/erp-ui';

import { BackButton } from '@shared/components/BackButton';
import { Breadcrumbs } from '@shared/components/Breadcrumbs';
import { CardsContainer } from '@shared/components/CardsContainer';
import { EMPTY_STRING } from '@shared/constants/fallbacks';
import { RenderCard } from '@shared/components/RenderCard';
import { TO_CATALOG } from '@shared/constants/category';
import { dataTestId } from '@shared/constants/dataTestId';
import { pathConfig } from '@shared/constants/pathConfig';
import { InfoCard } from '@shared/components/InfoCard/InfoCard';

import { CatalogCategoryViewProps } from './types';
import styles from './styles.module.scss';

export const CatalogCategoryView = ({
  breadcrumbs,
  selectedPart,
  transformedData,
  isLoading,
  title,
  nestedGroup
}: CatalogCategoryViewProps) => {
  return (
    <div className={styles.searchContainer} data-testid={dataTestId.CATALOG_CATEGORY_VIEW}>
      <Breadcrumbs links={breadcrumbs} />
      <InfoCard infoCardData={transformedData} isLoading={isLoading} />
      <BackButton text={TO_CATALOG} url={pathConfig.CatalogPath} />
      {selectedPart && (
        <>
          <Text className={styles.searchContainer_titleText} variant='header'>
            {isLoading ? <Skeleton height='40px' width='300px' /> : title}
          </Text>
          <CardsContainer>
            <RenderCard
              isLoading={isLoading}
              links={nestedGroup}
              selectedTitle
              title={isLoading ? EMPTY_STRING : selectedPart.name}
            />
          </CardsContainer>
        </>
      )}
    </div>
  );
};
