import { IconedText } from '@uremont/ui-kit';
import {
  NotesPen,
  Home,
  UserCircle,
  Sale,
  Heart,
  Ruble,
  Car,
  Notes,
  Cart,
  Box,
  Chat
} from '@uremont/ui-icons-v2';
import { uremontLinks } from '@shared/constants/links';
import styles from './ProfileMenuCustomer.module.scss';

export const ProfileMenuCustomer = () => (
  <div className={styles.wrapper}>
    <a className={styles.link} href={uremontLinks.customerDashboard}>
      <IconedText icon={Home} iconSize={24} textType='bodyTightRegular'>
        Главная
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerProfile}>
      <IconedText icon={UserCircle} iconSize={24} textType='bodyTightRegular'>
        Профиль
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerPromo}>
      <IconedText icon={Sale} iconSize={24} textType='bodyTightRegular'>
        Акции
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerChosen}>
      <IconedText icon={Heart} iconSize={24} textType='bodyTightRegular'>
        Избранные автосервисы
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerBids}>
      <IconedText icon={NotesPen} iconSize={24} textType='bodyTightRegular'>
        Заявки
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerFinanses}>
      <IconedText icon={Ruble} iconSize={24} textType='bodyTightRegular'>
        Мои финансы
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerCars}>
      <IconedText icon={Car} iconSize={24} textType='bodyTightRegular'>
        Гараж
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerReports}>
      <IconedText icon={Notes} iconSize={24} textType='bodyTightRegular'>
        Отчеты
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.autopartsCart}>
      <IconedText icon={Cart} iconSize={24} textType='bodyTightRegular'>
        Корзина
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.autopartsOrders}>
      <IconedText icon={Box} iconSize={24} textType='bodyTightRegular'>
        Заказы
      </IconedText>
    </a>
    <a className={styles.link} href={uremontLinks.customerChat}>
      <IconedText icon={Chat} iconSize={24} textType='bodyTightRegular'>
        Чат
      </IconedText>
    </a>
  </div>
);
