import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { EMPTY_ARRAY, EMPTY_STRING } from '@shared/constants/fallbacks';
import { DataOrder } from '@shared/components/OrderCard/types';
import { getOrderInformation, placeAnOrder } from '@shared/model/Store/Orders/service';
import { pathConfig } from '@shared/constants/pathConfig';
import { executeRequest } from '@shared/functions/executeRequest/executeRequest';
import { OrdersStore } from './types';

export const useOrdersStore = create(
  devtools(
    persist<OrdersStore>(
      set => ({
        orders: null,
        orderInformation: EMPTY_ARRAY,
        placeAnOrderData: null,
        isLoading: false,
        setOrder: (itemOrder: DataOrder) => {
          set({
            orders: itemOrder
          });
        },
        getOrderInformation: async (navigateToPage, isAuthorized = false) => {
          await executeRequest(
            getOrderInformation,
            isLoading => set({ isLoading }),
            response => {
              set({ orderInformation: response ?? EMPTY_ARRAY });
              if (!isAuthorized && navigateToPage) {
                const UREMONT_URL = process.env.REACT_APP_UREMONT_URL ?? EMPTY_STRING;

                window.location.href = `${UREMONT_URL}${pathConfig.EnterPath}`;
              } else if (response && navigateToPage) {
                navigateToPage(pathConfig.PlacingAnOrderPath);
              }
            }
          );
        },

        getOrderInformationPlacinganorder: async () => {
          await executeRequest(
            getOrderInformation,
            isLoading => set({ isLoading }),
            response => {
              set({ orderInformation: response ?? EMPTY_ARRAY });
            }
          );
        },

        placeAnOrder: async (values, id, navigateToPage) => {
          await executeRequest(
            () => placeAnOrder(values, id),
            isLoading => set({ isLoading }),
            response => {
              set({ placeAnOrderData: response ? response : null });

              if (response) {
                if (response.result) {
                  navigateToPage(pathConfig.PlacingAnOrderSuccessPath);
                } else if (response.paymentLink) {
                  window.location.href = response.paymentLink;
                } else navigateToPage(pathConfig.PlacingAnOrderRejectPath);
              } else navigateToPage(pathConfig.PlacingAnOrderRejectPath);
            }
          );
        },

        resetOrder: () => {
          set({
            orders: null,
            orderInformation: EMPTY_ARRAY,
            placeAnOrderData: null,
            isLoading: false
          });
        }
      }),
      {
        name: 'orders-storage',
        storage: createJSONStorage(() => localStorage)
      }
    )
  )
);
