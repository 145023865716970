import React from 'react';

import { Breadcrumbs } from 'src/shared/components/Breadcrumbs';
import { dataTestId } from 'src/shared/constants/dataTestId';
import { FilterComponent } from 'src/shared/components/FilterComponent';
import { SearchByArticleInput } from 'src/shared/components/SearchByArticleInput/SearchByArticleInput';
import { SearchFoundText } from 'src/shared/components/SearchFoundText/SearchFoundText';

import { CatalogItemPriceByArticlePageViewProps } from './types';
import styles from './styles.module.scss';

export const CatalogItemPriceByArticlePageView = ({
  searchValue,
  handleSearchChange,
  handleInputFocus,
  handleInputBlur,
  isFocused,
  searchWithClearButton,
  handleDropdownItemClick,
  isOpenDropdown,
  currentSearchValue,
  dropdownRef,
  goBack,
  articleTips,
  isMobile,
  handleButtonClick,
  resultLength,
  breadcrumbsLinks,
  isLoadingSearchByArticleDetailsData,
  handleSelectedArticleClicked
}: CatalogItemPriceByArticlePageViewProps) => (
  <div className={styles.filterPage} data-testid={dataTestId.CATALOG_PRICE_BY_ARTICLE_PAGE_VIEW}>
    <Breadcrumbs links={breadcrumbsLinks} />
    <SearchByArticleInput
      articleTips={articleTips}
      classNameInput={styles.input}
      dropdownRef={dropdownRef}
      goBack={goBack}
      handleButtonClick={handleButtonClick}
      handleDropdownItemClick={handleDropdownItemClick}
      handleInputBlur={handleInputBlur}
      handleInputFocus={handleInputFocus}
      handleSearchChange={handleSearchChange}
      isFocused={isFocused}
      isLoadingArticle={isLoadingSearchByArticleDetailsData}
      isMobile={isMobile}
      isOpenDropdown={isOpenDropdown}
      searchValue={searchValue}
      searchWithClearButton={searchWithClearButton}
    />
    <SearchFoundText
      isLoading={isLoadingSearchByArticleDetailsData}
      resultLength={resultLength}
      searchValue={currentSearchValue}
      tips={articleTips}
    />
    <FilterComponent
      handleSelectedArticleClicked={handleSelectedArticleClicked}
      isLoading={isLoadingSearchByArticleDetailsData}
    />
  </div>
);
