import { getCookie, setCookie } from '@uremont/erp-ui';
import { COOKIE_NAME } from '@shared/constants/enums';
import { decodeJwtToken } from '@utils/decodeJwtToken';
import { useEffect } from 'react';
import { getDomainForCookie } from '@utils/getDomainForCookie';
import { EXPIRES_COOKIE_YEAR } from '@shared/constants/consts';
import { sendRefreshToken } from '@features/AppLayout/components/AuthRefresher/service';

export const AuthRefresher = () => {
  const token = getCookie(COOKIE_NAME.AUTH_TOKEN);
  const refreshToken = getCookie(COOKIE_NAME.REFRESH_TOKEN);
  const domain = getDomainForCookie();

  const tokenExpireAt = token ? decodeJwtToken(token) : 0;

  useEffect(() => {
    if (!tokenExpireAt) {
      return;
    }

    const refreshInterval = setInterval(async () => {
      const now = Date.now();

      if (tokenExpireAt - now > 60 * 1000) {
        return;
      }

      clearInterval(refreshInterval);

      const response = await sendRefreshToken(refreshToken);

      if (response) {
        const { access_token, refresh_token } = response;
        setCookie(COOKIE_NAME.AUTH_TOKEN, access_token, EXPIRES_COOKIE_YEAR, domain);

        if (refresh_token) {
          setCookie(COOKIE_NAME.REFRESH_TOKEN, refresh_token, EXPIRES_COOKIE_YEAR, domain);
        }
      }
    }, 5 * 1000);

    return () => {
      clearInterval(refreshInterval);
    };
  }, [tokenExpireAt, refreshToken]);

  return null;
};
